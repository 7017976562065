import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {Loader} from '../components/Loader';
import {ScrollToTop} from '../components/ScrollToTop';
import ReactGA from 'react-ga';
//desktop
import Home from '../containers/Desktop/Home';
import Confirm from '../containers/Desktop/Confirm';
import Insurance from '../containers/Desktop/Insurance';
import Details from '../containers/Desktop/Details';
import Booking from '../containers/Desktop/Booking';
import InfoUpdate from '../containers/Desktop/InfoUpdate';
//mobile
import HomeMobile from '../containers/Home';
import ConfirmMobile from '../containers/Confirm';
import InsuranceMobile from '../containers/Insurance';
import DetailsMobile from '../containers/Details';
import BookingMobile from '../containers/Booking';
import TagManager from 'react-gtm-module';
import queryString from 'query-string';

import {addSkyscannerToHead, initAWS, initPaymentMethod} from '../helpers';

const initialState = {
  width: window.innerWidth
};
type State = Readonly<typeof initialState>

export default class Routes extends Component<any, State> {
  readonly state: State = initialState;
  lastScrollTop = 0;

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    window.addEventListener('scroll', this.scrollCart);
    if (window.location.href.indexOf('/reservation') === -1) {
      localStorage.removeItem('client');
      localStorage.removeItem('cart');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
    window.removeEventListener('scroll', this.scrollCart);
  }

  componentWillReceiveProps(newProps) {
    if (!this.props.baseConf && newProps.baseConf && Object.keys(newProps.baseConf).length) {
      const qs = queryString.parse(window.location.search);
      if (newProps.baseConf.awsLogs.enabled) {
        initAWS(newProps.baseConf.awsLogs);
      }
      if (newProps.baseConf.skyscannerPixelEnabled) {
        addSkyscannerToHead();
      }
      this.props.fetchCountries(newProps.baseConf.urls, qs['apiKey'] || newProps.baseConf.apiKeys.reservationsAPI);
      this.props.fetchExchange(newProps.baseConf.urls);
      this.props.fetchDesks(newProps.baseConf.urls, qs['apiKey'] || newProps.baseConf.apiKeys.reservationsAPI);
      this.props.fetchPrismic(newProps.baseConf.urls);
      this.props.fetchItems(newProps.baseConf.urls, qs['apiKey'] || newProps.baseConf.apiKeys.reservationsAPI);

      this.props.fetchCarInfo(newProps.baseConf.urls, qs['apiKey'] || newProps.baseConf.apiKeys.reservationsAPI);
      if (newProps.baseConf.enablePayments === 'disabled') {
        this.props.togglePayOnline(false);
      } else {
        initPaymentMethod(newProps.baseConf);
      }
    }
    if (newProps.conf && newProps.conf.ga_tracking_code) {
      if (!this.props.conf) {
        if (newProps.conf.ga_tracking_code.indexOf('GTM') !== -1) {
          const tagManagerArgs = {
            gtmId: newProps.conf.ga_tracking_code
          }
          TagManager.initialize(tagManagerArgs);
        } else {
          ReactGA.initialize(newProps.conf.ga_tracking_code);
          ReactGA.pageview(window.location.pathname + window.location.search);
          window.history.onpushstate = () => {
            ReactGA.pageview(window.location.pathname + window.location.search);
          }
        }
      }
    }
  }

  handleWindowSizeChange = () => {
    this.setState({width: window.innerWidth});
  };

  scrollCart = () => {
    let carts = document.getElementsByClassName('cart-container');
    let cart: HTMLElement;
    const app = document.getElementById('app');
    const scrollHeight = app.scrollHeight;
    if (carts && carts.length) {
      cart = carts.item(0) as HTMLElement;
    }
    if (cart) {
      if (window.scrollY < 215) {
        cart.className = 'cart-container';
      } else {
        cart.className = 'cart-container fixed';
        if (cart.getBoundingClientRect().height < window.innerHeight - 100 || window.scrollY - this.lastScrollTop < 0) {
          cart.className += ' top';
        } else {
          cart.className += ' bottom';
        }
      }
    }
    this.lastScrollTop = window.scrollY;
  }

  render() {
    if (!this.props.items || !this.props.desks || !this.props.conf || !this.props.prismic || !this.props.baseConf) return <Loader/>;

    document.title = this.props.prismic.data.meta_title;
    const meta = document.getElementsByTagName("meta");
    for (let i = 0; i < meta.length; i++) {
      if (meta[i].name.toLowerCase() === "description") {
        meta[i].content = this.props.prismic.data.meta_description;
      }
    }

    return (
      <Router>
        <ScrollToTop>
          <Route exact path="/:locale(en|de|is|ro|ru)?" component={this.state.width <= 1200 ? HomeMobile : Home}/>
          <Route exact path="/:locale/prices" component={this.state.width <= 1200 ? HomeMobile : Home}/>
          <Route exact path="/:locale/vehicle" component={this.state.width <= 1200 ? ConfirmMobile : Confirm}/>
          <Route exact path="/:locale/coverages" component={this.state.width <= 1200 ? InsuranceMobile : Insurance}/>
          <Route exact path="/:locale/reservation" component={this.state.width <= 1200 ? DetailsMobile : Details}/>
          <Route exact path="/:locale/confirmation" component={this.state.width <= 1200 ? BookingMobile : Booking}/>
          <Route exact path="/:locale/info_update" component={this.state.width <= 1200 ? InfoUpdate : InfoUpdate}/>
          <Route exact path="/info_update" component={this.state.width <= 1200 ? InfoUpdate : InfoUpdate}/>
        </ScrollToTop>
      </Router>
    );
  }
}
