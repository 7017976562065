import React, {Component} from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import {t} from '../../conf/gettext';
import {getRate} from '../../helpers';
import PromoCodeInfo from '../Listing/PromoCodeInfo';
import {fuel} from '../../conf/values';

const initialState = {
  modalOpen: false,
  modal: {},
  driverIndex: 0,
  drivers: [{}],
  errors: {},
  current: null,
  index: 0
};
type State = Readonly<typeof initialState>

const modalStyles = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    position: 'initial'
  }
};

export default class ActiveListing extends Component<any, State> {
  readonly state: State = initialState;

  constructor(props) {
    super(props);

    this.driver = {
      firstName: React.createRef(),
      lastName: React.createRef(),
      expiryMM: React.createRef(),
      expiryYY: React.createRef(),
      licenseNumber: React.createRef()
    };

    this.state = {
      ...this.state,
      drivers: props.rateInfo.drivers || [{}],
      current: props.rateInfo
    };
  }

  clearModal(indMod) {
    const driverIndex = this.state.driverIndex + indMod;

    this.driver.firstName.current.value = this.state.drivers[driverIndex].firstName || '';
    this.driver.lastName.current.value = this.state.drivers[driverIndex].lastName || '';
    this.driver.expiryMM.current.value = '';
    this.driver.expiryYY.current.value = '';
    this.driver.licenseNumber.current.value = this.state.drivers[driverIndex].licenseNumber || '';

    if (this.state.drivers[driverIndex].expiry) {
      const expiry = this.state.drivers[driverIndex].expiry.split('/');
      this.driver.expiryMM.current.value = expiry[0];
      this.driver.expiryYY.current.value = expiry[1];
    }

    this.setState({driverIndex});
  }

  toggleModal(driverIndex = 0) {
    this.setState({modalOpen: !this.state.modalOpen, driverIndex: 0});
  }

  addDriver() {
    const drivers = this.state.drivers;
    drivers.forEach(driver => {
      for (let key in driver) {
        if (!driver[key] || driver[key] == 'null' || driver[key] == 'undefined') {
          driver[key] = '';
        }
      }
    });
    this.props.addDriver({drivers}, this.props.rateInfo.reservationNumber, this.props.baseConf.urls);
    this.toggleModal();
  }

  navigate(isBack, alternatives = []) {
    let nextCar;
    let nextIndex;
    const index = this.state.index;
    if (index === 0 && isBack) {
      nextCar = alternatives[alternatives.length - 1];
      nextIndex = alternatives.length;
    } else if (index === alternatives.length && !isBack) {
      nextIndex = 0;
      nextCar = this.props.rate;
    } else {
      nextIndex = isBack ? index - 1 : index + 1;
      nextCar = nextIndex === 0 ? this.props.rate : alternatives[nextIndex - 1];
    }
    this.setState({
      index: nextIndex,
      current: nextCar
    });
  }

  getItem(items, itemGroupType, itemOtaCode) {
    return items[itemGroupType].filter((item) => item.otaCode === itemOtaCode)[0];
  }

  render() {
    if (!this.props.rateInfo || !this.props.baseConf) return null;
    const mileage = this.props.rateInfo.mileagePolicy.mileageTypeUnlimited ? t('Unlimited mileage') :
      `${this.props.rateInfo.mileagePolicy.mileageQuantity} ${this.props.rateInfo.mileagePolicy.mileageUnitType.toLowerCase()} ${this.props.rateInfo.mileagePolicy.mileageUnitPeriodType.toLowerCase()}`;

    const optionalExtras = this.props.rateInfo.optionalItems.extras.map((item, i) => {
      const itemData = this.getItem(this.props.items, 'extras', item.otaCode);
      return (
        <div key={i} className="margin8_8">
          <i className="icon icon-check-green"></i>
          <span
            className="margin8_8">{itemData.name[this.props.locale.rc_language_code]} {item.quantity > 1 ? `x ${item.quantity}` : ''}</span>
        </div>
      );
    });

    const optionalCoverages = this.props.rateInfo.optionalItems.coverages.map((item, i) => {
      const itemData = this.getItem(this.props.items, 'coverages', item.otaCode);
      return (
        <div key={i} className="margin8_8">
          <i className="icon icon-check-green"></i>
          <span
            className="margin8_8">{itemData.name[this.props.locale.rc_language_code]} {item.quantity > 1 ? `x ${item.quantity}` : ''}</span>
        </div>
      );
    });

    let driveType = null;

    if (this.props.rateInfo.driveType == '4WD') {
      driveType = t('4WD');
    }

    if (this.props.rateInfo.driveType == 'AWD') {
      driveType = t('AWD');
    }

    const daysString = this.props.rateInfo.rentalDays === 1 ? t('day') : this.props.rateInfo.rentalDays < 5 ? t('days (2-4)') : t('days');
    const alternatives = this.props.carinfo && this.props.carinfo.cars && this.props.carinfo.cars[this.props.rateInfo.vehicleId];
    const imgStyle = {backgroundImage: `url(${this.state.current.image.original || this.state.current.imageUrl})`};
    const rateTotalAmount = this.props.rateInfo.pricing.payNow ? this.props.rateInfo.pricing.payNow.totalAmount : this.props.rateInfo.pricing.payOnPickup.totalAmount;
    const promoCodeDiscount = this.props.rateInfo.pricing.payNow ? this.props.rateInfo.pricing.payNow.discount : this.props.rateInfo.pricing.payOnPickup.discount;

    return (
      <div className="listing overview no-borders">
        <div className="header">
          <div className="info">
            <div className="size-class-info">
              <span className="text-bold">{t(this.props.rateInfo.size)}</span>
              {this.props.rateInfo.isGuaranteedModel &&
                  <span className="description guaranteed">{t('Guaranteed model, you will get this exact car!')}</span>
                ||
                alternatives && alternatives.length &&
                  <span className="description">{t('You will get one of these cars:')}</span>
                ||
                  <span className="description">{t('or similar')}</span>}
            </div>
            <div className="size-class">
              {this.state.current.displayName || this.state.current.model}
            </div>
            {alternatives && alternatives.length &&
                <div className="car-carousel">
                    <div className="cars">
                        <button className="prev-btn" onClick={() => this.navigate(true, alternatives)}></button>
                        <div style={imgStyle} className="image"></div>
                        <button className="next-btn" onClick={() => this.navigate(false, alternatives)}></button>
                    </div>
                    <div className="indicators">
                        <span className={`indicator ${this.state.index === 0 && 'active'}`}></span>
                      {alternatives.map((_, i) => <span key={i}
                                                        className={`indicator ${this.state.index === i + 1 && 'active'}`}></span>)}
                    </div>
                </div> ||
                <div className="car-carousel no-options">
                    <div className="cars">
                        <div style={imgStyle} className="image"></div>
                    </div>
                </div>}
          </div>
        </div>
        <div className="cta-container">
          <div className="price">
            <div>{getRate(rateTotalAmount, this.props.currency, this.props.exchange)} {this.props.currency}</div>
            <div className="info">
              {this.props.rateInfo.promoCode && this.props.rateInfo.promoCode.code ?
                <PromoCodeInfo
                  estimatedTotalAmount={rateTotalAmount}
                  discount={promoCodeDiscount}
                  currency={this.props.currency}
                  exchange={this.props.exchange}
                /> :
                null}
              {t('for')} {this.props.rateInfo.rentalDays} {daysString} {getRate((rateTotalAmount / this.props.rateInfo.rentalDays), this.props.currency, this.props.exchange)} {this.props.currency} {t('per day')}
              <div className="vat-remark">{this.props.prismic.data.vat_remark_for_price}</div>
            </div>
          </div>
        </div>
        <div className="pickup">
          {this.props.prismic.data.extras_note}
        </div>
        <div className="extras">
          <div className="car-info">
            {driveType ?
              (<div>
                <i className="icon icon-wheel"></i>
                <span className="text-dark-gray text-bold">{driveType}</span>
              </div>) : ''
            }
            <div>
              <i className="icon icon-gearbox"></i>
              <span className="text-dark-gray text-bold">
                                {this.props.rateInfo.transmissionAuto ? t('A') : t('M')}
                            </span>
            </div>
            <div>
              <i className="icon icon-person"></i>
              <span className="text-dark-gray text-bold">
                                {this.props.rateInfo.passengerCount}
                            </span>
            </div>
            <div>
              <i className="icon icon-luggage"></i>
              <span className="text-dark-gray text-bold">
                                {this.props.rateInfo.baggageCount}
                            </span>
            </div>
            <div>
              <i className="icon icon-door"></i>
              <span className="text-dark-gray text-bold">
                                {this.props.rateInfo.doorCount}
                            </span>
            </div>
            {this.props.rateInfo.airCond ?
              (<div className="flex1">
                <i className="icon icon-snowflake"></i>
                <i className="icon icon-check-dark-gray"></i>
              </div>) : ''
            }
            {this.props.rateInfo.features && this.props.rateInfo.features.map(feat => (
              <div key={feat}>
                <i className={`icon icon-${feat}`}></i>
                <i className="icon icon-check-dark-gray"></i>
              </div>
            ))}
          </div>
        </div>
        <div className="description description-padding">
          {this.props.path ?
            <div className="text-dark-gray text-lg text-bold margin16_0">{t('Included in price')}</div> : null}
          <div className="margin8_8">
            <i className="icon icon-check-green"></i>
            <span className="margin8_8">{t(fuel[this.props.rateInfo.fuelPolicy])} {t('Fuel policy')}</span>
          </div>
          <div className="margin8_8">
            <i className="icon icon-check-green"></i>
            <span className="margin8_8">{mileage}</span>
          </div>
          {this.props.rateInfo.inclusiveItems.map((item, i) => {
            if (this.props.items && this.props.items[item.groupType] && this.getItem(this.props.items, item.groupType, item.otaCode)) {
              return (
                <div key={i} className="margin8_8">
                  <i className="icon icon-check-green"></i>
                  <span
                    className="margin8_8">{this.getItem(this.props.items, item.groupType, item.otaCode).name[this.props.locale.rc_language_code]}</span>
                </div>
              );
            }
          })}

          {this.props.path ?
            <div>
              {optionalExtras || optionalCoverages ?
                <div className="text-dark-gray text-lg text-bold margin16_0">{t('Extras purchased')}</div> : ''}
              {optionalExtras}
              {optionalCoverages}
              <div className="text-dark-gray text-lg text-bold margin16_0">{t('Drivers')}</div>
              {
                this.props.rateInfo.drivers.map((driver, i) => {
                  if (driver.firstName && driver.lastName && !(driver.firstName == 'null' && driver.lastName == 'null')) {
                    return (
                      <div onClick={() => this.toggleModal(i)} key={i}
                           className="description-item gray margin8_0 pointer">
                        <i className="icon icon-person"></i>
                        <span>{`${driver.firstName != 'null' ? driver.firstName : ''} ${driver.lastName != 'null' ? driver.lastName : ''}`}</span>
                      </div>
                    );
                  } else {
                    return (
                      <div onClick={() => this.toggleModal(i)} key={i}
                           className="description-item gray margin8_0 pointer">
                        <i className="icon icon-person"></i>
                        <span>{`${t('Additional driver')} #${i + 1}`}</span>
                      </div>
                    );
                  }
                })
              }
              <button className="btn btn-blue margin16_0"
                      onClick={() => this.toggleModal()}>{t('Fill in driver details')}</button>
            </div> : null
          }
        </div>

        <Modal
          isOpen={this.state.modalOpen}
          onRequestClose={() => this.toggleModal()}
          style={modalStyles}
          contentLabel="Driver details"
          setState={state => this.setState(state)}
        >
          <div className="modal">
            <div className="heading">
              <h2 className="text-blue">{t('Driver information')}</h2>
              <i onClick={() => this.toggleModal()} className="icon icon-cross-light-gray"></i>
              <div className="flex-row">
                <div className="input-group flex1 marginright32">
                  <div className="row-item">
                    <div className="label text-bold text-md">{t('First name')}</div>
                    <input
                      defaultValue={this.state.drivers[this.state.driverIndex].firstName && this.state.drivers[this.state.driverIndex].firstName != 'null' ? this.state.drivers[this.state.driverIndex].firstName : null}
                      ref={this.driver.firstName}
                      onBlur={() => {
                        const drivers = this.state.drivers;
                        drivers[this.state.driverIndex].firstName = this.driver.firstName.current.value;
                        this.setState({drivers});
                      }
                      }
                      className="input"
                      placeholder={t('Enter your first name')}/>
                  </div>
                </div>
                <div className="input-group flex1">
                  <div className="row-item">
                    <div className="label text-bold text-md">{t('Last name')}</div>
                    <input
                      defaultValue={this.state.drivers[this.state.driverIndex].lastName && this.state.drivers[this.state.driverIndex].lastName != 'null' ? this.state.drivers[this.state.driverIndex].lastName : null}
                      ref={this.driver.lastName}
                      onBlur={() => {
                        const drivers = this.state.drivers;
                        drivers[this.state.driverIndex].lastName = this.driver.lastName.current.value;
                        this.setState({drivers});
                      }
                      }
                      className="input"
                      placeholder={t('Enter your last name')}/>
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <div className="input-group flex1">
                  <div className="row-item">
                    <div className="label text-bold text-md">{t('Driver\'s licence number')}</div>
                    <input
                      defaultValue={this.state.drivers[this.state.driverIndex].licenseNumber && this.state.drivers[this.state.driverIndex].licenseNumber != 'null' ? this.state.drivers[this.state.driverIndex].licenseNumber : null}
                      ref={this.driver.licenseNumber}
                      onBlur={() => {
                        const drivers = this.state.drivers;
                        drivers[this.state.driverIndex].licenseNumber = this.driver.licenseNumber.current.value;
                        this.setState({drivers});
                      }
                      }
                      className="input"
                      placeholder={t('Enter your licence number')}/>
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <div className="input-group flex1">
                  <div className="row-item">
                    <div className="label text-bold text-md">{t('Licence expiry')}</div>
                    <div className="negative-checkbox">
                      <div
                        className={`floatright checkbox checkbox-sm ${this.state.drivers[this.state.driverIndex].noExpiry ? 'checked' : ''}`}
                        onClick={() => {
                          const drivers = this.state.drivers;
                          const errors = this.state.errors;
                          drivers[this.state.driverIndex].noExpiry = !drivers[this.state.driverIndex].noExpiry;
                          if (drivers[this.state.driverIndex].noExpiry) {
                            delete errors['expiryMM_' + this.state.driverIndex];
                            delete errors['expiryYY_' + this.state.driverIndex];
                          }

                          drivers[this.state.driverIndex].expiry = drivers[this.state.driverIndex].noExpiry ? 'noExp' : '';
                          this.setState({drivers});
                        }
                        }
                      ></div>
                      <div
                        className="margin0_8 text-sm floatright text-bold text-dark-gray">{t('Does not expire')}</div>
                    </div>
                    <div className="flex-row"
                         style={{opacity: (this.state.drivers[this.state.driverIndex].noExpiry ? 0 : 1)}}>
                      <input
                        defaultValue={this.state.drivers[this.state.driverIndex].expiry && this.state.drivers[this.state.driverIndex].expiry.indexOf('null') == -1 && this.state.drivers[this.state.driverIndex].expiry != 'noExp' ? this.state.drivers[this.state.driverIndex].expiry.split('/')[0] : null}
                        ref={this.driver.expiryMM}
                        onBlur={() => {
                          const errors = this.state.errors;
                          if (Number(this.driver.expiryMM.current.value) >= 1 && Number(this.driver.expiryMM.current.value) <= 12) {
                            delete errors['expiryMM_' + this.state.driverIndex];
                            const drivers = this.state.drivers;
                            drivers[this.state.driverIndex].expiry = `${this.driver.expiryMM.current.value}/${this.driver.expiryYY.current.value}`;
                            this.setState({drivers, errors});
                          } else {
                            errors['expiryMM_' + this.state.driverIndex] = true;
                            this.setState({errors});
                          }
                        }}
                        className={`input text-center flex1 joined ${this.state.errors['expiryMM_' + this.state.driverIndex] ? 'has-error' : ''}`}
                        placeholder={t('MM')} maxLength={2}/>
                      <input
                        defaultValue={this.state.drivers[this.state.driverIndex].expiry && this.state.drivers[this.state.driverIndex].expiry.indexOf('null') != -1 && this.state.drivers[this.state.driverIndex].expiry != 'noExp' ? this.state.drivers[this.state.driverIndex].expiry.split('/')[1] : null}
                        ref={this.driver.expiryYY}
                        onBlur={() => {
                          const errors = this.state.errors;
                          if (2000 + Number(this.driver.expiryYY.current.value) >= moment().year()) {
                            delete errors['expiryYY_' + this.state.driverIndex];
                            const drivers = this.state.drivers;
                            drivers[this.state.driverIndex].expiry = `${this.driver.expiryMM.current.value}/${this.driver.expiryYY.current.value}`;
                            this.setState({drivers});
                          } else {
                            errors['expiryYY_' + this.state.driverIndex] = true;
                            this.setState({errors});
                          }
                        }}
                        className={`input text-center flex1 ${this.state.errors['expiryYY_' + this.state.driverIndex] ? 'has-error' : ''}`}
                        placeholder={t('YY')}
                        maxLength={2}/>
                    </div>
                  </div>
                </div>
              </div>
              { /*<div className="flex-row">
                                <div className="flex1">
                                    <div
                                        className={`checkbox checkbox-sm ${this.state.modal.licenceForAYear ? 'checked' : ''}`}
                                        onClick={ () => this.setState({ modal: Object.assign({}, this.state.modal, {licenceForAYear: !this.state.modal.licenceForAYear}) }) }
                                    ></div>
                                    <span className="margin0_8 text-sm text-bold text-dark-gray">{ t("I have had a driver's licence for at least 1 year") }</span>
                                </div>
                            </div>*/}
              <div className="separator"></div>
              <div className="flex-row marginbottom32">
                <div className="flex1 marginright32">{this.state.driverIndex > 0 ?
                  <button disabled={Object.keys(this.state.errors).length > 0} onClick={() => this.clearModal(-1)}
                          className="btn btn-gray">{t('previous')}</button> : ''}</div>
                <div
                  className="flex1">{this.props.rateInfo.drivers && this.state.driverIndex < this.props.rateInfo.drivers.length - 1 ?
                  <button disabled={Object.keys(this.state.errors).length > 0} className="btn btn-green"
                          onClick={() => this.clearModal(1)}>{t('next driver')}</button> : null}</div>
              </div>
              <div className="flex-row">
                <div className="flex1 marginright32">
                  <button onClick={() => this.toggleModal()} className="btn btn-gray">{t('skip')}</button>
                </div>
                <div className="flex1">
                  <button className="btn btn-green" disabled={Object.keys(this.state.errors).length > 0}
                          onClick={() => this.addDriver()}>{t('submit')}</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}