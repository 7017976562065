import { Dispatch } from 'redux';
import fetch from 'cross-fetch';
import types from '../types';

export function setCurrency(currency) {
    return {
        type: types.SET_CURRENCY,
        currency: currency
    }
}

export function receiveExchange(exchange) {
    return {
        type: types.SET_EXCHANGE,
        exchange
    }
}

export function fetchExchange(urls): (dispatch: Dispatch<any, any>) => Promise<any> {
    return function(dispatch: Dispatch<any, any>) {
        return fetch(`${urls.reservationsAPI}/exchange`)
        .then(res => res.json())
        .then(json => {
            return dispatch(receiveExchange(json));
        });
    }
}
