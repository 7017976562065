import React, {Component} from 'react';
import {t} from '../../../conf/gettext';
import {currencies, languages} from '../../../conf/values';
import {defaultCurrency} from 'conf/env';
import queryString from 'query-string';

const initialState = {
  langMenu: false,
  currencyMenu: false,
  currency: localStorage.getItem('currency') || defaultCurrency,
  showScrollTop: false,
};
type State = Readonly<typeof initialState>

interface props {
  active?: number,
  minimal?: boolean
};

export default class NavBar extends Component<any, State> {
  readonly state: State = initialState;

  constructor(props) {
    super(props);

    if (!this.state.currency) {
      this.setState({currency: defaultCurrency});
      localStorage.setItem('currency', defaultCurrency);
    }

    this.scrollEvt = window.addEventListener('scroll', ev => {
      const search = document.getElementById('search');

      if (search) {
        if (search.getBoundingClientRect().bottom < 0 && !this.state.showScrollTop) {
          this.setState({showScrollTop: true});
        } else if (search.getBoundingClientRect().bottom > 0 && this.state.showScrollTop) {
          this.setState({showScrollTop: false});
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.locale.slug !== prevProps.locale.slug && this.props.locale.default_currency && window.location.search.indexOf('currency') === -1) {
      this.setCurrency(this.props.locale.default_currency);
    }
  }

  getActiveLocale() {
    return this.props.conf.enabled_languages.find(lang => {
      return lang.language_code_in_prismic == this.props.locale.language_code_in_prismic
    }).name;
  }

  changeRoute(active) {
    const routes = [
      '',
      'vehicle',
      'coverages',
      'reservation',
      'confirmation',
    ];

    if (this.props.active && this.props.active > 0 && this.props.active < 4 && active < this.props.active) {
      let route = `/${this.props.locale.slug}/${routes[active]}`;

      if (active > 0) {
        route += `${window.location.search}`;
      }

      this.props.history.push(route);
    }
  }

  changeSlug(lang) {
    this.props.setLocale(lang);
    let url = window.location.href;
    if (url.indexOf(`/${this.props.locale.slug}/`) != -1) {
      url = url.split(`/${this.props.locale.slug}/`).join(`/${lang.slug}/`);
    } else {
      url += `${lang.slug}/`;
    }

    window.history.pushState({path: url}, '', url);
    if (lang.default_currency) {
      this.setCurrency(lang.default_currency);
    }
  }

  getStepClass(number) {
    return this.props.active ? this.props.active === number ? 'active' : this.props.active > number ? 'done' : '' : '';
  }

  setCurrency(currency) {
    const qs = queryString.parse(window.location.search);
    qs['currency'] = currency;
    const search = queryString.stringify(qs);
    this.props.setCurrency(currency);
    const url = window.location.origin + window.location.pathname + '?' + search;
    window.history.pushState({path: url}, '', url);
  }

  render() {
    const mainPageUrl = this.props.prismic && this.props.prismic.data.main_page_link.url;
    return (
      <div>
        <div className="navbar-buffer"></div>
        <div className="navbar fixed">
          <div className="container">
            <a href={mainPageUrl}>
              <div className="logo"></div>
            </a>
            {this.props.minimal ? '' : (
              <div className={`progress active ${this.props.active ? `active${this.props.active}` : ''}`}>
                <div onClick={() => {
                  this.changeRoute(0)
                }} className={`step step0 active`}></div>
                <div onClick={() => {
                  this.changeRoute(1)
                }} className={`step step1 ${this.getStepClass(1)}`}></div>
                <div onClick={() => {
                  this.changeRoute(2)
                }} className={`step step2 ${this.getStepClass(2)}`}></div>
                <div onClick={() => {
                  this.changeRoute(3)
                }} className={`step step3 ${this.getStepClass(3)}`}></div>
                <div onClick={() => {
                  this.changeRoute(4)
                }} className={`step step4 ${this.getStepClass(4)}`}></div>
                <div className="flex-row">
                  <div onClick={() => {
                    this.changeRoute(0)
                  }} className="flex1 active">
                    {t('Results')}
                  </div>
                  <div onClick={() => {
                    this.changeRoute(1)
                  }} className={`flex1 ${this.props.active && this.props.active >= 1 ? 'active' : ''}`}>
                    {t('Extras')}
                  </div>
                  <div onClick={() => {
                    this.changeRoute(2)
                  }} className={`flex1 ${this.props.active && this.props.active >= 2 ? 'active' : ''}`}>
                    {t('Protection')}
                  </div>
                  <div onClick={() => {
                    this.changeRoute(3)
                  }} className={`flex1 ${this.props.active && this.props.active >= 3 ? 'active' : ''}`}>
                    {t('Details')}
                  </div>
                  <div onClick={() => {
                    this.changeRoute(4)
                  }} className={`flex1 ${this.props.active && this.props.active >= 4 ? 'active' : ''}`}>
                    {t('Confirmation')}
                  </div>
                </div>
              </div>)}
            <div className="menu-heading">
              {this.props.nolang ? '' :
                (<div className="menu-heading-item margin16" onClick={() => {
                  this.setState({langMenu: !this.state.langMenu})
                }}>
                  <span
                    className="label">{t(this.getActiveLocale())} {this.props.minimal ? '' : (`| ${t(this.props.currency)}`)}</span>
                  <i className="icon icon-chevron-down-header"></i>
                  <div
                    className={`menu-container ${this.state.langMenu ? 'open' : ''} ${this.props.minimal ? 'minimal' : ''}`}>
                    <div className="menu">
                      <div className="flex-row">
                        <div className={`flex1 ${this.props.minimal ? '' : 'menu-separator'}`}>
                          {
                            this.props.conf.enabled_languages.map((l, i) => {
                              const lang = languages.find(language => language.val == l.language_code_in_prismic);

                              if (lang) {
                                return (<div key={i}
                                             className={`menu-item ${lang.val == this.props.locale.language_code_in_prismic ? 'active' : ''}`}
                                             onClick={() => this.changeSlug(l)}>{l.name}</div>);
                              }
                            })
                          }
                        </div>
                        {this.props.minimal ? '' : (
                          <div className="flex1">
                            {
                              this.props.conf.supported_currencies.map((cur, i) => {
                                const currency = currencies.find(c => c.val == cur.currency_code);

                                if (currency) {
                                  return (<div key={i}
                                               className={`menu-item ${currency.val == this.props.currency ? 'active' : ''}`}
                                               onClick={() => this.setCurrency(currency.val)}>{currency.val}</div>);
                                }
                              })
                            }
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>)
              }
            </div>
          </div>
          {this.state.showScrollTop && !this.props.minimal &&
              <div onClick={() => window.scrollTo(0, 0)} className="btn btn-blue rounded">{t('open search')}</div>}
        </div>
      </div>
    );
  }
}
