import types from '../types';

const details = (state = [], action) => {
    switch (action.type) {
        case types.SET_DETAIL:
            return Object.assign({}, state, action.obj);
        default:
            return state
    }
}

export default details