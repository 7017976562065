import { defaultLocale, defaultCurrency } from 'conf/env';
import fetch from 'cross-fetch';

import { t } from '../conf/gettext';
import prismic from 'prismic-javascript';

declare var BAPIjs: any;
export const getPrismic = (prismic, locale) => {
  if (prismic && prismic.results) {
    return prismic.results.find(
      res =>
        res.lang == locale.language_code_in_prismic &&
        ['suppler_content', 'content'].indexOf(res.type) != -1
    )
      ? prismic.results.find(
          res =>
            res.lang == locale.language_code_in_prismic &&
            ['suppler_content', 'content'].indexOf(res.type) != -1
        )
      : prismic.results.find(
          res =>
            res.lang == defaultLocale.language_code_in_prismic &&
            ['suppler_content', 'content'].indexOf(res.type) != -1
        );
  }
};

export const getPrismicConf = prismic =>
  prismic.results && prismic.results.find(r => r.type == 'configuration').data;

export const getRate = (rate, currency, exchange) => {

  if (!exchange) {
    return null;
  }
  const modifier = currency == exchange.base ? 1 : exchange.rates[currency];
  return currency === 'ISK'
    ? Math.ceil(rate * modifier)
    : (rate * modifier).toFixed(2);
};

export const getLocaleOrDefault = (available, slug) => {
  let locale = available.find(l => l.slug == slug);

  if (locale) {
    return locale;
  } else {
    return available.find(l => l.slug == defaultLocale.slug);
  }
};

export const getCurrencyOrDefault = (available, code) => {
  let currency = available.find(c => c.currency_code == code);

  if (currency) {
    return currency.currency_code;
  } else {
    return available.find(c => c.currency_code == defaultCurrency)
      .currency_code;
  }
};

export const sortExtras = extras => {
  const sortOrder = {
    12: 1,
    2003: 1,
    7: 2,
    8: 2,
    2004: 3,
    55: 3,
    46: 4
  };

  extras.sort((a, b) => {
    const aOrder = sortOrder[a.otaCode] || 999;
    const bOrder = sortOrder[b.otaCode] || 999;

    if (aOrder > bOrder) {
      return 1;
    } else if (aOrder < bOrder) {
      return -1;
    } else {
      return 0;
    }
  });

  return extras;
};

let config = null;
export const conf = (() => {
  /*if (config) {
        return config;
    } else {
        fetch('./config.json').then(
            res => res.json()
        ).then(
            res => {
                console.log(res);
                config = res;
                return config;
            }
        ).catch(
            err => {
                console.log(err);
            }
        );
    }*/
})();

export const addScriptUrlToHead = (url, id?) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    if (id) {
      script.id = id;
    }
    script.onload = () => resolve();
    document.head.appendChild(script);
  });
};

export const initPaymentMethod = async appConf => {
  if (appConf.enablePayments === 'borgun') {
    initBorgun(appConf);
  } else if (appConf.enablePayments === 'paypal') {
    initPaypal(appConf);
  } else if (appConf.enablePayments === 'valitor') {
    await addScriptUrlToHead(appConf.valitor.paymentLibrary);
  }
};

export const initBorgun = async appConf => {
  let publicToken = appConf.borgun.publicApiToken;
  await addScriptUrlToHead(appConf.borgun.paymentLibrary);
  BAPIjs.setPublicToken(publicToken);
};

export const initPaypal = async appConf => {
  await addScriptUrlToHead(
    appConf.paypal.paymentLibrary +
      '&currency=' +
      (appConf.paypal.currency || appConf.defaultCurrency),
    'paypal-script'
  );
};

const borgunErrorMapping = {
  '000': t('Card accepted'),
  '100': t('Card not accepted'),
  '101': t('Expired card'),
  '102': t('Suspected card forgery (fraud)'),
  '104': t('Restricted card'),
  '109': t('Merchant not identified'),
  '110': t('Invalid amount'),
  '111': t('Invalid card number'),
  '116': t('Not sufficient funds'),
  '118': t('Unknown card'),
  '119': t('Transaction not allowed to cardholder'),
  '120': t('Transaction not allowed to terminal'),
  '121': t('Exceeds limits to withdrawal'),
  '125': t('Card not valid'),
  '131': t('Invalid expiration date'),
  '200': t('Do not honor'),
  '201': t('Card not valid'),
  '208': t('Lost card'),
  '209': t('Stolen card'),
  '210': t('Suspected fraud'),
  '904': t('Form error'),
  '909': t('System error'),
  '953': t('Host timeout'),
  '916': t('Merchant not found')
};

export const getBorgunErrorMessage = code => {
  return (code && borgunErrorMapping[code]) || 'System error';
};

export { initAWS } from './aws-logger';
export { addSkyscannerToHead } from './skyscanner';

export const filterPromoCodeRates = (rates, filters, hasCode) => {
  return rates.filter(
    rate =>
      (!filters || !(filters.seatFilter && rate.passengerCount < 6) &&
      !(filters.automaticTransFilter && !rate.transmissionAuto) &&
      !(
        filters.driveTypeFilter &&
        rate.driveType != '4WD' &&
        rate.driveType != 'AWD'
      ) &&
      !(
        filters.categoryFilter != 'All' &&
        rate.size != filters.categoryFilter
      ) &&
      !(
        filters.productFilter != 'All' &&
        rate.productCode != filters.productFilter
      ))
      && (hasCode ? rate.promoCode && rate.promoCode.code : !(rate.promoCode && rate.promoCode.code))
  );
};

export const fillThreeDModalHtml = (htmlString) => {
  let html = htmlString.replace(/(\r\n|\r|\n)/g, '');
  const regexp = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
  const scripts = [];
  let script = regexp.exec(html);
  while (script) {
    if (!script) {
      break;
    }
    html = html.replace(script[0], '');
    const scriptEl = document.createElement('script');
    scriptEl.innerHTML = script[1];
    scripts.push(scriptEl);
    script = regexp.exec(html);
  }

  const el = document.getElementById('3dsecure-modal-html');
  if (el) {
    el.innerHTML = html;
    scripts.forEach(s => document.head.appendChild(s));
  }
}

export const createGoogleAnalyticsItemsListFromCart = (cart: any, days: number) => {

  if (!cart) return []
  return cart.map(item => {
    return {
      item_id: item.groupType.toUpperCase() + "_" + item.otaCode,
      item_name: item.name,
      item_category: item.groupType,
      days: days,
      price: Number(item.totalPrice.toFixed(2)),
      quantity: item.groupType === 'coverages' ? 1 : item.count
    };
  })
}

export const calculateReservationTotalPrice = (reservationPrice: number, cart: any, includeReservationPrice?: boolean) => {
  if (!cart) return reservationPrice;
  return cart.reduce((prev, v) => prev + (v.groupType === 'coverages' ? 1 : v.count) * v.totalPrice, !!includeReservationPrice ? reservationPrice : 0)
}

export const calculateReservationPriceWithMethod = (price, payOnline, offlineFee) => {
  if (payOnline) return price
  return price + offlineFee
}
