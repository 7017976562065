import types from '../types';

const rates = (state = [], action) => {
    switch (action.type) {
        case types.REQUEST_RATES:
            return Object.assign({}, state, action.rates, { isFetching: action.isFetching });
        case types.RECEIVE_RATES:
            return Object.assign({}, state, action.rates, { isFetching: action.isFetching });
        default:
            return state
    }
}
export default rates