import types from '../types';

const rateInfo = (state = [], action) => {
  switch (action.type) {
    case types.REQUEST_RATE_INFO:
      return Object.assign({}, state, {rateinfo: action.rateInfo});
    case types.RECEIVE_RATE_INFO:
      if (action.rateInfo.optionalItems && action.rateInfo.optionalItems.coverages) {
        action.rateInfo.optionalItems.coverages.forEach(item => item.count = 0);
      }

      if (action.rateInfo.optionalItems && action.rateInfo.optionalItems.extras) {
        action.rateInfo.optionalItems.extras.forEach(item => item.count = 0);
      }

      return Object.assign({}, state, {rateinfo: action.rateInfo});
    default:
      return state
  }
}

export default rateInfo