import {Dispatch} from 'redux';
import fetch from 'cross-fetch';
import types from '../types';

export function requestCountries() {
  return {
    type: types.REQUEST_COUNTRIES,
    isFetching: true,
    countries: null
  }
}

export function receiveCountries(countries: any) {
  return {
    type: types.RECEIVE_COUNTRIES,
    isFetching: false,
    countries: countries.countries
  }
}

export function fetchCountries(urls, apiKey): (dispatch: Dispatch<any, any>) => any {
  return function (dispatch: Dispatch<any, any>) {
    dispatch(requestCountries());

    return fetch(`${urls.reservationsAPI}/countries`,
      {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'X-API-KEY': apiKey
      }
    }
    )
      .then(res => res.json())
      .then(json => {
        return dispatch(receiveCountries(json));
      });
  }
}
