import React, {Component} from 'react';
import {DayPickerRangeController} from 'react-dates';
import * as moment from 'moment';
import {NavBar} from '../../components/Desktop/NavBar';
import {Listing} from '../../components/Desktop/Listing';
import {Loader} from '../../components/Loader';
import {months, vehicleTypes, warningMessages} from '../../conf/values';
import {t} from '../../conf/gettext';
import {filterPromoCodeRates, getCurrencyOrDefault, getLocaleOrDefault} from '../../helpers';
import queryString from 'query-string';
import fetch from 'cross-fetch';
import Footer from '../../components/Footer';
import {handleViewItemListEvent} from '../../helpers/analyticsEvents';

const initialState = {
  pickUpDatePickerOpen: false,
  dropOffDatePickerOpen: false,
  pickUpDate: moment().add(1, 'days'),
  dropOffDate: moment().add(5, 'days'),
  categoryFilter: 'All',
  searchChanged: false,
  focusedInput: 'startDate',
  opacity: 0,
  country: '',
  categoryFilterOpen: false,
  seatFilter: false,
  automaticTransFilter: false,
  driveTypeFilter: false,
  acrissArray: [],
  hideRest: null,
  productFilter: 'All',
  productFilterOpen: false
};
type State = Readonly<typeof initialState>

export default class Home extends Component<any, State> {
  readonly state: State = initialState;

  pickUpDesk: React.RefObject<HTMLSelectElement>;
  dropOffDesk: React.RefObject<HTMLSelectElement>;
  dropOffTime: React.RefObject<HTMLSelectElement>;
  pickUpTime: React.RefObject<HTMLSelectElement>;
  age: React.RefObject<HTMLSelectElement>;
  promoCode: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.state = initialState;

    if (this.props.match.params.locale) {
      this.props.setLocale(getLocaleOrDefault(this.props.conf.enabled_languages, this.props.match.params.locale));
    }

    this.pickUpDesk = React.createRef();
    this.dropOffDesk = React.createRef();
    this.dropOffTime = React.createRef();
    this.pickUpTime = React.createRef();
    this.age = React.createRef();
    this.promoCode = React.createRef();

    const qs = queryString.parse(this.props.location.search);

    setTimeout(() => {

      // temporary fix
      const map = {
        puLocation: 'pickUpDesk',
        doLocation: 'dropOffDesk',
        country: 'country',
        driverAge: 'age',
        puDate: 'pickUpDate',
        doDate: 'dropOffDate',
        promo: 'promoCode',
        cars: 'cars',
        hideRest: 'hideRest'
      };

      let url = `/${this.props.locale.slug}/prices?`;

      if (qs['currency']) {
        url += `currency=${qs['currency']}`;
        this.props.setCurrency(getCurrencyOrDefault(this.props.conf.supported_currencies, qs['currency']));
      } else {
        url += `currency=${this.props.currency}`;
      }

      if (qs['cars']) {
        const acrissArray = qs['cars'].split(',');
        url += '&cars=' + qs['cars'];
        this.setState({
          acrissArray
        })

      }
      if (qs['hideRest']) {
        const hideRest = qs['hideRest'] === 'true';
        if (hideRest) {
          url += '&hideRest=' + qs['hideRest'];
        }
        this.setState({
          hideRest
        });
      }
      if (qs['partnerCode']) {
        url += '&partnerCode=' + qs['partnerCode'];
        this.setState({
          partnerCode: qs['partnerCode']
        });
      }
      if (qs['apiKey']) {
        url += '&apiKey=' + qs['apiKey'];
        this.setState({
          apiKey: qs['apiKey']
        });
      }
      if (this.props.conf.default_pu_time && this.dropOffTime.current && this.pickUpTime.current) {
        this.pickUpTime.current.value = this.props.conf.default_pu_time;
        this.dropOffTime.current.value = this.props.conf.default_pu_time;
      }

      if (this.props.conf.default_pu_location_code && this.pickUpDesk.current) {
        this.pickUpDesk.current.value = this.props.conf.default_pu_location_code;
      }

      if (Object.keys(qs).length) {
        const ages = this.props.conf.driver_age_ranges;
        Object.keys(qs).forEach(ref => {
          const indexRef = map[ref];
          if (['pickUpDesk', 'dropOffDesk', 'age', 'promoCode'].indexOf(indexRef) != -1) {
            if (this[indexRef] && this[indexRef].current) {
              this[indexRef].current.value = qs[ref];
              if (indexRef === 'age') {
                const queryAge = parseInt(qs[ref]);
                for (const range of [...ages].reverse()) {
                  const min = parseInt(range.driver_age_range_min);
                  if (queryAge >= min) {
                    this[indexRef].current.value = min;
                    break;
                  }
                }
              }
            }

            if (qs[ref]) {
              url += `&${ref}=${qs[ref]}`;
            }

          }
          if (indexRef === 'country') {
            this.setState({country: qs[ref]});
            if (qs[ref]) {
              url += `&${ref}=${qs[ref]}`;
            }
          }
          if (['dropOffDate', 'pickUpDate'].indexOf(indexRef) != -1) {
            const date = moment(qs[ref]);
            const obj = {};
            obj[indexRef] = date;
            this.setState(obj);
            let timeRef;
            if (indexRef === 'dropOffDate') {
              timeRef = this.dropOffTime;
            } else {
              timeRef = this.pickUpTime;
            }
            if (timeRef && timeRef.current) {
              timeRef.current.value = `${(date.hours() < 10 ? '0' : '') + date.hours()}:${(date.minutes() < 10 ? '0' : '') + date.minutes()}`;
            }

            if (qs[ref] && date) {
              url += `&${ref}=${qs[ref]}`;
            }
          }
        });

        if (qs['daysToStart'] && !qs['puDate'] && !isNaN(qs['daysToStart'])) {
          const pickUpDate = moment(this.state.pickUpDate).add(Number(qs['daysToStart']) - 1, 'days');
          const dropOffDate = moment(pickUpDate).add(3, 'days');
          this.setState({pickUpDate, dropOffDate});
          url += `&puDate=${pickUpDate}&doDate=${dropOffDate}`;
        }

        if (!qs['doDate'] && qs['rentalDuration'] && !isNaN(qs['rentalDuration'])) {
          let date = moment();
          if (qs['puDate']) {
            date = moment(qs['puDate']);
          }
          const dropOffDate = date.add(qs['rentalDuration'], 'days');
          this.setState({dropOffDate});
          url += `&doDate=${dropOffDate}`;
        }

        //window.history.pushState({path: url}, '', url);

        if (this.props.location.pathname.indexOf('prices') != -1 &&
          (qs['currency'] || this.props.currency) &&
          this.age.current.value &&
          qs['puDate'] &&
          this.pickUpDesk.current.value &&
          qs['doDate'] &&
          this.dropOffDesk.current.value &&
          this.state.country) {
          this.props.fetchRates({
            currency: this.props.currency,
            driverAge: this.age.current.value,
            pickupDate: moment(qs['puDate']).toISOString(true).split('.')[0],
            pickupDeskCode: this.pickUpDesk.current.value,
            returnDate: moment(qs['doDate']).toISOString(true).split('.')[0],
            returnDeskCode: this.dropOffDesk.current.value,
            sourceCountry: this.state.country,
            promoCode: this.promoCode.current && this.promoCode.current.value,
            apiKey: qs['apiKey'],
            partnerCode: qs['partnerCode']
          }, this.props.baseConf, qs['apiKey']);
        }
      }

      this.setState({opacity: 1});
    }, 550);

    if (Object.keys(qs).indexOf('country') == -1) {
      fetch('https://ipapi.co/json/')
        .then(
          res => res.json()
        ).then(
        json => {
          if (json.country) {
            this.setState({country: json.country});
          }
        }
      ).catch(err => this.setState({country: this.props.conf.default_country_of_residency || 'GB'}));
    }

    if (this.props.search && Object.keys(this.props.search).length) {
      Object.keys(this.props.search).forEach(key => {
        if (this[key] && this[key].current) {
          this[key].current.value = this.props.search[key];
        }
        if (key === 'country') {
          this.setState({country: key});
        }
      })
    }
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.rates.rates && (this.props.rates.rates != prevProps.rates.rates)) {
      handleViewItemListEvent(this.props.rates.rates);
    }
  }

  fetchRates() {
    const pickUpDate = moment(this.state.pickUpDate);
    pickUpDate.minutes(parseInt(this.pickUpTime.current.value.split(':')[1]));
    pickUpDate.hours(parseInt(this.pickUpTime.current.value.split(':')[0]));

    const dropOffDate = moment(this.state.dropOffDate);
    dropOffDate.minutes(parseInt(this.dropOffTime.current.value.split(':')[1]));
    dropOffDate.hours(parseInt(this.dropOffTime.current.value.split(':')[0]));

    this.setState({searchChanged: false});
    const puDateIsoString = moment(pickUpDate).toISOString(true);
    const doDateIsoString = moment(dropOffDate).toISOString(true);
    const defaultAge = this.props.conf.driver_age_ranges.find(a => a.default === 'true') || this.props.conf.driver_age_ranges[0];
    let url = `/${this.props.locale.slug}/prices?country=${this.state.country}&driverAge=${(this.age.current && this.age.current.value) || defaultAge.driver_age_range_min}&puLocation=${this.pickUpDesk.current.value}&doLocation=${this.dropOffDesk.current.value == '0' || !this.dropOffDesk.current.value ? this.pickUpDesk.current.value : this.dropOffDesk.current.value}&puDate=${puDateIsoString && puDateIsoString.split('.')[0]}&doDate=${doDateIsoString && doDateIsoString.split('.')[0]}&currency=${this.props.currency}`;
    if (this.promoCode.current && this.promoCode.current.value) {
      url += '&promo=' + this.promoCode.current.value;
    }
    if (this.state.acrissArray && this.state.acrissArray.length) {
      url += '&cars=' + this.state.acrissArray.join(',');
      if (this.state.hideRest) {
        url += '&hideRest=true';
      }
    }
    if (this.state.apiKey) {
      url += '&apiKey=' + this.state.apiKey;
    }
    if (this.state.partnerCode) {
      url += '&partnerCode=' + this.state.partnerCode;
    }
    window.history.pushState({path: url}, '', url);

    return this.props.fetchRates({
      currency: this.props.currency,
      driverAge: (this.age.current && this.age.current.value) || defaultAge.driver_age_range_min,
      pickupDate: puDateIsoString && puDateIsoString.split('.')[0],
      pickupDeskCode: this.pickUpDesk.current.value,
      returnDate: doDateIsoString && doDateIsoString.split('.')[0],
      returnDeskCode: this.dropOffDesk.current.value == '0' || !this.dropOffDesk.current.value ? this.pickUpDesk.current.value : this.dropOffDesk.current.value,
      sourceCountry: this.state.country,
      promoCode: this.promoCode.current && this.promoCode.current.value,
      apiKey: this.state.apiKey,
      partnerCode: this.state.partnerCode
    }, this.props.baseConf, this.state.apiKey);
  }

  createTimeOptions() {
    let timeOptions = [];

    for (let i = 0; i < 24; i++) {
      for (let k = 0; k < 60; k += 15) {
        const time = `${(i < 10 ? '0' : '') + i}:${(k < 10 ? '0' : '') + k}`;
        timeOptions.push(<option key={time} value={time}>{time}</option>)
      }
    }

    return timeOptions
  }

  getDateString(date) {
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
  }

  sanitizeAge() {
    this.setState({searchChanged: true})
    this.age.current.value = this.age.current.value.replace(/[^0-9]/g, '');
  }

  render() {
    if (!this.props.locale || !this.props.countries || !this.props.prismic || !this.props.baseConf) return <Loader/>;

    const timeOptions = this.createTimeOptions();
    const searchBtn = this.props.rates.isFetching ? (<div className="spinner"></div>) : (
      <span><i className="icon icon-search-blue"></i> {t('Search')}</span>);
    let category = null;

    let usedVehicleTypes = [];

    if (this.props.rates && this.props.rates.rates) {
      this.props.rates.rates.forEach(rate => {
        if (usedVehicleTypes.indexOf(rate.size) == -1) usedVehicleTypes.push(rate.size);
      })
    }
    const ageOptions = this.props.conf.driver_age_ranges
      .map(r =>
        <option
          key={r.driver_age_range_min}
          value={r.driver_age_range_min}
        >{r.driver_age_range_max ? r.driver_age_range_min + '-' + r.driver_age_range_max + ' ' + t('years') : r.driver_age_range_min + '+ ' + t('years')}</option>);
    const defaultAge = this.props.conf.driver_age_ranges.find(a => a.default === 'true') || this.props.conf.driver_age_ranges[0];

    let rates = this.props.rates && this.props.rates.rates;
    const acrissArray = this.state.acrissArray;
    if (rates && acrissArray && acrissArray.length) {
      if (this.state.hideRest) {
        rates = rates.filter(r => acrissArray.indexOf(r.acrissCode) !== -1);
      }
      rates.sort((a, b) => {
        const aInArr = acrissArray.indexOf(a.acrissCode) !== -1;
        const bInArr = acrissArray.indexOf(b.acrissCode) !== -1;
        if (aInArr && !bInArr) {
          return -1;
        }
        if (bInArr && !aInArr) {
          return 1;
        }
        return a.rateTotalAmount - b.rateTotalAmount;
      });
    }
    let discountCars = [];
    let otherCars = [];
    if (rates) {
      if (this.promoCode.current && this.promoCode.current.value) {
        discountCars = filterPromoCodeRates(rates, this.state, true).map((rate, i) => (
          <div id={rate.vehicleId + rate.productCode} key={rate.vehicleId + rate.productCode}
               className="listing-container">
            <Listing carinfo={this.props.carinfo} prismic={this.props.prismic} clearCart={this.props.clearCart}
                     locale={this.props.locale} exchange={this.props.exchange} currency={this.props.currency}
                     rate={rate} items={this.props.items} history={this.props.history}/>
          </div>
        ));
        if (!this.state.hideRest) {
          otherCars = filterPromoCodeRates(rates, this.state, false).map((rate, i) => (
            <div id={rate.vehicleId + rate.productCode} key={rate.vehicleId + rate.productCode}
                 className="listing-container">
              <Listing carinfo={this.props.carinfo} prismic={this.props.prismic} clearCart={this.props.clearCart}
                       locale={this.props.locale} exchange={this.props.exchange} currency={this.props.currency}
                       rate={rate} items={this.props.items} history={this.props.history}/>
            </div>
          ));
        }
      } else {
        discountCars = filterPromoCodeRates(rates, this.state, false).map((rate, i) => (
          <div id={rate.vehicleId + rate.productCode} key={rate.vehicleId + rate.productCode}
               className="listing-container">
            <Listing carinfo={this.props.carinfo} prismic={this.props.prismic} clearCart={this.props.clearCart}
                     locale={this.props.locale} exchange={this.props.exchange} currency={this.props.currency}
                     rate={rate} items={this.props.items} history={this.props.history}/>
          </div>
        ));
      }
    }
    const selectedProduct = this.state.productFilter != 'All' ?
      this.props.baseConf.products.find(p => p.code === this.state.productFilter).translations[this.props.locale.rc_language_code] ||
      this.props.baseConf.products.find(p => p.code === this.state.productFilter).name :
      t('All products');
    return (
      <div style={{opacity: this.state.opacity}} className="background">
        <NavBar prismic={this.props.prismic} setCurrency={this.props.setCurrency} currency={this.props.currency}
                locale={this.props.locale} setLocale={this.props.setLocale} conf={this.props.conf}
                history={this.props.history} match={this.props.match}/>
        <div className="search padding32_0" id="search">
          <div className="container">
            <div className="flex-row">
              <div className="flex2 flex-row">
                <div className="flex1">
                  <div className="input-group">
                    <div className="row-item">
                      <div className="label">{t('Pickup location')}</div>
                      <select onChange={() => {
                        this.setState({searchChanged: true})
                      }} onBlur={() => {
                        this.props.setSearch({pickUpDesk: this.pickUpDesk.current.value})
                      }} ref={this.pickUpDesk} className="input">
                        {this.props.desks && Object.keys(this.props.desks).map((key) => {
                          const desk = this.props.desks[key];
                          return <option key={desk.code}
                                         value={desk.code}>{desk.name[this.props.locale.rc_language_code]}{desk.iata_code ? ' (' + desk.iata_code + ')' : ''}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex1 marginleft16">
                  <div className="input-group">
                    <div className="row-item">
                      <div className="label">{t('Pickup time')}</div>
                      <div className="flex-row">
                        <input
                          onClick={() => {
                            this.setState({
                              pickUpDatePickerOpen: !this.state.pickUpDatePickerOpen,
                              dropOffDatePickerOpen: false,
                              focusedInput: 'startDate'
                            })
                          }}
                          value={this.state.pickUpDate && moment(this.state.pickUpDate).format('DD.MM.YYYY') || ''}
                          className="flex2 input joined select"
                          onKeyDown={e => {
                            e.preventDefault();
                            e.stopPropagation()
                          }}
                          onBlur={() => {
                            this.props.setSearch({pickUpDate: this.state.pickUpDate})
                          }}
                          onChange={() => {
                            this.setState({searchChanged: true})
                          }}
                          name="start date"
                          readOnly
                        />
                        <select onChange={() => {
                          this.setState({searchChanged: true})
                        }} onBlur={() => {
                          this.props.setSearch({pickUpTime: this.pickUpTime.current.value})
                        }} ref={this.pickUpTime} className="flex1 input">
                          {timeOptions}
                        </select>
                        {this.state.pickUpDatePickerOpen ?
                          <DayPickerRangeController
                            renderMonthElement={(el) => {
                              return (
                                <span>{`${t(moment(el.month).format('MMMM').toLowerCase())} ${moment(el.month).format('YYYY')}`}</span>)
                            }}
                            startDate={this.state.pickUpDate ? moment(this.state.pickUpDate) : null}
                            endDate={this.state.dropOffDate ? moment(this.state.dropOffDate) : null}
                            onDatesChange={(date) => {
                              this.setState({
                                pickUpDate: date.startDate || null,
                                dropOffDate: date.endDate || null
                              });
                            }}
                            focusedInput={this.state.focusedInput}
                            numberOfMonths={2}
                            isOutsideRange={(date) => moment().diff(moment(date), 'days') > 0}
                            onFocusChange={(focusedInput) => {
                              this.setState({
                                focusedInput: focusedInput == null ? 'startDate' : focusedInput,
                                pickUpDatePickerOpen: focusedInput == null ? false : true
                              })
                            }}
                            onOutsideClick={() => this.setState({pickUpDatePickerOpen: false})}
                            minimumNights={0}/> : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex1">
                <div className="flex-row">
                  <div className="flex1 marginleft16">
                    <div className="input-group">
                      <div className="row-item">
                        <div className="label">{t('I am from')}</div>
                        <select onChange={(c) => {
                          this.setState({searchChanged: true, country: c.target.value})
                        }} onBlur={() => {
                          this.props.setSearch({country: this.state.country})
                        }} className="input country" value={this.state.country}>
                          {
                            this.props.countries.map((country, i) => {
                              return <option key={i}
                                             value={country.code}>{country.name[this.props.locale.rc_language_code]}</option>
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="input-group flex1 marginleft16">
                    <div className="row-item" style={{minWidth: 168}}>
                      <div className="label">{t("Driver's age")}</div>
                      <select
                        defaultValue={defaultAge.driver_age_range_min}
                        onBlur={() => {
                          this.props.setSearch({age: this.age.current.value})
                        }}
                        ref={this.age}
                        className="input age"
                        onChange={() => this.sanitizeAge()}>
                        {ageOptions}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-row">
              <div className="flex2 flex-row">
                <div className="flex1">
                  <div className="input-group">
                    <div className="row-item">
                      <div className="label">{t('Dropoff location')}</div>
                      <select onChange={() => {
                        this.setState({searchChanged: true})
                      }} onBlur={() => {
                        this.props.setSearch({dropOffDesk: this.dropOffDesk.current.value})
                      }} ref={this.dropOffDesk} className="input">
                        <option value={0}>{t('Same as pickup')}</option>
                        {this.props.desks && Object.keys(this.props.desks).map((key) => {
                          const desk = this.props.desks[key];
                          return <option key={desk.code}
                                         value={desk.code}>{desk.name[this.props.locale.rc_language_code]}{desk.iata_code ? ' (' + desk.iata_code + ')' : ''}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex1 marginleft16">
                  <div className="input-group">
                    <div className="row-item">
                      <div className="label">{t('Dropoff time')}</div>
                      <div className="flex-row">
                        <input
                          onClick={() => {
                            this.setState({
                              dropOffDatePickerOpen: !this.state.dropOffDatePickerOpen,
                              pickUpDatePickerOpen: false,
                              focusedInput: 'startDate'
                            })
                          }}
                          value={this.state.dropOffDate && moment(this.state.dropOffDate).format('DD.MM.YYYY') || ''}
                          className="flex2 input joined select"
                          onKeyDown={e => e.preventDefault()}
                          onBlur={() => {
                            this.props.setSearch({pickUpDate: this.state.pickUpDate})
                          }}
                          onChange={() => {
                            this.setState({searchChanged: true})
                          }}
                          name="end date"
                          readOnly/>
                        <select onChange={() => {
                          this.setState({searchChanged: true})
                        }} onBlur={() => {
                          this.props.setSearch({dropOffTime: this.dropOffTime.current.value})
                        }} ref={this.dropOffTime} className="flex1 input">
                          {timeOptions}
                        </select>
                        {this.state.dropOffDatePickerOpen ?
                          <DayPickerRangeController
                            renderMonthElement={(el) => {
                              return (
                                <span>{`${t(moment(el.month).format('MMMM').toLowerCase())} ${moment(el.month).format('YYYY')}`}</span>)
                            }}
                            startDate={this.state.pickUpDate ? moment(this.state.pickUpDate) : null}
                            endDate={this.state.dropOffDate ? moment(this.state.dropOffDate) : null}
                            onDatesChange={(date) => {
                              this.setState({
                                dropOffDate: date.startDate,
                                dropOffDatePickerOpen: false
                              });
                            }}
                            focusedInput={this.state.focusedInput}
                            numberOfMonths={2}
                            isOutsideRange={(date) => moment().diff(moment(date), 'days') > 0}
                            onFocusChange={(focusedInput) => {
                              this.setState({
                                focusedInput: focusedInput == null ? 'startDate' : focusedInput,
                                dropOffDatePickerOpen: focusedInput == null ? false : true
                              })
                            }}
                            onOutsideClick={() => this.setState({dropOffDatePickerOpen: false})}
                            minimumNights={0}/> : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex1">
                <div className="flex-row">
                  {this.props.baseConf.enablePromoCode ? <div className="flex1 marginleft16 input-group">
                    <div className="row-item">
                      <div className="label">{'Promo code'}</div>
                      <input className="input promo-code" ref={this.promoCode}/>
                    </div>
                  </div> : null}
                  <div className="flex1 marginleft16">
                    <div className="row-item">
                      <button className="btn btn-search-form" onClick={() => {
                        this.fetchRates()
                      }}>
                        {searchBtn}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {this.props.rates && this.props.rates.message == 'OK' && this.props.rates.rates && this.state.searchChanged &&
              <div className="search-overlay">
                  <i className="icon icon-reload-blue"></i>
                  <h2 className="text-blue">{t('Search terms have been changed')}</h2>
                  <p className="">{t('Perform a new search to see results for the new terms or press cancel to see the previous search results')}</p>
                  <div className="btn-group">
                      <button className="btn btn-white" onClick={() => {
                        this.setState({searchChanged: false})
                      }}>{t('cancel')}</button>
                      <button className="btn btn-blue" onClick={() => {
                        this.fetchRates()
                      }}>{t('search')}</button>
                  </div>
              </div>}
          {this.props.rates && this.props.rates.message == 'OK' && this.props.rates.rates && (
            <div className="filters">
              <div className="filter-heading">{t('Filter by')}:</div>
              <div className={`filter ${this.state.categoryFilter != 'All' && 'selected'}`}>
                <div className="select"
                     onClick={() => this.setState({categoryFilterOpen: !this.state.categoryFilterOpen})}>
                  {this.state.categoryFilter == 'All' ? t('All car types') : vehicleTypes[this.state.categoryFilter]}
                  <div className={`menu-container ${this.state.categoryFilterOpen ? 'open' : ''}`}>
                    <div className="menu">
                      <div className={`menu-item ${'All' == this.state.categoryFilter ? '' : 'active'}`}
                           onClick={() => this.setState({categoryFilter: 'All'})}>
                        {t('All car types')}
                      </div>
                      {
                        usedVehicleTypes.map((type, i) => {
                          return (
                            <div key={i} className={`menu-item ${type == this.state.categoryFilter ? '' : 'active'}`}
                                 onClick={() => this.setState({categoryFilter: type})}>
                              {vehicleTypes[type]}
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className={`filter ${this.state.driveTypeFilter && 'selected'}`}
                   onClick={() => this.setState({driveTypeFilter: !this.state.driveTypeFilter})}>
                <div className={`checkbox checkbox-xs ${this.state.driveTypeFilter && 'checked'}`}></div>
                {`${t('4WD')}/${t('AWD')}`}
              </div>
              <div className={`filter ${this.state.seatFilter && 'selected'}`}
                   onClick={() => this.setState({seatFilter: !this.state.seatFilter})}>
                <div className={`checkbox checkbox-xs ${this.state.seatFilter && 'checked'}`}></div>
                {t('6+ seats')}
              </div>
              <div className={`filter ${this.state.automaticTransFilter && 'selected'}`}
                   onClick={() => this.setState({automaticTransFilter: !this.state.automaticTransFilter})}>
                <div className={`checkbox checkbox-xs ${this.state.automaticTransFilter && 'checked'}`}></div>
                {t('Automatic')}
              </div>
              {this.state.hideRest !== null && <div className={`filter ${this.state.hideRest && 'selected'}`}
                                                    onClick={() => this.setState({hideRest: !this.state.hideRest})}>
                  <div className={`checkbox checkbox-xs ${this.state.hideRest && 'checked'}`}></div>
                {t('Show only campaign offers')}
              </div> || null}
              {this.props.baseConf.enableMultipleProducts && !this.state.apiKey ?
                <div className={`filter ${this.state.productFilter != 'All' && 'selected'}`}>
                  <div className="select"
                       onClick={() => this.setState({productFilterOpen: !this.state.productFilterOpen})}>
                    {this.state.productFilter == 'All' ? t('All products') : selectedProduct}
                    <div className={`menu-container ${this.state.productFilterOpen ? 'open' : ''}`}>
                      <div className="menu">
                        <div className={`menu-item ${'All' == this.state.productFilter ? '' : 'active'}`}
                             onClick={() => this.setState({productFilter: 'All'})}>
                          {t('All products')}
                        </div>
                        {
                          this.props.baseConf.products.map((p, i) => {
                            return (
                              <div key={i} className={`menu-item ${p.code == this.state.productFilter ? '' : 'active'}`}
                                   onClick={() => this.setState({productFilter: p.code})}>
                                {p.translations[this.props.locale.rc_language_code] || p.name}
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div> : null}
              {this.state.automaticTransFilter || this.state.seatFilter || this.state.driveTypeFilter || this.state.categoryFilter != 'All' || this.state.productFilter != 'All' ?
                (<div className="filter clear" onClick={() => this.setState({
                  automaticTransFilter: false,
                  seatFilter: false,
                  driveTypeFilter: false,
                  categoryFilter: 'All',
                  productFilter: 'All'
                })}>
                  <i className="icon icon-cross-light-gray"></i>
                </div>) : ''
              }
            </div>
          )}
          <div className="results">
            {this.props.rates.isFetching ? [<div key="1" className="listing-loading margin8_0"></div>,
              <div key="2" className="listing-loading margin8_0"></div>,
              <div key="3" className="listing-loading margin8_0"></div>] : ''}
            {rates && this.props.rates.message == 'OK' ?
              <>
                {discountCars}
                {otherCars}
              </> : ''
            }
            {rates && this.props.rates.message == 'OK' && !discountCars.length && !otherCars.length && this.state.hideRest ?
              <div className="listing-error">
                <h2 className="text-blue text-center">{
                  this.state.acrissArray.length && t('Sorry, this car is not available for selected time period') ||
                  this.promoCode.current && this.promoCode.current.value && t('Campaign offers for this period are sold out or expired')
                }</h2>
                <a
                  tabIndex={-1}
                  className="text-center"
                  onClick={() => this.setState({hideRest: false, acrissArray: []})}
                >{t('Show all cars for this time period')}</a>
              </div> : null
            }
            {
              this.props.rates.message && this.props.rates.message != 'OK' ?
                <div className="listing-error">
                  <h2
                    className="text-blue text-center">{t(warningMessages[this.props.rates.code]) || this.props.rates.message}</h2>
                  {(this.props.rates.code === 30101 || this.props.rates.code === 30102) && this.props.openingTimes ?
                    <div className="text-center">
                      <h3>{t('Opening times at selected date:')}</h3>
                      <ul style={{listStyle: 'none'}}>
                        {this.props.openingTimes.open1 ?
                          <li>{this.props.openingTimes.open1} - {this.props.openingTimes.close1}</li> : null}
                        {this.props.openingTimes.open2 ?
                          <li>{this.props.openingTimes.open2} - {this.props.openingTimes.close2}</li> : null}
                        {this.props.openingTimes.open3 ?
                          <li>{this.props.openingTimes.open3} - {this.props.openingTimes.close3}</li> : null}
                      </ul>
                    </div> : null}
                </div> : ''
            }
            {
              this.props.rates && this.props.rates.message == 'OK' ?
                <Footer prismic={this.props.prismic}/> : ''
            }
          </div>
        </div>
      </div>
    );
  }
};
