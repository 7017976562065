import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {withRouter} from 'react-router';
import {data, fetchRates} from '../../../actions/rates';
import {setCurrency} from '../../../actions/currency';
import {setSearch} from '../../../actions/search';
import {setLocale} from '../../../actions/locale';
import {clearCart} from '../../../actions/cart';
import HomeView from '../../../views/Desktop/Home';
import {getPrismic} from '../../../helpers';

const mapStateToProps = state => {
  return {
    desks: state.desks.desks,
    openingTimes: state.desks.openingTimes,
    rates: state.rates,
    items: state.items.items,
    prismic: getPrismic(state.prismic.prismic, state.locale.locale),
    currency: state.currency.currency,
    exchange: state.currency.exchange,
    search: state.search,
    locale: state.locale.locale,
    countries: state.countries.countries && state.countries.countries.length ? state.countries.countries.sort((a, b) => {
      let cA = a.name[state.locale.locale.rc_language_code];
      cA = cA ? cA.toUpperCase() : 'undefined';
      let cB = b.name[state.locale.locale.rc_language_code];
      cB = cB ? cB.toUpperCase() : 'undefined';
      return (cA < cB) ? -1 : (cA > cB) ? 1 : 0;
    }) : [],
    conf: state.prismic.prismic && state.prismic.prismic.results.find(r => r.type == 'configuration').data,
    baseConf: state.config.config,
    carinfo: state.carinfo
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any, any>) => {
  return {
    fetchRates: (data: data, conf, apiKey) => {
      dispatch(fetchRates(data, conf, apiKey))
    },
    setCurrency: (currency: string) => {
      dispatch(setCurrency(currency))
    },
    setSearch: (obj: {}) => {
      return dispatch(setSearch(obj))
    },
    setLocale: (locale) => {
      return dispatch(setLocale(locale))
    },
    clearCart: () => {
      return dispatch(clearCart())
    }
  }
}

export default withRouter(connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(HomeView));
