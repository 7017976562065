import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withRouter } from 'react-router';
import { removeItem, togglePayOnline } from '../../actions/cart';
import { setCurrency } from '../../actions/currency';
import { Cart } from '../../components/Cart';
import { getPrismic } from '../../helpers';

const mapStateToProps = state => {
    return {
        rateInfo: state.rateInfo.rateinfo,
        cart: state.cart.items,
        payOnline: state.cart.payOnline,
        currency: state.currency.currency,
        exchange: state.currency.exchange,
        prismic: getPrismic(state.prismic.prismic, state.locale.locale),
        conf: state.prismic.prismic && state.prismic.prismic.results.find(r => r.type == 'configuration').data,
        locale: state.locale.locale,
        items: state.items.items,
        baseConf: state.config.config
    }
}

const mapDispatchToProps = (dispatch: Dispatch<any, any>) => {
    return {
        removeItem: (item, props) => {
            dispatch(removeItem(item, props))
        },
        setCurrency: (currency) => {
            dispatch(setCurrency(currency))
        },
        togglePayOnline: (value) => dispatch(togglePayOnline(value))
    }
}

export default withRouter(connect<{}, {}, any>(
    mapStateToProps,
    mapDispatchToProps
)(Cart));
