import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {withRouter} from 'react-router';
import {setCurrency} from '../../../actions/currency';
import {setLocale} from '../../../actions/locale';
import {fetchModifyReservation, putModifyReservation, receiveModifyReservation} from '../../../actions/reservation';
import InfoUpdateView from '../../../views/Desktop/InfoUpdate';
import {getPrismic} from '../../../helpers';

const mapStateToProps = state => {

  return {
    desks: state.desks.desks,
    prismic: getPrismic(state.prismic.prismic, state.locale.locale),
    currency: state.currency.currency,
    exchange: state.currency.exchange,
    locale: state.locale.locale,
    countries: state.countries.countries && state.countries.countries.length > 1 ? state.countries.countries.sort((a, b) => {
      let cA = a.name[state.locale.locale.rc_language_code];
      cA = cA ? cA.toUpperCase() : 'undefined';
      let cB = b.name[state.locale.locale.rc_language_code];
      cB = cB ? cB.toUpperCase() : 'undefined';
      return (cA < cB) ? -1 : (cA > cB) ? 1 : 0;
    }) : [],
    reservation: state.reservation,
    conf: state.prismic.prismic && state.prismic.prismic.results.find(r => r.type == 'configuration').data,
    baseConf: state.config.config
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any, any>) => {
  return {
    setCurrency: (currency: string) => {
      dispatch(setCurrency(currency))
    },
    setLocale: (locale) => {
      dispatch(setLocale(locale))
    },
    fetchModifyReservation: (data, conf) => {
      dispatch(fetchModifyReservation(data, conf))
    },
    receiveModifyReservation: () => {
      dispatch(receiveModifyReservation(null))
    },
    putModifyReservation: (id, data, conf) => {
      dispatch(putModifyReservation(id, data, conf));
    }
  };
}

export default withRouter(connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(InfoUpdateView));