import React, {Component} from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import {t} from '../../conf/gettext';
import {NavBar} from '../../components/Desktop/NavBar';
import {RouteComponentProps} from 'react-router-dom';
import {mileage, monthsShort} from '../../conf/values';
import {Loader} from '../../components/Loader';
import {getCurrencyOrDefault, getLocaleOrDefault, getRate} from '../../helpers';
import queryString from 'query-string';
import Footer from '../../components/Footer';
import SkyScanner from '../../helpers/skyscanner';
import TagManager from 'react-gtm-module';
import {CarDetails} from '../../components/Desktop/Listing/Listing';

const initialState = {
  detailedOpen: false,
  modalOpen: false,
  modal: {},
  errors: {},
  driverIndex: 0,
  drivers: [{}]
};
type State = Readonly<typeof initialState>

Modal.setAppElement('#app');

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

export default class Booking extends Component<RouteComponentProps<any>, State> {
  readonly state: State = initialState;
  reservationNumber: string | string[] = '';

  constructor(props) {
    super(props);

    this.driver = {
      firstName: React.createRef(),
      lastName: React.createRef(),
      expiryMM: React.createRef(),
      expiryYY: React.createRef(),
      licenseNumber: React.createRef()
    };

    this.accomodationName = React.createRef();
    this.pickupTime = React.createRef();
    this.numPassengers = React.createRef();

    if (this.props.match.params.locale) {
      this.props.setLocale(getLocaleOrDefault(this.props.conf.enabled_languages, this.props.match.params.locale));
    }

    const qs = queryString.parse(this.props.location.search);
    if (qs['reservationNumber']) {
      this.reservationNumber = qs['reservationNumber'];
    }
    if (qs['currency']) {
      this.props.setCurrency(getCurrencyOrDefault(this.props.conf.supported_currencies, qs['currency']));
    }
  }

  clearModal(indMod) {
    const driverIndex = this.state.driverIndex + indMod;

    this.driver.firstName.current.value = this.state.drivers[driverIndex].firstName || '';
    this.driver.lastName.current.value = this.state.drivers[driverIndex].lastName || '';
    this.driver.expiryMM.current.value = '';
    this.driver.expiryYY.current.value = '';
    this.driver.licenseNumber.current.value = this.state.drivers[driverIndex].licenseNumber || '';

    if (this.state.drivers[driverIndex].expiry && !this.state.drivers[driverIndex].noExpiry) {
      const expiry = this.state.drivers[driverIndex].expiry.split('/');
      this.driver.expiryMM.current.value = expiry[0];
      this.driver.expiryYY.current.value = expiry[1];
    }

    this.setState({driverIndex});
  }

  componentDidMount() {
    this.fetchReservation(this.props);
    document.title = t('Confirmation') + ' | ' + this.props.prismic.data.meta_title;
  }

  componentWillUnmount() {
    document.title = this.props.prismic.data.meta_title;
  }

  getOpeningTimes(desk, date) {

    const weekday = moment(date).isoWeekday();

  }

  componentWillReceiveProps(props) {
    if (!this.props.hotel.hotelBooked && props.hotel.hotelBooked) {
      this.setState({hotelModalOpen: true});
    }

    if (this.props.reservation && !this.props.reservation.drivers && props.reservation.drivers && props.reservation.drivers.length) {
      const drivers = props.reservation.drivers.map((driver) => {
        driver.noExpiry = driver.expiry === 'noExp';
        return driver;
      });
      this.setState({drivers});

      if (SkyScanner.isToPush(props.reservation.reservationNumber)) {
        SkyScanner.pushTracking(SkyScanner.getCookie(), props.reservation, props.baseConf);
      }
      const payNow = props.reservation.pricing.payNow ? props.reservation.pricing.payNow.totalAmount : 0
      const payOnPickup = props.reservation.pricing.payOnPickup ? props.reservation.pricing.payOnPickup.totalAmount : 0

      if (localStorage.getItem('_reservation_nr') !== props.reservation.reservationNumber) {
        const tagManagerArgs = {
          dataLayer: {
            reservation: {
              reservationNumber: props.reservation.reservationNumber,
              paidNow: payNow,
              payableOnPickup: payOnPickup,
              sum: payNow + payOnPickup
            },
            event: 'reservation_created'
          }
        };
        TagManager.dataLayer(tagManagerArgs);
      }
      localStorage.setItem('_reservation_nr', props.reservation.reservationNumber);
    }
    if (!this.state.fetchedRate && props && Object.keys(props.baseConf).length) {
      this.props.fetchReservation(this.reservationNumber, props.baseConf.urls);
      this.setState({fetchedRate: true});
    }
  }

  fetchReservation(props) {
    if (!this.state.fetchedRate && props.baseConf && Object.keys(props.baseConf).length) {
      this.props.fetchReservation(this.reservationNumber, props.baseConf.urls);
      this.setState({fetchedRate: true});
    }
  }

  toggleModal(driverIndex = 0) {
    this.setState({modalOpen: !this.state.modalOpen, driverIndex});
  }

  toggleHotelModal() {
    this.setState({hotelModalOpen: !this.state.hotelModalOpen});
  }

  getTime(date) {
    const hours = date.hours() < 10 ? `0${date.hours()}` : date.hours();
    const minutes = date.minutes() < 10 ? `0${date.minutes()}` : date.minutes();

    return `${hours}:${minutes}`;
  }

  getLocationString(desk) {
    if (desk.latitude && desk.longitude) {
      return `${desk.latitude},${desk.longitude}`;
    } else {
      return [desk.address1, desk.address2, desk.city, desk.country].join('+').replace(' ', '+');
    }
  }

  addDriver() {
    const drivers = this.state.drivers;
    drivers.forEach(driver => {
      for (let key in driver) {
        if (!driver[key] || driver[key] == 'null' || driver[key] == 'undefined') {
          driver[key] = '';
        }
      }
    });
    const qs = queryString.parse(this.props.location.search);
    this.props.addDriver({drivers}, this.reservationNumber, this.props.baseConf.urls);
    this.toggleModal();
  }

  getMarketingBody(text) {
    let __html = '';
    let isList = false;
    text.forEach(t => {
      if (t.type == 'paragraph') {
        if (isList) isList = false && __html += '</ul>';

        __html += `<p>${t.text}</p>`;
      } else if (t.type == 'list-item') {
        if (!isList) isList = true && __html += '<ul>';

        __html += `<li>${t.text}</li>`;
      }
    });

    return {__html};
  }

  createTimeOptions() {
    let timeOptions = [];

    for (let i = 0; i < 24; i++) {
      for (let k = 0; k < 60; k += 30) {
        const time = `${(i < 10 ? '0' : '') + i}:${(k < 10 ? '0' : '') + k}`;
        timeOptions.push(<option key={time} value={time}>{time}</option>);
      }
    }

    return timeOptions;
  }

  getPickupDesk() {
    return this.props.desks.filter((desk) => desk.code === this.props.reservation.pickupDeskCode)[0];
  }

  getReturnDesk() {
    return this.props.desks.filter((desk) => desk.code === this.props.reservation.returnDeskCode)[0];
  }

  bookHotel() {
    const errors = {};

    if (!this.accomodationName.current.value) {
      errors['accomodationName'] = true;
    }

    if (isNaN(this.numPassengers.current.value) || this.numPassengers.current.value <= 0) {
      errors['numPassengers'] = true;
    }

    this.setState({errors});

    if (!Object.keys(errors).length) {
      this.props.bookHotel({
        to_email: this.props.conf.supplier_email_hotel_delivery,
        reservationNumber: this.props.match.params.hash,
        pickup_time: this.pickupTime.current.value,
        accommodation: this.accomodationName.current.value,
        passengers: Number(this.numPassengers.current.value)
      }, this.props.baseConf.urls);
    }
  }

  getItem(items, itemGroupType, itemOtaCode) {
    return items[itemGroupType].filter((item) => item.otaCode === itemOtaCode)[0];
  }

  render() {
    if (!(this.props.prismic && this.props.prismic.data) || !this.props.items || !this.props.reservation || !Object.keys(this.props.reservation).length || !this.state.drivers.length || !this.props.baseConf) return <Loader/>;

    const pickUpDate = moment(this.props.reservation.pickupDate.split('Z')[0]);
    const returnDate = moment(this.props.reservation.returnDate.split('Z')[0]);
    const mileageText = this.props.reservation.mileagePolicy.mileageTypeUnlimited ?
      t('Unlimited mileage') :
      `${this.props.reservation.mileagePolicy.mileageQuantity} ${t(mileage[this.props.reservation.mileagePolicy.mileageUnitType.toLowerCase()])} ${t(mileage[this.props.reservation.mileagePolicy.mileageUnitPeriodType.toLowerCase()])}`;
    let driveType = null;

    const optionalExtras = this.props.reservation.optionalItems.extras.map((item, i) => {
      const itemData = this.getItem(this.props.items, 'extras', item.otaCode);
      return (
        <div key={i} className="description-item gray margin8_0">
          <i className="icon icon-check-green"></i>
          <span className="margin0_8">{itemData.name[this.props.locale.rc_language_code]} {item.quantity > 1 ? `x ${item.quantity}` : ''}</span>
        </div>
      );
    });

    const optionalCoverages = this.props.reservation.optionalItems.coverages.map((item, i) => {
      const itemData = this.getItem(this.props.items, 'coverages', item.otaCode);
      return (
        <div key={i} className="description-item gray margin8_0">
          <i className="icon icon-check-green"></i>
          <span className="margin0_8">{itemData.name[this.props.locale.rc_language_code]} {item.quantity > 1 ? `x ${item.quantity}` : ''}</span>
        </div>
      );
    });

    if (this.props.reservation.driveType == '4WD') {
      driveType = t('4WD');
    }

    if (this.props.reservation.driveType == 'AWD') {
      driveType = t('AWD');
    }
    const pickupDesk = this.getPickupDesk();
    const returnDesk = this.getReturnDesk();

    return (
      <div>
        <NavBar prismic={this.props.prismic} conf={this.props.conf} active={4} currency={this.props.currency}
                locale={this.props.locale} setCurrency={this.props.setCurrency} setLocale={this.props.setLocale}
                history={this.props.history} match={this.props.match}/>
        <div className="search">
          <div className="container">
            <div className="flex-row text-on-primary">
              <div className="flex3">
                <i
                  className={`icon icon-${this.props.reservation.status == 'PENDING' ? 'pending' : 'confirmation'}-check`}></i>
                <h3
                  className="margin">{this.props.reservation.status == 'PENDING' ? t('Booking pending') : t('Booking Confirmed')}</h3>
                <div>
                  {this.props.reservation.status == 'PENDING' ? this.props.prismic.data.confirmation_pending_message[0].text : this.props.prismic.data.confirmation_confirmed_message[0].text}
                </div>
              </div>
              <div className="flex1 text-right">
                <h3
                  className="margin">{this.props.reservation.pricing.payNow ? getRate(this.props.reservation.pricing.payNow.totalAmount, this.props.currency, this.props.exchange) : 0} {this.props.currency}</h3>
                <div>{t('Paid now')}</div>
              </div>
              <div className="flex1 text-right">
                <h3
                  className="margin">{this.props.reservation.pricing.payOnPickup ? getRate(this.props.reservation.pricing.payOnPickup.totalAmount, this.props.currency, this.props.exchange
                ) : 0 } {this.props.currency}</h3>
                <div>{t('Payable on pickup')}</div>
              </div>
              <div className="flex1 text-right">
                <h3 className="margin">{this.props.reservation.reservationNumber}</h3>
                <div>{t('Reservation number')}</div>
              </div>
            </div>
            <div className="separator"></div>
            <div className="search-row">
              <div className="search-item">
                <h3 className="margin16_0">{t('Pick-up')}</h3>
                <div className="datetime-info">
                  <div className="date">
                    <div className="month">
                      {monthsShort[pickUpDate.month()]}
                    </div>
                    <div className="numeric">
                      {pickUpDate.date()}
                    </div>
                  </div>
                  <div className="location">
                    <div className="location-row">
                      <i className="icon icon-location"></i>
                      <span>{pickupDesk.name[this.props.locale.rc_language_code]}</span>
                    </div>
                    <div className="location-row">
                      <i className="icon icon-time"></i>
                      <span>{this.getTime(pickUpDate)}</span>
                    </div>
                  </div>
                </div>
                <button className="btn btn-transparent"
                        onClick={() => this.setState({detailedOpen: !this.state.detailedOpen})}>{this.state.detailedOpen ? t('Hide') : t('Show')} {t('detailed pick-up information')}</button>
                <div className={`detailed-info text-on-primary ${this.state.detailedOpen && 'open'}`}>
                  {pickupDesk.pickupInstructions[this.props.locale.rc_language_code] ?
                    [<div className="flex-row margin16_0" key="ld_1">
                      <div className="flex1">
                        <h3>{t('Location details')}</h3>
                      </div>
                    </div>,
                      <div className="flex-row" key="lg_1">
                        <div className="flex1">
                          {pickupDesk.pickupInstructions[this.props.locale.rc_language_code]}
                        </div>
                      </div>] : ''
                  }
                  <div className="flex-row margin16_0">
                    <div className="flex1">
                      <h3>{t('Opening hours')}</h3>
                    </div>
                  </div>
                  <div className="flex-row">
                    <div className="flex1">
                      {t('On your pick-up date, we are open at the following times')}
                    </div>
                  </div>
                  <div className="flex-row margin8_0">
                    <div className="flex1">
                      <strong>{`${pickupDesk.address}, ${pickupDesk.city}, ${pickupDesk.country}`}</strong>
                    </div>
                    {/*<div className="flex1">*/}
                    {/*  <strong>{this.props.reservation.pickupDesk.openingHours}</strong>*/}
                    {/*</div>*/}
                  </div>
                  <div className="flex-row">
                    <iframe className="flex1"
                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBe6ryJmWCL6i-9n31E37dFru2Jpvsxs4E&q=${this.getLocationString(pickupDesk)}&zoom=10`}></iframe>
                  </div>
                </div>
              </div>
              <div className="search-item">
                <h3 className="margin16_0">{t('Drop-off')}</h3>
                <div className="datetime-info">
                  <div className="date">
                    <div className="month">
                      {monthsShort[returnDate.month()]}
                    </div>
                    <div className="numeric">
                      {returnDate.date()}
                    </div>
                  </div>
                  <div className="location">
                    <div className="location-row">
                      <i className="icon icon-location"></i>
                      <span>{returnDesk.name[this.props.locale.rc_language_code]}</span>
                    </div>
                    <div className="location-row">
                      <i className="icon icon-time"></i>
                      <span>{this.getTime(returnDate)}</span>
                    </div>
                  </div>
                </div>
                <button className="btn btn-transparent"
                        onClick={() => this.setState({detailedOpen: !this.state.detailedOpen})}>{this.state.detailedOpen ? t('Hide') : t('Show')} {t('detailed drop-off information')}</button>
                <div className={`detailed-info text-on-primary ${this.state.detailedOpen && 'open'}`}>
                  {returnDesk.returnInstructions[this.props.locale.rc_language_code] ?
                    [<div className="flex-row margin16_0" key="ld_2">
                      <div className="flex1">
                        <h3>{t('Location details')}</h3>
                      </div>
                    </div>,
                      <div className="flex-row" key="lg_2">
                        <div className="flex1">
                          {returnDesk.returnInstructions[this.props.locale.rc_language_code]}
                        </div>
                      </div>] : ''
                  }
                  <div className="flex-row margin16_0">
                    <div className="flex1">
                      <h3>{t('Opening hours')}</h3>
                    </div>
                  </div>
                  <div className="flex-row">
                    <div className="flex1">
                      {t('On your drop-off date, we are open at the following times')}
                    </div>
                  </div>
                  <div className="flex-row margin8_0">
                    <div className="flex1">
                      <strong>{`${returnDesk.address}, ${returnDesk.city}, ${returnDesk.country}`}</strong>
                    </div>
                    {/*<div className="flex1">*/}
                    {/*  <strong>{this.props.reservation.returnDesk.openingHours}</strong>*/}
                    {/*</div>*/}
                  </div>
                  <div className="flex-row">
                    <iframe className="flex1"
                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBe6ryJmWCL6i-9n31E37dFru2Jpvsxs4E&q=${this.getLocationString(returnDesk)}&zoom=10`}></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="margin32_0">
            <div className="flex-row listing no-borders">
              <CarDetails
                rate={this.props.reservation}
                carinfo={this.props.carinfo}
                items={this.props.items}
                locale={this.props.locale}
                showPrice={false}
                currency={this.props.currency}
                exchange={this.props.exchange}
              />
              <div className="flex1 marginleft8">
                {optionalExtras || optionalCoverages ?
                  <div className="text-dark-gray text-lg text-bold margin16_0">{t('Extras purchased')}</div> : ''}
                {optionalExtras}
                {optionalCoverages}
                <button onClick={() => {
                  this.toggleModal();
                }} className="btn btn-blue">{t('Fill in driver details')}</button>
                <div className="text-dark-gray text-lg text-bold margin16_0">{t('Drivers')}</div>
                {
                  this.props.reservation.drivers.map((driver, i) => {
                    if (driver.firstName && driver.lastName && !(driver.firstName == 'null' && driver.lastName == 'null')) {
                      return (
                        <div key={i} onClick={() => this.toggleModal(i)}
                             className="description-item gray margin8_0 pointer">
                          <i className="icon icon-person"></i>
                          <span
                            className="margin0_8">{`${driver.firstName != 'null' ? driver.firstName : ''} ${driver.lastName != 'null' ? driver.lastName : ''}`}</span>
                        </div>
                      );
                    } else {
                      return (
                        <div key={i} onClick={() => this.toggleModal(i)}
                             className="description-item gray margin8_0 pointer">
                          <i className="icon icon-person"></i>
                          <span className="margin0_8">{`${t('Additional driver')} #${i + 1}`}</span>
                        </div>
                      );
                    }
                  })
                }
              </div>
            </div>
            <div className="separator"></div>
            <div className="flex-row wrap marginbottom32">
              {
                this.props.conf.enable_hotel_delivery == 'true' ? (
                  <div className="flex1_0_48 marginbottom32">
                    <div className="margin16_0 text-blue text-bold text-lg">{t('Book a hotel pick-up')}</div>
                    {!!this.props.prismic.data.book_hotel_pickup_description && <div
                        className="margin16_0 text-md text-dark-gray">{this.props.prismic.data.book_hotel_pickup_description}</div>}
                    <div className="input-group">
                      <div className="row-item">
                        <div
                          className={`label ${this.state.errors.accomodationName ? 'has-error' : ''}`}>{t('Accomodation name')}</div>
                        <input ref={this.accomodationName}
                               className={`input minwidth100 ${this.state.errors.accomodationName ? 'has-error' : ''}`}
                               placeholder={t('Enter the name of your place of stay ...')}/>
                      </div>
                    </div>
                    <div className="input-group">
                      <div className="row-item">
                        <div
                          className={`label ${this.state.errors.pickupTime ? 'has-error' : ''}`}>{t('Requested pick-up time')}</div>
                        <select defaultValue="11:00" ref={this.pickupTime}
                                className={`input minwidth100 ${this.state.errors.pickupTime ? 'has-error' : ''}`}>
                          {this.createTimeOptions()}
                        </select>
                      </div>
                      <div className="row-item">
                        <div
                          className={`label ${this.state.errors.numPassengers ? 'has-error' : ''}`}>{t('Number of passengers')}</div>
                        <input ref={this.numPassengers}
                               className={`input minwidth100 ${this.state.errors.numPassengers ? 'has-error' : ''}`}
                               placeholder="0"/>
                      </div>
                    </div>
                    <div className="flex-row">
                      <div className="flex1">
                        <button className="btn btn-green" onClick={() => this.bookHotel()}>
                          {this.props.hotel.isFetching ? <div className="spinner green"></div> : t('Book a pick-up')}
                        </button>
                      </div>
                    </div>
                    <div className="flex-row">
                      <div className="flex1 fine-print margin12">
                        {this.props.hotel.error ? <span
                          className="text-red">{t('Unable to book hotel pick-up, please try again later')}</span> : t('A confirmation message will be sent to the renter’s e-mail address')}
                      </div>
                    </div>
                  </div>
                ) : ''
              }
              {
                this.props.prismic.data.confirmation_marketing_texts.map((text, i) => {
                  return (
                    <div key={i}
                         className={`flex1_0_48 ${i % 2 == (this.props.conf.enable_hotel_delivery == 'true' ? 1 : 0) ? 'marginbottom32' : 'marginleft32'}`}>
                      <div className="margin16_0 text-blue text-bold text-lg">{text.marketing_title}</div>
                      <div className="text-dark-gray text-md"
                           dangerouslySetInnerHTML={this.getMarketingBody(text.marketing_text)}></div>
                    </div>
                  );
                })
              }
              {(this.props.prismic.data.confirmation_marketing_texts.length + (this.props.conf.enable_hotel_delivery == 'true' ? 1 : 0)) % 2 == 1 &&
                  <div className="flex1_0_48 marginleft32"></div>}
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.hotelModalOpen}
          onRequestClose={() => this.toggleHotelModal()}
          style={Object.assign({}, modalStyles, {right: '50%'})}
          contentLabel="Hotel pick-up confirmed"
          setState={state => this.setState(state)}
        >
          <div className="modal">
            <div className="heading">
              <h2 className="text-blue">{t('Hotel pick-up request received')}</h2>
              <i onClick={() => this.toggleHotelModal()} className="icon icon-cross-light-gray"></i>
              <div className="margin32_0">
                {t('We have received your hotel pick-up request. We will let you know once it has been confirmed.')}
              </div>
              <div className="flex-row">
                <div className="flex1 marginright32">
                  <button onClick={() => this.toggleHotelModal()} className="btn btn-gray">{t('close')}</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.modalOpen}
          onRequestClose={() => this.toggleModal()}
          style={modalStyles}
          contentLabel="Driver details"
          setState={state => this.setState(state)}
        >
          <div className="modal">
            <div className="heading">
              <h2 className="text-blue">{t('Driver information')}</h2>
              <i onClick={() => this.toggleModal()} className="icon icon-cross-light-gray"></i>
              <div className="flex-row">
                <div className="input-group flex1 marginright32">
                  <div className="row-item">
                    <div className="label text-bold text-md">{t('First name')}</div>
                    <input
                      defaultValue={this.state.drivers[this.state.driverIndex].firstName && this.state.drivers[this.state.driverIndex].firstName != 'null' ? this.state.drivers[this.state.driverIndex].firstName : null}
                      ref={this.driver.firstName}
                      onBlur={() => {
                        const drivers = this.state.drivers;
                        drivers[this.state.driverIndex].firstName = this.driver.firstName.current.value;
                        this.setState({drivers});
                      }
                      }
                      className="input"
                      placeholder={t('Enter your first name')}/>
                  </div>
                </div>
                <div className="input-group flex1">
                  <div className="row-item">
                    <div className="label text-bold text-md">{t('Last name')}</div>
                    <input
                      defaultValue={this.state.drivers[this.state.driverIndex].lastName && this.state.drivers[this.state.driverIndex].lastName != 'null' ? this.state.drivers[this.state.driverIndex].lastName : null}
                      ref={this.driver.lastName}
                      onBlur={() => {
                        const drivers = this.state.drivers;
                        drivers[this.state.driverIndex].lastName = this.driver.lastName.current.value;
                        this.setState({drivers});
                      }
                      }
                      className="input"
                      placeholder={t('Enter your last name')}/>
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <div className="input-group flex1">
                  <div className="row-item">
                    <div className="label text-bold text-md">{t('Driver\'s license number')}</div>
                    <input
                      defaultValue={this.state.drivers[this.state.driverIndex].licenseNumber && this.state.drivers[this.state.driverIndex].licenseNumber != 'null' ? this.state.drivers[this.state.driverIndex].licenseNumber : null}
                      ref={this.driver.licenseNumber}
                      onBlur={() => {
                        const drivers = this.state.drivers;
                        drivers[this.state.driverIndex].licenseNumber = this.driver.licenseNumber.current.value;
                        this.setState({drivers});
                      }
                      }
                      className="input"
                      placeholder={t('Enter your licence number')}/>
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <div className="input-group flex1">
                  <div className="row-item">
                    <div className="label text-bold text-md">{t('Licence expiry')}</div>
                    <div className="negative-checkbox">
                      <div
                        className={`floatright checkbox checkbox-sm ${this.state.drivers[this.state.driverIndex].noExpiry ? 'checked' : ''}`}
                        onClick={() => {
                          const drivers = this.state.drivers;
                          const errors = {...this.state.errors};
                          drivers[this.state.driverIndex].noExpiry = !drivers[this.state.driverIndex].noExpiry;
                          if (drivers[this.state.driverIndex].noExpiry) {
                            delete errors['expiryMM_' + this.state.driverIndex];
                            delete errors['expiryYY_' + this.state.driverIndex];
                          }

                          drivers[this.state.driverIndex].expiry = drivers[this.state.driverIndex].noExpiry ? 'noExp' : '';
                          this.setState({drivers});
                        }
                        }
                      ></div>
                      <div
                        className="margin0_8 text-sm floatright text-bold text-dark-gray">{t('Does not expire')}</div>
                    </div>
                    <div className="flex-row"
                         style={{opacity: (this.state.drivers[this.state.driverIndex].noExpiry ? 0 : 1)}}>
                      <input
                        defaultValue={this.state.drivers[this.state.driverIndex].expiry && this.state.drivers[this.state.driverIndex].expiry.indexOf('null') == -1 && !this.state.drivers[this.state.driverIndex].noExpiry ? this.state.drivers[this.state.driverIndex].expiry.split('/')[0] : null}
                        ref={this.driver.expiryMM}
                        onBlur={() => {
                          const errors = this.state.errors;
                          if (Number(this.driver.expiryMM.current.value) >= 1 && Number(this.driver.expiryMM.current.value) <= 12) {
                            delete errors['expiryMM_' + this.state.driverIndex];
                            const drivers = this.state.drivers;
                            drivers[this.state.driverIndex].expiry = `${this.driver.expiryMM.current.value}/${this.driver.expiryYY.current.value}`;
                            this.setState({drivers, errors});
                          } else {
                            if (this.driver.expiryMM.current.value && this.driver.expiryMM.current.value.trim()) {
                              errors['expiryMM_' + this.state.driverIndex] = true;
                            } else {
                              errors['expiryMM_' + this.state.driverIndex] = false;
                            }
                            this.setState({errors});
                          }
                        }}
                        className={`input text-center flex1 joined ${this.state.errors['expiryMM_' + this.state.driverIndex] ? 'has-error' : ''}`}
                        placeholder={t('MM')} maxLength="2"/>
                      <input
                        defaultValue={this.state.drivers[this.state.driverIndex].expiry && this.state.drivers[this.state.driverIndex].expiry.indexOf('null') == -1 && !this.state.drivers[this.state.driverIndex].noExpiry ? this.state.drivers[this.state.driverIndex].expiry.split('/')[1] : null}
                        ref={this.driver.expiryYY}
                        onBlur={() => {
                          const errors = this.state.errors;
                          if (2000 + Number(this.driver.expiryYY.current.value) >= moment().year()) {
                            delete errors['expiryYY_' + this.state.driverIndex];
                            const drivers = this.state.drivers;
                            drivers[this.state.driverIndex].expiry = `${this.driver.expiryMM.current.value}/${this.driver.expiryYY.current.value}`;
                            this.setState({drivers});
                          } else {
                            if (this.driver.expiryYY.current.value && this.driver.expiryYY.current.value.trim()) {
                              errors['expiryYY_' + this.state.driverIndex] = true;
                            } else {
                              errors['expiryYY_' + this.state.driverIndex] = false;
                            }
                            this.setState({errors});
                          }
                        }}
                        className={`input text-center flex1 ${this.state.errors['expiryYY_' + this.state.driverIndex] ? 'has-error' : ''}`}
                        placeholder={t('YY')}
                        maxLength="2"/>
                    </div>
                  </div>
                </div>
              </div>
              { /*<div className="flex-row">
                                <div className="flex1">
                                    <div
                                        className={`checkbox checkbox-sm ${this.state.modal.licenceForAYear ? 'checked' : ''}`}
                                        onClick={ () => this.setState({ modal: Object.assign({}, this.state.modal, {licenceForAYear: !this.state.modal.licenceForAYear}) }) }
                                    ></div>
                                    <span className="margin0_8 text-sm text-bold text-dark-gray">{ t("I have had a driver's licence for at least 1 year") }</span>
                                </div>
                            </div>*/}
              <div className="separator"></div>
              <div className="flex-row marginbottom32">
                <div className="flex1 marginright32">{this.state.driverIndex > 0 ?
                  <button disabled={Object.keys(this.state.errors).length > 0} onClick={() => this.clearModal(-1)}
                          className="btn btn-gray">{t('previous')}</button> : ''}</div>
                <div className="flex1">{this.state.driverIndex < this.props.reservation.drivers.length - 1 ?
                  <button disabled={Object.keys(this.state.errors).length > 0} className="btn btn-green"
                          onClick={() => this.clearModal(1)}>{t('next driver')}</button> : null}</div>
              </div>
              <div className="flex-row">
                <div className="flex1 marginright32">
                  <button onClick={() => this.toggleModal()} className="btn btn-gray">{t('skip')}</button>
                </div>
                <div className="flex1">
                  <button className="btn btn-green" disabled={Object.keys(this.state.errors).length > 0}
                          onClick={() => this.addDriver()}>{t('submit')}</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Footer prismic={this.props.prismic}/>
      </div>
    );
  }
};