import types from '../types';
import { defaultLocale } from 'conf/env';
import { gt, setLocale } from '../conf/gettext';

const locale = (state = {locale: defaultLocale }, action) => {
    switch (action.type) {
        case types.SET_LOCALE:
            setLocale(action.locale.rc_language_code);
            return Object.assign({}, state, {locale: action.locale});
        default:
            return state
    }
}

export default locale