import {Dispatch} from 'redux';
import fetch from 'cross-fetch';
import types from '../types';

export function requestDesks() {
  return {
    type: types.REQUEST_DESKS,
    isFetching: true,
    desks: null
  }
}

export function receiveDesks(desks: any) {
  return {
    type: types.RECEIVE_DESKS,
    isFetching: false,
    desks
  }
}


export function fetchDesks(urls, apiKey): (dispatch: Dispatch<any, any>) => Promise<any> {
  return function (dispatch: Dispatch<any, any>) {
    dispatch(requestDesks());

    return fetch(`${urls.reservationsAPI}/desks`, {
        method: 'GET',
        headers: {
          "X-API-KEY": apiKey
        }
      }
    )
      .then(res => res.json())
      .then(json => {
        return dispatch(receiveDesks(json.desks));
      });
  }
}

export function fetchOpeningTimes(urls, supplierCode: string, deskCode: string, date: string): (dispatch: Dispatch<any, any>) => Promise<any> {
  return function (dispatch: Dispatch<any, any>) {
    dispatch({
      type: types.REQUEST_OPENING_TIMES
    });

    return fetch(`${urls.reservationsAPI}/desk-info/${supplierCode}/openings?deskCode=${deskCode}&date=${date}`)
      .then(res => res.json())
      .then(json => {
        return dispatch({
          type: types.RECEIVE_OPENING_TIMES,
          openingTimes: json.openings
        });
      });
  }
}
