import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ReactDOM from 'react-dom';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import Routes from './containers/Routes';
import { fetchConfig } from './actions/config';
import "core-js/stable";

declare global {
  interface History {
    onpushstate: any;
  }
}
window.history.onpushstate = window.history.onpushstate || {};


(function(history) {
  var pushState = history.pushState;
  history.pushState = function(state) {
    if (typeof history.onpushstate == 'function') {
      history.onpushstate({ state: state });
    }
    return pushState.apply(history, arguments);
  };
})(window.history);

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

store.dispatch(fetchConfig());

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById('app')
);
