import React, {Component} from 'react';
import {t} from '../../conf/gettext';
import {getRate} from '../../helpers';

const initialState = {};
type State = Readonly<typeof initialState>

export default class AdditionalOption extends Component<any, State> {
  readonly state: State = initialState;

  getTotalPrice(payOnline?: boolean) {
    let price = this.props.rateInfo.pricing.payNow.totalAmount;
    if (!payOnline) {
      price = this.props.rateInfo.pricing.payOnPickup.totalAmount;
    }
    if (this.props.cart) {
      this.props.cart.forEach(item => {
        price += item.count && item.count > 1 ? item.totalPrice * item.count : item.totalPrice;
      });
    }


    return price.toFixed(2);
  }

  getPriceDiff() {
    return (Math.abs(parseFloat(this.getTotalPrice(false)) - parseFloat(this.getTotalPrice(true)))).toFixed(2);
  }

  paymentTogglingEnabled() {
    return this.props.baseConf.enablePayments !== 'disabled';
  }

  getItem(items, itemGroupType, itemOtaCode) {
    return items[itemGroupType].filter((item) => item.otaCode === itemOtaCode)[0]
  }

  render() {
    if (!this.props.rateInfo) return null;
    const daysString = this.props.rateInfo.rentalDays === 1 ? t('day') : this.props.rateInfo.rentalDays < 5 ? t('days (2-4)') : t('days');
    return (
      <div className="cart" id="cart">
        <div className="heading">
          {t('Price summary')}
          {this.props.rateInfo.promoCode && this.props.rateInfo.promoCode.code &&
              <span className="promo-heading">{t('Prices with promo discount')}</span> || null}
        </div>
        <div className="items">
          <div className="item">
            <div className="name"><strong>{t('Vehicle rental')}</strong>
              <span
                // className="price">{getRate(this.props.rateInfo.pricing.vehicleRentalAmount + (!this.props.payOnline ? this.props.rateInfo.pricing.payOnPickup.payLocallyFee : 0), this.props.currency, this.props.exchange)} {this.props.currency}</span>
                className="price">{getRate(this.props.payOnline ? this.props.rateInfo.pricing.payNow.vehicleRentalAmount : (this.props.rateInfo.pricing.payOnPickup.vehicleRentalAmount + this.props.rateInfo.pricing.payOnPickup.payLocallyFee), this.props.currency, this.props.exchange)} {this.props.currency}</span>
            </div>
            <div className="info">{this.props.rateInfo.rentalDays} {daysString}</div>
          </div>
          {
            this.props.rateInfo.mandatoryItems.fees.filter(item => this.props.items.fees[Number(item.otaCode)]).map((item, i) => (
              <div key={i} className="item">
                <div className="name">
                  <strong>{this.getItem(this.props.items, "fees", item.otaCode).name[this.props.locale.rc_language_code]}</strong>
                  <span
                    className="price">{getRate(item.count && item.count > 1 ? item.totalPrice * item.count : item.totalPrice, this.props.currency, this.props.exchange)} {this.props.currency}</span>
                </div>
                <div className="info">{this.props.rateInfo.rentalDays} {daysString}</div>
              </div>
            ))
          }
          {this.props.cart && this.props.cart.map((item, i) => {
            return (
              <div key={i} className="item">
                <div className="name">
                  <strong>{item.name} {item.count && item.count > 1 ? `x ${item.count}` : ''}</strong>
                  <span
                    className="price">{getRate(item.count && item.count > 1 ? item.totalPrice * item.count : item.totalPrice, this.props.currency, this.props.exchange)} {this.props.currency}</span>
                  <i onClick={() => this.props.removeItem(item, this.props)} className="icon icon-cross-primary"></i></div>
                <div className="info">{this.props.rateInfo.rentalDays} {daysString}</div>
              </div>
            );
          })}
        </div>
        <div className="total">
          <div className="name">{t('Total price')} <span
            className="price">{getRate(this.getTotalPrice(this.props.payOnline), this.props.currency, this.props.exchange)} {this.props.currency}</span>
          </div>
        </div>
        <div className="summary">
          <div className={`summary-item ${!this.paymentTogglingEnabled() ? 'd-none' : ''}`}
               onClick={() => this.props.togglePayOnline(true)}>
            <div
              className={`checkbox checkbox-sm checkbox-rounded ${this.props.payOnline ? 'white-bg checked' : ''}`}
              onClick={() => this.props.togglePayOnline(true)}
            ></div>
            <div
              className={`name ${this.props.payOnline ? '' : 'inactive'}`}
              onClick={() => this.props.togglePayOnline(true)}
            >{t('Pay online')}
              <div className="price-container">
                <span
                  className="price">{getRate(this.getTotalPrice(true), this.props.currency, this.props.exchange)} {this.props.currency}</span>
                {this.getPriceDiff() !== '0.00' ? <span className="discount-price">
                          <span
                            className="discount-price--emphasized">{t('Save')} {getRate(parseFloat(this.getPriceDiff()) + this.props.rateInfo.pricing.payNow.discount, this.props.currency, this.props.exchange)} {this.props.currency}</span>
                        </span> : null}
              </div>
            </div>

          </div>
          <div className="summary-item">
            {this.paymentTogglingEnabled() &&
                <div
                    className={`checkbox checkbox-sm checkbox-rounded ${!this.props.payOnline ? 'white-bg checked' : ''}`}
                    onClick={() => this.props.togglePayOnline(false) || null}
                ></div>}
            <div
              className={`name ${!this.props.payOnline ? '' : 'inactive'}`}
              onClick={() => this.props.togglePayOnline(false)}
            >{t('Pay on pickup')}
              <div className="price-container">
                <span
                  className="price">{(getRate((this.getTotalPrice(false)), this.props.currency, this.props.exchange))} {this.props.currency}</span>
                {this.props.rateInfo.promoCode && this.props.rateInfo.promoCode.code ?
                  <span
                    className="discount-price">{t('Save')} {getRate(this.props.rateInfo.pricing.payNow.discount, this.props.currency, this.props.exchange)} {this.props.currency}</span> : null}
              </div>
            </div>
          </div>

        </div>
        <div className="vat-remark">
          {this.props.prismic.data.vat_remark_for_price || ''}
        </div>
      </div>
    );
  }
}
