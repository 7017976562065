import React, { StatelessComponent } from 'react';
import { t } from '../../../conf/gettext';
import { getRate } from '../../../helpers';

export const Table: StatelessComponent<{}> = (props) => (
    <div className="table">
        <div className="th">
            <div className="th-item index">
            </div>
            {
                props.coverages && props.coverages.columns && props.coverages.columns.map((column, i) => {
                    return (
                        <div key={ i } className="th-item">
                            { column.title }
                            <div className="sub">
                                { column.column_subtitle }
                            </div>
                        </div>
                    );
                })
            }
        </div>
        {
            props.coverages && props.coverages.rows && props.coverages.rows.map((coverage, i) => {
                return (
                    <div key={ i } className="tr">
                        <div className="tr-item index">
                            { coverage.row_title }
                        </div>
                        {
                            Object.keys(coverage).map((key, ind) => {
                                if (key != 'row_title') {
                                    if (props.coverages.columns.length <= ind - 1) {
                                      return null;
                                    }
                                    return (
                                        <div key={ key } className="tr-item">
                                            <i className={`icon icon-${ coverage[key] == 'true' ? 'check' : 'cross' }-circle-${ coverage[key] == 'true' ? 'green' : 'light-gray' }`}></i>
                                        </div>
                                    );
                                }
                                return null;
                            })
                        }
                    </div>
                );
            })
        }
        <div className="tr">
            <div className="tr-item index">
                { t('Excess') }
            </div>
            {props.coverages.columns.map((c, i) => {
                const item = props.items && props.items.find(k => c.coverage_code.split(',').indexOf(k.otaCode) != -1);
                const amount = item && item.excess.excessAmount !== null && item.excess.excessAmount !== undefined ? item.excess.excessAmount : props.item.excess.excessAmount;
                const unit = item && item.excess.currency || props.item.excess.currency;
                return (<div key={i} className="tr-item text-green">
                    { amount + ' ' + unit }
                </div>);
            })}
        </div>
        <div className="tr">
            <div className="tr-item index">
                { t('Deposit') }
            </div>
            {props.coverages.columns.map((c, i) => {
                const item = props.items && props.items.find(k => c.coverage_code.split(',').indexOf(k.otaCode) != -1);
                const amount = item && item.excess.depositAmount !== null && item.excess.depositAmount !== undefined ? item.excess.depositAmount : props.item.excess.depositAmount;
                const unit = item && item.excess.currency || props.item.excess.currency;
                return (<div key={i} className="tr-item text-green">
                    { amount + ' ' + unit }
                </div>);
            })}
        </div>        
        <div className="total">
            <div className="tr">
                <div className="tr-item index text-green">
                    { t('Price') }
                </div>
                <div key={-1} className="tr-item text-blue">
                    { t('Included in price')}
                </div>
                {
                    props.coverages && props.coverages.columns && props.coverages.columns.map((column, i) => {
                        if (column.coverage_code != 'NONE') {
                            const item = props.items && props.items.find(k => column.coverage_code.split(',').indexOf(k.otaCode) != -1);
                            if (item) {
                                return (
                                    <div key={i} className="tr-item text-blue">
                                        { (item.unitPrice * (props.currency == props.exchange.base ? 1 : props.exchange.rates[props.currency])).toFixed(2) } { props.currency }
                                        { ' ' + (item.unit == 'PER_DAY' ? t('per day') : t('per rental')) }
                                    </div>
                                );
                            } else {

                                return (
                                    <div key={i} className="tr-item text-blue">
                                        MISSING
                                    </div>
                                );
                            }
                        }
                        return null;
                    })
                }
            </div>
        </div>
        <div className="actions">
            <div className="tr">
                <div className="tr-item index">
                </div>
                {
                    props.coverages && props.coverages.columns && props.coverages.columns.map((column, i) => {
                        return (
                            <div key={i} onClick={ () => props.setCoverage(column.coverage_code) } className={`tr-item ${ ((column.coverage_code.split(',').indexOf(props.active) != -1) || (!props.active && column.coverage_code == 'NONE')) && 'selected' }`}>
                                {(column.coverage_code.split(',').indexOf(props.active) != -1) || (!props.active && column.coverage_code == 'NONE') ? t('selected') : t('select')}
                            </div>
                        );
                    })
                }
            </div>
        </div>
    </div>
);