import { combineReducers } from 'redux';
import desks from './desks';
import rates from './rates';
import rateInfo from './rateinfo';
import cart from './cart';
import reservation from './reservation';
import prismic from './prismic';
import items from './items';
import terms from './terms';
import drivers from './drivers';
import details from './details';
import search from './search';
import currency from './currency';
import locale from './locale';
import hotel from './hotel';
import countries from './countries';
import config from './config';
import carinfo from './carinfo';

export default combineReducers({
	desks,
	rates,
	rateInfo,
	cart,
	reservation,
	prismic,
	items,
	terms,
	drivers,
	details,
	currency,
	search,
	locale,
	hotel,
	countries,
	config,
	carinfo
});