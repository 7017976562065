import CookieMgr from './cookiemgr.min.js';
declare var sat: any;

const setCookie = redirectId => {
  if (window.Skyscanner && window.Skyscanner.CookieManager) {
    window.Skyscanner.CookieManager.setCookie(
      '_sky_redirectid_',
      redirectId,
      30
    );
  }
};

const getCookie = () => {
  if (window.Skyscanner && window.Skyscanner.CookieManager) {
    return window.Skyscanner.CookieManager.getCookie(
      '_sky_redirectid_'
    );
  }
  return false;
};

const isToPush = reservationNumber => {
  return (
    getCookie() &&
    localStorage.getItem('_ss_booking_pushed') !== reservationNumber
  );
};

const pushSkyScannerTracking = (redirectId, reservation, conf) => {

  if (conf.skyscannerTag) {
    sat('init', conf.skyscannerTag);
    // Send a 'conversion' event
    sat('send', 'conversion', {
      'bookingReference': reservation.reservationNumber,
      'redirectid': redirectId,
      'totalprice.amount': reservation.charges.estimatedTotalAmount,
      'totalprice.currency': reservation.currency,
      'carhire.location.pickupoffice': reservation.pickupDesk.code,
      pickupLocation: reservation.pickupDesk.code,
      'carhire.location.dropoffoffice': reservation.returnDesk.code,
      dropoffLocation: reservation.returnDesk.code,
      'carhire.cartype': reservation.acrissCode,
      carClass: reservation.acrissCode
    });
  }

  localStorage.setItem('_ss_booking_pushed', reservation.reservationNumber);
};

export const addSkyscannerToHead = () => {
    let element = document.createElement('script');
    element.type = 'text/javascript';
    element.innerHTML = skyscannerScriptBody;
    document.getElementsByTagName('head')[0].appendChild(element);
    CookieMgr();
}

const skyscannerScriptBody = `(function(s,k,y,t,a,g)
{s['SkyscannerAnalyticsTag']=t;s[t]=s[t]||function(){
(s[t].buffer=s[t].buffer||
[]).push(arguments)};s[t].u=y;var
l=k.createElement("script");
l.src=y+"/tag.js";l.async=1;var
h=k.getElementsByTagName("head")[0];h.appendChild(l); })
(window, document, 'https://analytics.skyscanner.net',
'sat');`;

export default {
  setCookie: setCookie,
  getCookie: getCookie,
  pushTracking: pushSkyScannerTracking,
  isToPush: isToPush,
  addSkyscannerToHead: addSkyscannerToHead
};
