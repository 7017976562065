import types from '../types';

const hotel = (state = { hotel: { isFetching: false, hotelBooked: false }}, action) => {
    switch (action.type) {
        case types.REQUEST_HOTEL:
            return Object.assign({}, state, action);
        case types.RECEIVE_HOTEL:
            return Object.assign({}, state, action);
        default:
            return state
    }
}

export default hotel