import React, { Component } from 'react';
import * as moment from 'moment';
import { DayPickerRangeController } from 'react-dates';
import { t } from '../../conf/gettext';
import { countries, months } from '../../conf/values';

const initialState = {
    searchOpen: false,
    pickUpDatePickerOpen: false,
    dropOffDatePickerOpen: false,
    focusedInput: 'startDate',
    country: '',
    promoCode: ''
};
type State = Readonly<typeof initialState>

export default class SearchForm extends Component<any, State> {
    readonly state: State = initialState;

    constructor(props) {
        super(props);

        this.pickUpDesk = React.createRef();
        this.dropOffDesk = React.createRef();
        this.dropOffTime = React.createRef();
        this.pickUpTime = React.createRef();
        this.age = React.createRef();
        this.promoCode = React.createRef();
        const defaultAge = this.props.conf.driver_age_ranges.find(a => a.default === 'true') || this.props.conf.driver_age_ranges[0];
        this.state = {
          ...initialState,
          pickUpDate: moment(this.props.state.pickUpDate),
          dropOffDate: moment(this.props.state.returnDate),
          pickUpTime: this.props.state.pickUpTime,
          dropOffTime: this.props.state.dropOffTime,
          country: this.props.search.country || this.props.state.country,
          age: this.props.search.age || this.props.state.age || defaultAge.driver_age_range_min,
          promoCode: this.props.search.promoCode || this.props.state.promoCode
        };
    }

    search() {
        const pickUpDate = moment(this.state.pickUpDate);
        pickUpDate.minutes(this.pickUpTime.current.value.split(':')[1]);
        pickUpDate.hours(this.pickUpTime.current.value.split(':')[0]);

        const dropOffDate = moment(this.state.dropOffDate);
        dropOffDate.minutes(this.dropOffTime.current.value.split(':')[1]);
        dropOffDate.hours(this.dropOffTime.current.value.split(':')[0]);
        const puDateIsoString = pickUpDate && pickUpDate.toISOString(true);
        const doDateIsoString = dropOffDate && dropOffDate.toISOString(true);
        const defaultAge = this.props.conf.driver_age_ranges.find(a => a.default === 'true') || this.props.conf.driver_age_ranges[0];
        return this.props.fetchRates({
            currency: this.props.currency,
            driverAge: this.age.current.value || defaultAge.driver_age_range_min,
            pickupDate: puDateIsoString && puDateIsoString.split('.')[0],
            pickupDeskCode: this.pickUpDesk.current.value,
            returnDate: doDateIsoString && doDateIsoString.split('.')[0],
            returnDeskCode: this.dropOffDesk.current.value == '0' ? this.pickUpDesk.current.value : this.dropOffDesk.current.value,
            sourceCountry: this.state.country,
            promoCode: this.state.promoCode,
            apiKey: this.props.search.apiKey,
            partnerCode: this.props.search.partnerCode
        });
    }

    createTimeOptions() {
        let timeOptions = [];

        for (let i = 0; i <24; i++) {
            for (let k = 0; k<60; k+=15) {
                const time = `${ (i < 10 ? '0' : '') + i }:${ (k < 10 ? '0' : '') + k }`;
                timeOptions.push(<option key={ time } value={ time }>{ time }</option>)
            }
        }

        return timeOptions;
    }

    getDateString(date) {
        return `${date.getDate()} ${ months[date.month()]} ${date.getFullYear()}`;
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.state.country && nextProps.state.country ||
          this.state.country !== nextProps.state.country) {
          this.setState({country: nextProps.state.country});
        }
        if (nextProps.search.country && this.state.country !== nextProps.search.country) {
          this.setState({country: nextProps.search.country});
        }
        if (nextProps.search.promoCode && this.state.promoCode !== nextProps.search.promoCode) {
            this.setState({ promoCode: nextProps.search.promoCode });
        }
    }

    sanitizeAge() {
        this.age.current.value = this.age.current.value.replace(/[^0-9]/g, '');
    }

    render() {
        const timeOptions = this.createTimeOptions();
        const ageOptions = this.props.conf.driver_age_ranges
        .map(r =>
            <option
                key={r.driver_age_range_min}
                value={r.driver_age_range_min}
            >{r.driver_age_range_max ? r.driver_age_range_min + '-' + r.driver_age_range_max + ' ' + t('years') : r.driver_age_range_min + '+ ' + t('years')}</option>);
        const defaultAge = this.props.conf.driver_age_ranges.find(a => a.default === 'true') || this.props.conf.driver_age_ranges[0];
        return (
            <div className="search full">
                <div className="input-group margin0">
                    <div className="row-item">
                        <div className="label">{t('Pickup location')}</div>
                        <select ref={ this.pickUpDesk } onChange={ () => { this.props.setSearch({pickUpDesk: this.pickUpDesk.current.value}) } } className="input" defaultValue={ this.props.search.pickUpDesk || this.props.state.pickUpDesk }>
                            {this.props.desks && Object.keys(this.props.desks).map(key => this.props.desks[key]).map((desk) => {
                                return <option key={desk.code} value={desk.code}>{desk.name[this.props.locale.rc_language_code]}</option>;
                            })}
                        </select>
                    </div>
                </div>
                <div className="input-group margin0">
                    <div className="row-item">
                        <div className="flex-row">
                            <input
                                onClick={ (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.setState({ pickUpDatePickerOpen: !this.state.pickUpDatePickerOpen, dropOffDatePickerOpen: false });
                                 } }
                                value={ this.state.pickUpDate && moment(this.state.pickUpDate).format('DD.MM.YYYY') || '' }
                                className="flex2 input joined select"
                                name="start date"
                                readOnly />
                            <select ref={ this.pickUpTime } onChange={ () => { this.props.setSearch({ pickUpTime: this.pickUpTime.current.value }) } } className="flex1 input" defaultValue={ this.props.search.pickUpTime || this.props.state.pickUpTime }>
                                { timeOptions }
                            </select>
                            {this.state.pickUpDatePickerOpen ?
                                <DayPickerRangeController
                                    renderMonthElement={ (el) => { return ( <span>{`${ t(moment(el.month).format('MMMM').toLowerCase())} ${ moment(el.month).format('YYYY') }`}</span>) }}
                                    startDate={this.state.pickUpDate ? moment(this.state.pickUpDate) : null}
                                    endDate={this.state.dropOffDate ? moment( this.state.dropOffDate ) : null}
                                    onDatesChange={({startDate, endDate}) => {
                                      this.setState({
                                        pickUpDate: startDate,
                                        dropOffDate: endDate
                                      });
                                    }}
                                    focusedInput={ this.state.focusedInput }
                                    numberOfMonths={1}
                                    onFocusChange={(focusedInput) => { this.setState({ focusedInput: focusedInput || 'startDate', pickUpDatePickerOpen: focusedInput == null ? false : true }) }}
                                    onOutsideClick={ () => this.setState({ pickUpDatePickerOpen: false }) }
                                    isOutsideRange={ (date) => moment().diff(moment(date), 'days') > 0 }
                                    minimumNights={0}
                                    withPortal={true}
                                  /> : ''}
                        </div>
                    </div>
                </div>
                <div className="input-group margin0">
                    <div className="row-item">
                        <div className="label">{t('Dropoff location')}</div>
                        <select ref={ this.dropOffDesk } onChange={ () => { this.props.setSearch({dropOffDesk: this.dropOffDesk.current.value}) } } className="input" defaultValue={ this.props.search.dropOffDesk || this.props.state.returnDesk }>
                            <option value={0} key={-1}>{ t('Same as pickup') }</option>
                            {this.props.desks && Object.keys(this.props.desks).map(key => this.props.desks[key]).map((desk) => {
                                return <option key={desk.code} value={desk.code}>{desk.name[this.props.locale.rc_language_code]}</option>;
                            })}
                        </select>
                    </div>
                </div>
                <div className="input-group margin0">
                    <div className="row-item">
                        <div className="flex-row">
                            <input
                                onClick={ (e) => {
                                    e.preventDefault();
                                    this.setState({ dropOffDatePickerOpen: !this.state.dropOffDatePickerOpen, pickUpDatePickerOpen: false });
                                 } }
                                value={ this.state.dropOffDate && moment(this.state.dropOffDate).format('DD.MM.YYYY') || '' }
                                className="flex2 input joined select"
                                onKeyDown={ e => e.preventDefault() }
                                name="end date"
                                readOnly />
                            <select ref={ this.dropOffTime } onChange={ () => { this.props.setSearch({ dropOffTime: this.dropOffTime.current.value }) } } className="flex1 input" defaultValue={ this.props.search.dropOffTime || this.props.state.dropOffTime }>
                                { timeOptions }
                            </select>
                            {this.state.dropOffDatePickerOpen ?
                                <DayPickerRangeController
                                    renderMonthElement={ (el) => { return ( <span>{`${ t(moment(el.month).format('MMMM').toLowerCase())} ${ moment(el.month).format('YYYY') }`}</span>) }}
                                    startDate={this.state.pickUpDate ? moment(this.state.pickUpDate) : null}
                                    endDate={this.state.dropOffDate ? moment( this.state.dropOffDate ) : null}
                                    onDatesChange={(date) => {
                                      this.setState({
                                        dropOffDate: date.endDate || date.startDate,
                                        dropOffDatePickerOpen: false
                                      });
                                    }}
                                    focusedInput={ this.state.focusedInput }
                                    numberOfMonths={1}
                                    onFocusChange={(focusedInput) => { this.setState({ focusedInput: focusedInput || 'endDate', dropOffDatePickerOpen: focusedInput == null ? false : true }) }}
                                    onOutsideClick={ () => this.setState({ dropOffDatePickerOpen: false }) }
                                    isOutsideRange={ (date) => moment().diff(moment(date), 'days') > 0 }
                                    withPortal={true}
                                    minimumNights={0} /> : ''}
                        </div>
                    </div>
                </div>
                <div className="input-group">
                    <div className="row-item fixed">
                        <div className="label">{t('I am from')}</div>
                        <select style={{maxWidth: '100%'}} onChange={ (c) => this.props.setSearch({ country: c.target.value })} className="input" value={this.state.country}>
                            {
                                this.props.countries.map((country, i) => { return <option key={i} value={ country.code }>{ country.name[this.props.locale.rc_language_code] }</option> })
                            }
                        </select>
                    </div>
                    <div className="row-item">
                        <div className="label">{t("Driver's age")}</div>
                        <select
                            defaultValue={defaultAge.driver_age_range_min}
                            ref={ this.age }
                            className="input"
                            onChange={ () => this.sanitizeAge() && this.props.setSearch({age: this.age.current.value}) }>
                            {ageOptions}
                        </select>
                    </div>
                </div>
                {this.props.baseConf.enablePromoCode ? <div className="input-group">
                    <div className="row-item fixed">
                        <div className="label">{'Promo code'}</div>
                        <input className="input" value={this.state.promoCode} onChange={(c) => this.props.setSearch({ promoCode: c.target.value })} />
                    </div>
                </div> : null}
                <div className="flex-row">
                    { this.props.minimalSearch ? '' : <button className="flex1 btn btn-transparent" onClick={ () => this.props.toggle() }>{t('Cancel')}</button>}
                    <button className="flex1 btn btn-search-form" onClick={ () => this.search() }>{t('Search')}</button>
                </div>
            </div>
        );
    }
}
