import {
    calculateReservationPriceWithMethod,
    calculateReservationTotalPrice,
    createGoogleAnalyticsItemsListFromCart
} from './index';

export const handleViewItemListEvent = (rates) => {
    (window as any).dataLayer.push({ecommerce: null});
    (window as any).dataLayer.push({
        event: 'view_item_list',
        ecommerce: {
            item_list_name: "Search results",
            items: [
                rates.map((r) => ({
                    item_id: r.vehicleId.toString(),
                    item_name: r.model,
                    item_brand: r.model,
                    item_category: "Car",
                    price: r.pricing.payNow.vehicleRentalAmount,
                    days: r.rentalDays,
                    quantity: 1
                }))
            ]
        }
    })
}

export const handleAddToCartEvent = (showSelectedRate, rate, currency, includeRatePrice: boolean, cart?) => {

    const reservationTotal = Number(calculateReservationTotalPrice(rate.pricing ?
        rate.pricing.payNow.totalAmount :  rate.pricing.payNow.vehicleRentalAmount, cart, includeRatePrice).toFixed(2));
    const selectedRate = showSelectedRate ? {
        item_id: rate.vehicleId.toString(),
        item_name: rate.model,
        item_brand: rate.model,
        item_category: "Car",
        days: rate.rentalDays,
        price: Number((rate.charges ?
            rate.pricing.payNow.totalAmount :  rate.pricing.payNow.vehicleRentalAmount).toFixed(2)),
        quantity: 1
    } : undefined;
    const eventItems = createGoogleAnalyticsItemsListFromCart(cart, rate.rentalDays);
    if (selectedRate) {
        eventItems.push(selectedRate);
    }

    (window as any).dataLayer.push({ecommerce: null});
    (window as any).dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
            currency: currency,
            value: reservationTotal,
            items: eventItems
        }
    })

}

export const handleRemoveFromCartEvent = (items, currency, rentalDays) => {
    const reservationTotal = Number(calculateReservationTotalPrice(0, items, false).toFixed(2));

    (window as any).dataLayer.push({ecommerce: null});
    (window as any).dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
            currency: currency,
            value: reservationTotal,
            items: [
                ...createGoogleAnalyticsItemsListFromCart(items, rentalDays)
            ]
        }
    })
}

export const handleBeginCheckoutEvent = (rate, currency, cart?) => {
    (window as any).dataLayer.push({ecommerce: null});
    (window as any).dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
            currency: currency,
            value: Number(calculateReservationTotalPrice(rate.pricing.payNow.totalAmount, cart, true).toFixed(2)),
            coupon: rate.promoCode ? rate.promoCode.code.toString() : "",
            items: [
                {
                    item_id: rate.vehicleId.toString(),
                    item_name: rate.model,
                    item_brand: rate.model,
                    days: rate.rentalDays,
                    item_category: "Car",
                    price: Number(rate.pricing.payNow.totalAmount.toFixed(2)),
                    quantity: 1
                },
                ...createGoogleAnalyticsItemsListFromCart(cart, rate.rentalDays)
            ]
        }
    })
}

export const handleCheckoutStepProtection = (rate, currency, payOnline, cart?) => {
    (window as any).dataLayer.push({ecommerce: null});
    (window as any).dataLayer.push({
        event: 'checkout_step_protection',
        ecommerce: {
            currency: currency,
            value: Number(calculateReservationPriceWithMethod(
                calculateReservationTotalPrice(rate.pricing.payNow.totalAmount, cart, true),
                payOnline, rate.pricing.payOnPickup.payLocallyFee
            ).toFixed(2)),
            coupon: rate.promoCode ? rate.promoCode.code.toString() : "",
            payment_type: payOnline ? "Pay now & save" : "Pay on pickup",
            items: [
                {
                    item_id: rate.vehicleId.toString(),
                    item_name: rate.model,
                    item_brand: rate.model,
                    days: rate.rentalDays,
                    item_category: "Car",
                    price: Number(rate.pricing.payNow.totalAmount.toFixed(2)),
                    quantity: 1
                },
                ...createGoogleAnalyticsItemsListFromCart(cart, rate.rentalDays)
            ]
        }
    })
}

export const handleCheckoutStepDetailsLoadEvent = (rate, currency, payOnline, cart?) => {
    (window as any).dataLayer.push({ecommerce: null});
    (window as any).dataLayer.push({
        event: 'checkout_step_details',
        ecommerce: {
            currency: currency,
            value: Number(calculateReservationPriceWithMethod(
                calculateReservationTotalPrice(rate.pricing.payNow.totalAmount, cart, true),
                payOnline, rate.pricing.payOnPickup.payLocallyFee).toFixed(2)),
            coupon: rate.promoCode? rate.promoCode.code.toString() : "",
            payment_type: payOnline ? "Pay now & save" : "Pay on pickup",
            items: [
                {
                    item_id: rate.vehicleId.toString(),
                    item_name: rate.model,
                    item_brand: rate.model,
                    item_category: "Car",
                    days: rate.rentalDays,
                    price: Number(rate.pricing.payNow.totalAmount.toFixed(2)),
                    quantity: 1
                },
                ...createGoogleAnalyticsItemsListFromCart(cart, rate.rentalDays)
            ]
        }
    })
}

export const createBookingConfirmRateInfo = (rate, currency, payOnline, cart) => {
    return {
        currency: currency,
        value: 0,
        transaction_id: "",
        coupon: rate.promoCode ? rate.promoCode.code.toString() : "",
        payment_type: payOnline ? "Pay now & save" : "Pay on pickup",
        items: [
            {
                item_id: rate.vehicleId.toString(),
                item_name: rate.model,
                item_brand: rate.model,
                days: rate.rentalDays,
                item_category: "Car",
                price: Number(rate.pricing.payNow.totalAmount.toFixed(2)),
                quantity: 1
            },
            ...createGoogleAnalyticsItemsListFromCart(cart, rate.rentalDays)
        ]
    }
}
