import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import Index from '../../routes';
import {fetchCountries} from '../../actions/countries';
import {fetchExchange} from '../../actions/currency';
import {fetchDesks} from '../../actions/desks';
import {fetchPrismic} from '../../actions/prismic';
import {fetchItems} from '../../actions/items';
import {fetchCarInfo} from '../../actions/carinfo';
import {getPrismic} from "../../helpers";
import {togglePayOnline} from '../../actions/cart';

const mapStateToProps = state => {
  return {
    desks: state.desks.desks,
    items: state.items.items,
    prismic: getPrismic(state.prismic.prismic, state.locale.locale),
    conf: state.prismic.prismic && state.prismic.prismic.results.find(r => r.type == 'configuration').data,
    baseConf: state.config.config
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any, any>) => {
  return {
    fetchCountries: (urls, apiKey) => {
      dispatch(fetchCountries(urls, apiKey))
    },
    fetchExchange: (urls) => {
      dispatch(fetchExchange(urls))
    },
    fetchDesks: (urls, apiKey) => {
      dispatch(fetchDesks(urls, apiKey))
    },
    fetchPrismic: (conf) => {
      dispatch(fetchPrismic(conf))
    },
    fetchItems: (urls, apiKey) => {
      dispatch(fetchItems(urls, apiKey))
    },
    fetchCarInfo: (urls, apiKey) => {
      dispatch(fetchCarInfo(urls, apiKey))
    },
    togglePayOnline: (val) => dispatch(togglePayOnline(val))
  }
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(Index);