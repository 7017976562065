import {Dispatch} from 'redux';
import fetch from 'cross-fetch';
import types from '../types';

export function requestItems() {
  return {
    type: types.REQUEST_ITEMS,
    isFetching: true,
    items: null
  }
}

export function receiveItems(items: any) {
  return {
    type: types.RECEIVE_ITEMS,
    isFetching: false,
    items
  }
}

export function fetchItems(urls, apiKey: string): (dispatch: Dispatch<any, any>) => Promise<void> {
  return function (dispatch: Dispatch<any, any>) {
    dispatch(requestItems());

    return fetch(`${urls.reservationsAPI}/items`,
      {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'X-API-KEY': apiKey
      }
    }
    )
      .then(res => res.json())
      .then(json => {
        dispatch(receiveItems(json));
      });
  }
}
