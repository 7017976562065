import types from '../types';

const items = (state = [], action) => {
    switch (action.type) {
        case types.REQUEST_ITEMS:
            return Object.assign({}, state,
                {
                    items: action.items
                }
            );
        case types.RECEIVE_ITEMS:
            return Object.assign({}, state,
                {
                    items: action.items
                }
            );
        default:
            return state
    }
}

export default items