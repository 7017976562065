import AWS from 'aws-sdk';

export const initAWS = conf => {
  const awsCreds = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: conf.identityPoolId
  });
  AWS.config = new AWS.Config({
    credentials: awsCreds,
    region: conf.region
  });
  const cloudwatchlogs = new AWS.CloudWatchLogs({ apiVersion: '2014-03-28' });

  window.onerror = (message, source, lineno, colno, error) => {
    console.log('err');
    getLatestSequenceToken(conf, cloudwatchlogs, latestStream =>
      sendErrorToLogs(error || message, conf, latestStream, cloudwatchlogs)
    );
  };
};

const getLatestSequenceToken = (conf, cloudwatchlogs, setLatestStream) => {
  const params = {
    logGroupName: conf.groupName,
    descending: true,
    logStreamNamePrefix: conf.streamPrefix
  };
  cloudwatchlogs.describeLogStreams(params, function(err, data) {
    if (err) console.log(err, err.stack);
    // an error occurred
    else {
      const streams = data.logStreams;
      streams.sort((a, b) => b.creationTime - a.creationTime);
      const latestLogStream = streams[0]; // latest
      setLatestStream(latestLogStream);
    } // successful response
  });
};

const sendErrorToLogs = (error, conf, latestLogStream, cloudwatchlogs) => {
  const params = {
    logEvents: [
      {
        message: 'APP_ERROR: ' + (error && error.stack ? error.stack : error),
        timestamp: new Date().getTime()
      }
    ],
    logGroupName: conf.groupName,
    logStreamName: latestLogStream.logStreamName,
    sequenceToken: latestLogStream.uploadSequenceToken
  };
  cloudwatchlogs.putLogEvents(params, function(err, data) {
    if (err) console.log(err, err.stack);
    // an error occurred
    else {
      latestLogStream.uploadSequenceToken = data.nextSequenceToken;
    }
  });
};
