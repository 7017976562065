import React from 'react';
import { getRate } from 'helpers';

const PromoCodeInfo = ({ estimatedTotalAmount, discount, currency, exchange }) => {
  return (
    <div className="promo-code-info">
      <span className="strikethrough">
        { (parseFloat(getRate(estimatedTotalAmount, currency, exchange)) + parseFloat(getRate(discount, currency, exchange))).toFixed(2) } { currency }
      </span> you save <strong style={{ fontWeight: 600 }}>{getRate(discount, currency, exchange)} { currency }</strong>
    </div>
  )
};

export default PromoCodeInfo;
