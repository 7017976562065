import types from '../types';

const reservation = (state: any = {}, action) => {
  switch (action.type) {
    case types.REQUEST_RESERVATION:
      return Object.assign({}, state, action);
    case types.RECEIVE_RESERVATION:
      return Object.assign({}, state, action);
    case types.CONFIRM_RESERVATION:
      return Object.assign({}, state, action);
    case types.RECEIVE_DRIVER:
      return Object.assign({}, state,
        {
          reservation: {...state.reservation, ...{drivers: action.driver.drivers}}
        }
      );
    default:
      return state
  }
}
export default reservation