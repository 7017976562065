import types from '../types';

export function removeItem(item, componentProps) {
    return {
        type: types.REMOVE_ITEM,
        item,
        componentProps
    }
}

export function clearCart() {
    return {
        type: types.CLEAR_CART,
    }
}


export function addItem(item, componentProps) {
    return {
        type: types.ADD_ITEM,
        item,
        componentProps
    }
}

export function restoreCart() {
    return {
        type: types.RESTORE_CART
    }
}

export const togglePayOnline = (value: boolean) => {
  return {
    type: types.TOGGLE_PAY_ONLINE,
    payload: value
  }
};
