import types from '../types';

const config = (state = {}, action) => {
    switch (action.type) {
        case types.REQUEST_CONFIG:
            return Object.assign({}, state,
                {
                    config: action.config
                }
            );
        case types.RECEIVE_CONFIG:
            return Object.assign({}, state,
                {
                    config: action.config
                }
            );
        default:
            return state
    }
}

export default config