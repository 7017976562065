import React, {Component} from 'react';
import {t} from '../conf/gettext';
import {NavBar} from '../components/NavBar';
import {Table} from '../components/Table';
import {AdditionalOption} from '../components/AdditionalOption';
import Cart from '../containers/Cart';
import {Link, RouteComponentProps} from 'react-router-dom';
import {getCurrencyOrDefault, getLocaleOrDefault} from '../helpers';
import queryString from 'query-string';
import Footer from '../components/Footer';
import {RichText} from 'prismic-reactjs';
import {handleCheckoutStepProtection} from '../helpers/analyticsEvents';

const initialState = {
  disableCoverages: [],
  matrixCoverages: []
};
type State = Readonly<typeof initialState>

export default class Insurance extends Component<RouteComponentProps<any>, State> {
  readonly state: State = initialState;

  componentDidMount() {
    if (!this.props.rateInfo) {
      if (this.props.match.params.hash) {
        this.props.history.push(`/${this.props.locale.slug}/vehicle${window.location.search}`);
      } else {
        this.props.history.push(`/${this.props.locale.slug}${window.location.search}`);
      }
    }

    if (this.props.match.params.locale) {
      this.props.setLocale(getLocaleOrDefault(this.props.conf.enabled_languages, this.props.match.params.locale));
    }

    const qs = queryString.parse(this.props.location.search);
    if (qs['currency']) {
      this.props.setCurrency(getCurrencyOrDefault(this.props.conf.supported_currencies, qs['currency']));
    }

    const matrixCoverages = [];
    [1, 2, 3, 4].forEach(i => {
      if (this.props.conf[`matrix_column_${i}`]) {
        this.props.conf[`matrix_column_${i}`].split(',').forEach(k => matrixCoverages.push(k));
      }
    });

    this.setState({matrixCoverages});
    document.title = t('Protection') + ' | ' + this.props.prismic.data.meta_title;

    if (this.props.rateInfo) {
      handleCheckoutStepProtection(this.props.rateInfo, this.props.currency, this.props.payOnline, this.props.cart);
    }
  }

  componentWillUnmount() {
    document.title = this.props.prismic.data.meta_title;
  }

  setCoverage(code) {
    const cov = this.props.prismic.data.coverage_matrix_columns.find((c, i) => this.props.conf[`matrix_column_${i + 1}`] && this.props.conf[`matrix_column_${i + 1}`].indexOf(code) != -1);

    if (this.props.cart) {
      let item = this.props.cart.find(item => this.state.matrixCoverages.indexOf(item.otaCode) != -1);
      if (item) this.props.removeItem(Object.assign({}, item, {name: cov.title, groupType:'coverages'}), this.props);
    }

    if (code != 'NONE') {
      let item = this.props.rateInfo.optionalItems.coverages.find(coverage => code.split(',').indexOf(coverage.otaCode) != -1);
      if (item) this.props.addItem(Object.assign({}, item, {name: cov.title, groupType:'coverages'}), this.props);
    }

    const disableCoverages = this.props.conf.coverages_rules.find(i => code.split(',').indexOf(i.if_coverage_is) != -1);
    if (disableCoverages) {
      this.setState({disableCoverages: disableCoverages.then_disable.split(',')});

      if (this.props.cart && this.props.cart.length) {
        this.props.cart.forEach(item => {
          if (disableCoverages.then_disable.split(',').indexOf(item.otaCode) != -1) {
            this.props.removeItem(item, this.props);
          }
        });
      }
    } else {
      this.setState({disableCoverages: []});
    }
  }

  getTableItems() {
    const items = Object.keys(this.props.rateInfo.optionalItems['coverages']).map(key => this.props.rateInfo.optionalItems['coverages'][key]).filter(item => {
      return (this.state.matrixCoverages.indexOf(item.otaCode) != -1);
    }).map(item => {
      const column = this.props.prismic.data.coverage_matrix_columns.find((c, i) => this.props.conf[`matrix_column_${i + 1}`] && this.props.conf[`matrix_column_${i + 1}`].split(',').indexOf(item.otaCode) != -1);
      const codeOfColumn = this.props.conf[`matrix_column_${this.props.prismic.data.coverage_matrix_columns.indexOf(column) + 1}`];
      const code = Object.keys(this.props.items['coverages']).find(i => codeOfColumn && codeOfColumn.split(',').indexOf(i) != -1);
      if (code) {
        return Object.assign({}, item, {
          description: this.getItem(this.props.items, 'coverages', item.otaCode).description[this.props.locale.language_code_in_prismic],
          name: this.getItem(this.props.items, 'coverages', item.otaCode).name[this.props.locale.language_code_in_prismic],
          groupType: 'coverages'
        });
      } else {
        return {};
      }
    });

    return items;
  }

  getItem(items, itemGroupType, itemOtaCode) {
    return items[itemGroupType].filter((item) => item.otaCode === itemOtaCode)[0];
  }

  render() {
    if (!this.props.rateInfo || !(this.props.prismic && this.props.prismic.data)) return null;

    const columns = this.props.prismic.data.coverage_matrix_columns;
    columns.forEach((column, i) => {
      column.coverage_code = this.props.conf[`matrix_column_${i + 1}`];
    });

    const coverages = {
      columns,
      rows: this.props.prismic.data.coverages_matrix_rows
    };

    const activeCoverage = this.props.cart && this.props.cart.find(item => this.state.matrixCoverages.indexOf(item.otaCode) != -1);
    if (this.props.conf.coverage_rules) {
      this.props.conf.coverage_rules.forEach(rule => {
        if (this.state.matrixCoverages.indexOf(rule.if_coverage_is) != -1) {
          this.setState({matrixCoverages: [...this.state.matrixCoverages, rule.then_disable.split(',')]});
        }
      });
    }

    return (
      <div>
        <NavBar prismic={this.props.prismic} setCurrency={this.props.setCurrency} setLocale={this.props.setLocale}
                currency={this.props.currency} exchange={this.props.exchange} locale={this.props.locale}
                conf={this.props.conf} prevRoute={t('vehicle')}
                prevUrl={`/${this.props.locale.slug}/vehicle${window.location.search}`} history={this.props.history}
                match={this.props.match}/>
        <div className="disclaimer">
          <h3>{t('Protection options')}</h3>
          <p>{this.props.prismic.data.coverages_note}</p>
        </div>
        <Table
          coverages={coverages}
          items={this.getTableItems()}
          active={activeCoverage && activeCoverage.otaCode}
          setCoverage={(code) => this.setCoverage(code)}
          currency={this.props.currency}
          exchange={this.props.exchange}
          item={{excess: this.props.rateInfo.excess}}/>
        {this.props.rateInfo.optionalItems.coverages.length ? <>
          <div className="separator"></div>
          <div className="margin0_8">
            {this.props.rateInfo.optionalItems.coverages.map((item, i) => {
              if (this.props.items && this.props.items['coverages'] && this.getItem(this.props.items, 'coverages', item.otaCode) && this.state.matrixCoverages.indexOf(item.otaCode) == -1 && this.state.disableCoverages.indexOf(item.otaCode) == -1) {
                return (
                  <div key={i} className="flex1_0_49 margin0_4">
                    <AdditionalOption
                      item={Object.assign({}, item,
                        {
                          description: this.getItem(this.props.items, 'coverages', item.otaCode).description[this.props.locale.rc_language_code],
                          name: this.getItem(this.props.items, 'coverages', item.otaCode).name[this.props.locale.rc_language_code],
                          groupType: "coverages"
                        })
                      }
                      addItem={(itm) => this.props.addItem(itm, this.props)}
                      removeItem={(itm) => this.props.removeItem(itm, this.props)}
                      cart={this.props.cart}
                      currency={this.props.currency}
                      exchange={this.props.exchange}/>
                  </div>
                );
              }
            })}
          </div>
        </> : ''}
        <div className="separator"></div>
        <div className="margin0_8">
          <Cart/>
          <div className="margin8_0 flex-row align-center">
            <div className={`checkbox checkbox-sm ${this.props.terms ? 'checked' : ''}`}
                 onClick={() => this.props.toggleTerms(this.props.terms)}></div>
            <RichText render={this.props.prismic.data.terms_conditions_accept_text}/>
          </div>
          <div
            className="fine-print text-red margin8_0">{this.props.payOnline && this.props.prismic.data.coverages_hint_pay_online || this.props.prismic.data.coverages_hint}</div>
          <Link to={`/${this.props.locale.slug}/reservation${window.location.search}`}>
            <button className="btn btn-green margin8_0">{t('continue')}</button>
          </Link>
        </div>
        <Footer prismic={this.props.prismic}/>
      </div>
    );
  }
};
