import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withRouter } from 'react-router';
import { removeItem, addItem } from '../../../actions/cart';
import InsuranceView from '../../../views/Desktop/Insurance';
import { toggleTerms } from '../../../actions/terms';
import { setCurrency } from '../../../actions/currency';
import { setLocale } from '../../../actions/locale';
import { getPrismic } from '../../../helpers';
​
const mapStateToProps = state => {
    return {
        rateInfo: state.rateInfo.rateinfo,
        cart: state.cart.items,
        terms: state.terms.terms || false,
        prismic: getPrismic(state.prismic.prismic, state.locale.locale),
        items: state.items.items,
        currency: state.currency.currency,
        exchange: state.currency.exchange,
        locale: state.locale.locale,
        conf: state.prismic.prismic && state.prismic.prismic.results.find(r => r.type == 'configuration').data,
        baseConf: state.config.config,
        payOnline: state.cart.payOnline
    }
}
​
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        removeItem: (item, props) => {
            dispatch(removeItem(item, props));
        },
        addItem: (item, compProps) => {
            dispatch(addItem(item, compProps));
        },
        toggleTerms: (terms) => {
            dispatch(toggleTerms(terms))
        },
        setCurrency: (currency) => {
            dispatch(setCurrency(currency))
        },
        setLocale: (locale) => {
            return dispatch(setLocale(locale))
        },
    }
}
​
export default withRouter(connect<{}, {}, any>(
    mapStateToProps,
    mapDispatchToProps
)(InsuranceView));
