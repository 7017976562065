import types from '../types';

const search = (state = [], action) => {
    switch (action.type) {
        case types.SET_SEARCH:
            return Object.assign({}, state, action.obj);
        default:
            return state
    }
}

export default search