import React from 'react';

const Footer = props => {
  const footerText = props.prismic.data.footer_text as Array<any>;
  let footerParagraphs;
  if (footerText && footerText.length) {
    footerParagraphs = footerText.map((item, index) => {
      let text = item.text;
      if (item.spans && item.spans.length) {
        for (const span of item.spans) {
          const textStart = text.slice(0, span.start);
          const textEnd = text.slice(span.end, text.length);
          const textInSpan = text.slice(span.start, span.end);
          if (span.type === 'hyperlink') {
            text = <span>{textStart}<a href={span.data.url} target={span.data.target}>{textInSpan}</a>{textEnd}</span>;
          } else {
            text = <span>{textStart}{textInSpan}{textEnd}</span>;
          }
        }
      }
      return (
        <p key={'i' + index}>{text}</p>
      )
    })
  }
  return (
    <div className="footer">
      <div className="container">
        <hr />
        <div className="logo" />
        <div className="paragraphs">
          {footerParagraphs}
        </div>
      </div>
    </div>
  );
};

export default Footer;