import React, { StatelessComponent } from 'react';
import { t } from '../../conf/gettext';
import { getRate } from '../../helpers';

export const Table: StatelessComponent<{}> = (props) => (
    <div>
        {
            props.coverages && props.coverages.columns.map((column, i) => {
                let item = props.item;
                if (column.coverage_code != 'NONE') {
                    item = props.items && props.items.find(k => column.coverage_code.split(',').indexOf(k.otaCode) != -1);
                }

                if (!props.items.length) {
                    return null;
                }
                const columnPrice = (column) => {
                    if (column.coverage_code != 'NONE') {
                        if (item) {
                            return (
                                <div className="text-md row-price text-blue">
                                    { (item.unitPrice * (props.currency == props.exchange.base ? 1 : props.exchange.rates[props.currency])).toFixed(2) } { props.currency }
                                    { ' ' + (item.unit == 'PER_DAY' ? t('per day') : t('per rental')) }
                                </div>
                            )
                        }
                    } else {
                        return (
                            <div className="text-md row-price text-blue">
                                { t('Included in price') }
                            </div>
                        )
                    }
                };

                return (
                    <div key={i} className={`coverage ${ ((column.coverage_code.split(',').indexOf(props.active) != -1) || (!props.active && column.coverage_code == 'NONE')) && 'selected' }`}>
                        <div>
                            <div className="text-lg coverage-title">
                                { column.title }
                                { columnPrice(column) }
                            </div>
                            {
                                props.coverages.rows.map((row, j) => {
                                    return (
                                        <div key={j} className="coverage-row text-left">
                                            <span className="coverage-row-title">
                                                { row.row_title }
                                            </span>
                                            <i className={`row-price icon icon-${ row[`value_column${i+1}`] == 'true' ? 'check' : 'cross' }-circle-${ row[`value_column${i+1}`] == 'true' ? 'green' : 'light-gray' }`}></i>
                                        </div>
                                    )
                                })
                            }
                            <div className="coverage-row text-left">
                                <span className="coverage-row-title">
                                    { t('Excess') }
                                </span>
                                 <div className="row-price text-blue">{ item && item.excess.excessAmount !== null && item.excess.excessAmount !== undefined ? item.excess.excessAmount + ' ' + item.excess.currency : props.item.excess.excessAmount + ' ' + props.item.excess.currency }</div>
                            </div>
                            <div className="coverage-row text-left">
                                <span className="coverage-row-title">
                                    { t('Deposit') }
                                </span>
                                 <div className="row-price text-blue">{ item && item.excess.depositAmount !== null && item.excess.depositAmount !== undefined ? item.excess.depositAmount + ' ' + item.excess.currency : props.item.excess.depositAmount + ' ' + props.item.excess.currency }</div>
                            </div>                                                                   
                        </div>
                        <button onClick={ () => props.setCoverage(column.coverage_code) } className={`btn btn-coverage ${ ((column.coverage_code.split(',').indexOf(props.active) != -1) || (!props.active && column.coverage_code == 'NONE')) ? 'btn-blue selected' : 'btn-gray' }`}>
                            {(column.coverage_code.split(',').indexOf(props.active) != -1) || (!props.active && column.coverage_code == 'NONE') ? t('selected') : t('select')}
                        </button>
                    </div>
                );
            })
        }
    </div>
);