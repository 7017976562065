import React, {Component} from 'react';
import moment from 'moment';
import {t} from '../../conf/gettext';
import Cart from '../../containers/Cart';
import {NavBar} from '../../components/Desktop/NavBar';
import {AdditionalOption} from '../../components/AdditionalOption';
import {Link, RouteComponentProps} from 'react-router-dom';
import {mileage, monthsShort} from '../../conf/values';
import {Loader} from '../../components/Loader';
import {
  getCurrencyOrDefault,
  getLocaleOrDefault,
  sortExtras
} from '../../helpers';
import queryString from 'query-string';
import Footer from '../../components/Footer';
import SkyScanner from '../../helpers/skyscanner';
import {RichText} from 'prismic-reactjs';
import {CarDetails} from '../../components/Desktop/Listing/Listing';
import {handleBeginCheckoutEvent} from '../../helpers/analyticsEvents';

const initialState = {}
type State = Readonly<typeof initialState>

export default class Confirm extends Component<RouteComponentProps<any>, State> {
  readonly state: State = initialState;
  quoteID: string | string[] = '';
  analyticsSent = false;

  constructor(props) {
    super(props);


    if (this.props.match.params.locale) {
      this.props.setLocale(getLocaleOrDefault(this.props.conf.enabled_languages, this.props.match.params.locale));
    }

    const qs = queryString.parse(this.props.location.search);
    if (qs['quoteID']) {
      this.quoteID = qs['quoteID']
    }
    if (qs['currency']) {
      this.props.setCurrency(getCurrencyOrDefault(this.props.conf.supported_currencies, qs['currency']));
    }
    if (qs['skyscanner_redirectid']) {
      const redirectId = qs['skyscanner_redirectid'];
      this.props.postSource(this.props.baseConf, this.quoteID, {source: qs['source'], value: redirectId});
      SkyScanner.setCookie(redirectId);
    }

  }

  componentDidMount() {
    this.fetchRateInfo(this.props);
    document.title = t('Extras') + ' | ' + this.props.prismic.data.meta_title;

  }

  componentDidUpdate(prevProps: any) {
    if (prevProps !== this.props && this.props.rateInfo && !this.analyticsSent) {
      handleBeginCheckoutEvent(this.props.rateInfo, this.props.currency, this.props.cart);
      this.analyticsSent = true;
    }
  }

  componentWillUnmount() {
    document.title = this.props.prismic.data.meta_title;
  }

  componentWillReceiveProps(newProps) {
    this.fetchRateInfo(newProps);
  }

  fetchRateInfo(props) {
    if (!this.state.fetchedRate && props.baseConf && Object.keys(props.baseConf).length) {
      this.props.fetchRateInfo(this.quoteID, props.baseConf.urls, this.props.history);
      this.setState({fetchedRate: true});
    }
  }

  getTime(date) {
    const hours = date.hours() < 10 ? `0${date.hours()}` : date.hours();
    const minutes = date.minutes() < 10 ? `0${date.minutes()}` : date.minutes();

    return `${hours}:${minutes}`;
  }

  getItem(items, itemGroupType, itemOtaCode) {
    return items[itemGroupType].filter((item) => item.otaCode === itemOtaCode)[0]
  }

  getDesk(deskCode) {
    const deskElement = this.props.desks.filter((desk) => desk.code === deskCode);
    return deskElement[0];
  }

  render() {
    if (!this.props.rateInfo || !Object.keys(this.props.rateInfo).length || !(this.props.prismic && this.props.prismic.data) || !this.props.baseConf) return <Loader/>;

    const pickUpDate = moment(this.props.rateInfo.pickupDate.split('Z')[0]);
    const returnDate = moment(this.props.rateInfo.returnDate.split('Z')[0]);
    const mileageText = this.props.rateInfo.mileagePolicy.mileageTypeUnlimited ?
      t('Unlimited mileage') :
      `${this.props.rateInfo.mileagePolicy.mileageQuantity} ${t(mileage[this.props.rateInfo.mileagePolicy.mileageUnitType.toLowerCase()])} ${t(mileage[this.props.rateInfo.mileagePolicy.mileageUnitPeriodType.toLowerCase()])}`;

    let driveType = null;

    if (this.props.rateInfo.driveType == '4WD') {
      driveType = t('4WD');
    }

    if (this.props.rateInfo.driveType == 'AWD') {
      driveType = t('AWD');
    }

    const extras = sortExtras(this.props.rateInfo.optionalItems.extras);
    const daysString = this.props.rateInfo.rentalDays === 1 ? t('day') : this.props.rateInfo.rentalDays < 5 ? t('days (2-4)') : t('days');

    return (
      <div>
        <NavBar prismic={this.props.prismic} conf={this.props.conf} active={1} currency={this.props.currency}
                setCurrency={this.props.setCurrency} locale={this.props.locale} setLocale={this.props.setLocale}
                history={this.props.history} match={this.props.match}/>
        <div className="search">
          <div className="container text-md">
            <div className="search-row">
              <div className="search-item">
                <h3>{t('Pick-up')}</h3>
                <div className="datetime-info">
                  <div className="date">
                    <div className="month">
                      {monthsShort[pickUpDate.month()]}
                    </div>
                    <div className="numeric">
                      {pickUpDate.date()}
                    </div>
                  </div>
                  <div className="location">
                    <div className="location-row">
                      <i className="icon icon-location"></i>
                      <span>{this.getDesk(this.props.rateInfo.pickupDeskCode).name[this.props.locale.rc_language_code]}</span>
                    </div>
                    <div className="location-row">
                      <i className="icon icon-time"></i>
                      <span>{this.getTime(pickUpDate)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-item">
                <h3>Drop-off</h3>
                <div className="datetime-info">
                  <div className="date">
                    <div className="month">
                      {monthsShort[returnDate.month()]}
                    </div>
                    <div className="numeric">
                      {returnDate.date()}
                    </div>
                  </div>
                  <div className="location">
                    <div className="location-row">
                      <i className="icon icon-location"></i>
                      <span>{this.getDesk(this.props.rateInfo.returnDeskCode).name[this.props.locale.rc_language_code]}</span>
                    </div>
                    <div className="location-row">
                      <i className="icon icon-time"></i>
                      <span>{this.getTime(returnDate)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-actions">
                <button onClick={() => {
                  this.props.history.push(`/${this.props.locale.slug}${window.location.search}`)
                }} className="btn btn-transparent">{t('change dates')}</button>
                <button onClick={() => {
                  this.props.history.push(`/${this.props.locale.slug}${window.location.search}`)
                }} className="btn btn-transparent">{t('see other cars')}</button>

              </div>
            </div>
            <div className="search-disclaimer">
              {this.props.prismic.data.extras_note}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="flex-row margin32_0">
            <div className="flex3 marginright32">
              <div className="flex-row listing no-borders">
                <CarDetails
                  rate={this.props.rateInfo}
                  carinfo={this.props.carinfo}
                  items={this.props.items}
                  locale={this.props.locale}
                  showPrice={true}
                  currency={this.props.currency}
                  exchange={this.props.exchange}
                />
              </div>
              <div className="separator"></div>
              <h3>{t('Additional options')}</h3>
              <div className="flex-row wrap">
                {extras.map((item, i) => {
                  if (this.props.items && this.props.items['extras'] && this.getItem(this.props.items, 'extras', item.otaCode)) {
                    return (
                      <div key={i} className="flex1_0_49 margin0_4">
                        <AdditionalOption
                          item={Object.assign({}, item,
                            {
                              description: this.getItem(this.props.items, 'extras', item.otaCode).description[this.props.locale.rc_language_code],
                              name: this.getItem(this.props.items, 'extras', item.otaCode).name[this.props.locale.rc_language_code],
                              groupType: "extras"
                            }
                          )}
                          addItem={(itm) => this.props.addItem(itm, this.props)}
                          removeItem={(itm) => this.props.removeItem(itm, this.props)}
                          cart={this.props.cart}
                          currency={this.props.currency}
                          exchange={this.props.exchange}/>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            <div className="flex1-2">
              <div className="cart-container">
                <Cart/>
                <div className="margin16_0 flex-row align-center">
                  <div className={`checkbox checkbox-sm ${this.props.terms ? 'checked' : ''}`}
                       onClick={() => this.props.toggleTerms(this.props.terms)}></div>
                  <RichText render={this.props.prismic.data.terms_conditions_accept_text}/>
                </div>
                <div
                  className="fine-print text-red margin8_0">{this.props.payOnline && this.props.prismic.data.extras_hint_pay_online || this.props.prismic.data.extras_hint}</div>
                <button onClick={() => {
                  this.props.history.push(`/${this.props.locale.slug}/coverages${window.location.search}`)
                }} className="btn btn-green margin8_0">
                  {t('continue')}
                </button>
                <Link to={`/${window.location.search}`}>
                  <button className="btn btn-gray margin8_0" onClick={}>{t('back to search')}</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex-row">
            <div className="flex3">
            </div>
            <div className="flex1">
            </div>
          </div>
        </div>
        <Footer prismic={this.props.prismic}/>
      </div>
    );
  }
};
