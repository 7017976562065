import { Dispatch } from 'redux';
import fetch from 'cross-fetch';
import types from '../types';

export function requestConfig() {
    return {
        type: types.REQUEST_CONFIG,
        config: null,
    }
}

export function receiveConfig(config: any) {
    return {
        type: types.RECEIVE_CONFIG,
        config
    }
}

export function fetchConfig(): (dispatch: Dispatch<any, any>) => any {
    return function(dispatch: Dispatch<any, any>) {
        dispatch(requestConfig());

        return fetch('/conf.json')
        .then(res => res.json())
        .then(json => {
            return dispatch(receiveConfig(json));
        });
    }
}
