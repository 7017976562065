import React, {Component} from 'react';
import ReactTelephoneInput from 'react-telephone-input';
import {t} from '../../conf/gettext';
import Cart from '../../containers/Cart';
import {NavBar} from '../../components/Desktop/NavBar';
import {Loader} from '../../components/Loader';
import {Link, RouteComponentProps} from 'react-router-dom';
import {defaultGateway} from 'conf/env';
import {
  fillThreeDModalHtml,
  getBorgunErrorMessage,
  getCurrencyOrDefault,
  getLocaleOrDefault,
  getRate
} from '../../helpers';
import queryString from 'query-string';
import Footer from '../../components/Footer';
import {RichText} from 'prismic-reactjs';
import {createBookingConfirmRateInfo, handleCheckoutStepDetailsLoadEvent} from '../../helpers/analyticsEvents';

const initialState = {
  errors: {},
  arriveByPlane: false,
  driverPhone: null,
  cardError: false,
  cardErrorMessage: null,
  reservationPaymentFailed: false,
  tokenLoading: false,
  lastReservationNumber: null,
  show3DSecureModal: false
};
type State = Readonly<typeof initialState>;

export default class Details extends Component<RouteComponentProps<any>,
  State> {
  readonly state: State = initialState;
  driver: any;
  payment: any

  constructor(props) {
    super(props);

    this.driver = {
      firstName: React.createRef(),
      lastName: React.createRef(),
      email: React.createRef(),
      address: React.createRef(),
      city: React.createRef(),
      country: React.createRef(),
      gender: React.createRef(),
      age: React.createRef(),
      comments: React.createRef(),
      flightNumber: React.createRef(),
      customerId: React.createRef()
    };

    this.payment = {
      cardName: React.createRef(),
      pan: React.createRef(),
      cvc: React.createRef(),
      expMM: React.createRef(),
      expYY: React.createRef()
    };

    if (this.props.match.params.locale) {
      this.props.setLocale(
        getLocaleOrDefault(
          this.props.conf.enabled_languages,
          this.props.match.params.locale
        )
      );
    }

    const qs = queryString.parse(this.props.location.search);
    if (qs['currency']) {
      this.props.setCurrency(
        getCurrencyOrDefault(
          this.props.conf.supported_currencies,
          qs['currency']
        )
      );
    }
  }

  componentDidMount() {
    const qs = queryString.parse(this.props.location.search);
    if (!this.props.rateInfo && !qs['reservationNumber']) {
      if (qs['quoteID']) {
        this.props.history.push(
          `/${this.props.locale.slug}/vehicle${
            window.location.search
          }`
        );
      } else {
        this.props.history.push(
          `/${this.props.locale.slug}${window.location.search}`
        );
      }
    } else if (!this.props.rateInfo && qs['reservationNumber']) {
      if (qs['paymentStatus'] === 'FAILED') {
        this.setState({
          reservationPaymentFailed: true,
          lastReservationNumber: qs['reservationNumber']
        })
        setTimeout(() => this.restoreClient(), 1000);
        this.props.fetchRateInfo(qs['quoteID'], this.props.baseConf.urls, this.props.history);
        this.props.restoreCart();
      } else {
        this.props.history.push(
          `/${this.props.locale.slug}/confirmation${window.location.search}`
        );
      }
    }

    if (this.props.details && Object.keys(this.props.details).length) {
      Object.keys(this.props.details).forEach(key => {
        if (this.driver[key] && this.driver[key].current) {
          this.driver[key].current.value = this.props.details[key];
        } else if (key == 'phone') {
          this.setState({driverPhone: this.props.details[key]});
        }
      });
    }

    if (this.props.rateInfo) {
      handleCheckoutStepDetailsLoadEvent(this.props.rateInfo, this.props.currency, this.props.payOnline,
          this.props.cart);
    }
    document.title = t('Details') + ' | ' + this.props.prismic.data.meta_title;
    this.createPaypalButton();
  }

  createPaypalButton() {
    if (this.props.baseConf.enablePayments === 'paypal') {
      setTimeout(() => {
        if (!document.getElementById('paypal-button-container')) {
          return;
        }
        if (document.getElementsByClassName('paypal-button').length) {
          return;
        }
        const that = this;
        paypal
          .Buttons({
            onClick: (data, actions) => {
              const errors = this.getFormErrors();
              if (Object.keys(errors).length) {
                this.setState({errors});
                return actions.reject();
              }
              return actions.resolve();
            },
            onError: err => {
              console.log(err);
            },
            createOrder: (data, actions) => {
              // Set up the transaction
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: this.getPrice()
                    }
                  }
                ]
              });
            },
            onApprove: (data, actions) => {
              // Authorize the transaction
              const qs = queryString.parse(this.props.location.search);
              return this.createReservation(qs, data.orderID, 'paypal');
            }
          })
          .render('#paypal-button-container');
      }, 10);
    }
  }

  componentWillUnmount() {
    document.title = this.props.prismic.data.meta_title;
    this.setState({
      cardError: false,
      cardErrorMessage: null,
      reservationPaymentFailed: false
    });
  }

  componentWillReceiveProps(props) {
    if (
      props.reservation &&
      props.reservation.status &&
      props.reservation.status.reservationNumber
    ) {
      if (props.reservation.status.paymentStatus != 'FAILED' && props.reservation.status.threeDResponse !== 'FAILED') {
        if (props.reservation.status.threeDResponse) {

          this.setState({show3DSecureModal: true}, () => {
            fillThreeDModalHtml(props.reservation.status.threeDResponse);
          });
          this.saveFieldsToLocalStorage();
        } else {
          const currentUrlParams = new URLSearchParams(window.location.search);
          let newSearchParams = new URLSearchParams();
          currentUrlParams.forEach((value, key) => {
            if (key !== 'quoteID') {
              newSearchParams.set(key, value);
            }
          })
          newSearchParams.set('reservationNumber', props.reservation.status.reservationNumber);
          this.props.history.push(
            `/${this.props.locale.slug}/confirmation?${newSearchParams.toString()}`
          );
        }
      } else {
        this.setState({
          reservationPaymentFailed: true,
          lastReservationNumber: props.reservation.status.reservationNumber
        });
      }
    }

    if (
      props.reservation &&
      props.reservation.status &&
      props.reservation.status === 'error' &&
      !(
        this.props.reservation &&
        this.props.reservation.status &&
        this.props.reservation.status === 'error'
      )
    ) {
      this.setState({errorOverlay: true});
    }
    if (props.payOnline && !this.props.payOnline) {
      this.createPaypalButton();
    }
  }

  saveFieldsToLocalStorage() {
    const client = JSON.stringify({
      firstName: this.driver.firstName.current.value,
      lastName: this.driver.lastName.current.value,
      email: this.driver.email.current.value,
      address: this.driver.address.current.value,
      city: this.driver.city.current.value,
      country: this.driver.country.current.value,
      gender: this.driver.gender.current.value,
      age: this.driver.age.current.value,
      comments: this.props.baseConf.enableCommentsField ? this.driver.comments.current.value : null,
      customerId: this.props.baseConf.enableCustomerIdField ? this.driver.customerId.current && this.driver.customerId.current.value : null,
      flightNumber: this.driver.flightNumber.current && this.driver.flightNumber.current.value,
      phone: this.state.driverPhone,
      terms: this.props.terms
    });
    localStorage.setItem('client', client);
    localStorage.setItem('cart', JSON.stringify(this.props.cart));
  }

  restoreClient() {
    const clientItem = localStorage.getItem('client');
    if (!clientItem) {
      return;
    }
    const client = JSON.parse(clientItem);
    this.driver.firstName.current.value = client.firstName;
    this.driver.lastName.current.value = client.lastName;
    this.driver.email.current.value = client.email;
    this.driver.address.current.value = client.address;
    this.driver.city.current.value = client.city;
    this.driver.country.current.value = client.country;
    this.driver.gender.current.value = client.gender;
    this.driver.age.current.value = client.age;
    if (this.props.baseConf.enableCommentsField) {
      this.driver.comments.current.value = client.comments;
    }
    if (this.props.baseConf.enableCustomerIdField && this.driver.customerId.current) {
      this.driver.customerId.current.value = client.customerId;
    }
    this.props.setDetails({...client});
    this.props.toggleTerms(!client.terms);
    this.setState({driverPhone: client.phone});
    if (this.driver.flightNumber.current) {
      this.driver.flightNumber.current.value = client.flightNumber;
    }
  }

  getPrice() {
    let price = this.props.rateInfo.charges.estimatedTotalAmount;
    if (!this.props.payOnline) {
      price += this.props.rateInfo.pricing.payLocallyFee;
    }
    if (this.props.cart) {
      this.props.cart.forEach(item => {
        price +=
          item.count && item.count > 1
            ? item.converted_price * item.count
            : item.converted_price;
      });
    }

    return getRate(price.toFixed(2), this.props.baseConf[this.props.baseConf.enablePayments].currency, this.props.exchange);
  }

  createBooking() {
    const errors = this.getFormErrors();
    let token = null;
    let gateway = defaultGateway;

    if (this.props.baseConf.enablePayments && this.props.payOnline) {
      for (let key in this.payment) {
        if (
          this.payment[key].current &&
          !this.payment[key].current.value.trim()
        ) {
          errors[key] = true;
        } else if (
          key == 'pan' &&
          !BAPIjs.isValidCardNumber(
            this.payment.pan.current.value.replace(/\s/g, '')
          )
        ) {
          errors[key] = true;
        } else if (
          key == 'cvc' &&
          !BAPIjs.isValidCVC(this.payment.cvc.current.value)
        ) {
          errors[key] = true;
        }
      }
    }
    const qs = queryString.parse(this.props.location.search);
    const partnerReferenceNumber = qs['partnerCode'];

    if (Object.keys(errors).length === 0) {
      if (
        this.props.baseConf.enablePayments == 'borgun' &&
        this.props.payOnline
      ) {
        gateway = 'borgun';
        this.setState({tokenLoading: true});
        BAPIjs.getToken(
          {
            pan: this.payment.pan.current.value.replace(/\s/g, ''),
            expYear: this.payment.expYY.current.value,
            expMonth: this.payment.expMM.current.value,
            tokenLifetime: '120',
            verifyCard: {
              cvc: this.payment.cvc.current.value
            }
          },
          (status, data) => {
            this.setState({tokenLoading: false});
            if (
              data.VerifyCardResult &&
              data.VerifyCardResult.ActionCode === '000'
            ) {
              token = data.Token;
              if (!this.state.reservationPaymentFailed) {
                this.createReservation(qs, token, gateway);
              } else {
                const request = {
                  gateway,
                  token,
                  paymentRedirectUrl: window.location.href.replace(/\?.*/, '')
                }
                if (partnerReferenceNumber) {
                  request.brokerReferenceNumber = partnerReferenceNumber;
                }
                this.props.retryPayment(
                  (this.props.reservation.status &&
                    this.props.reservation.status.reservationNumber) ||
                  this.state.lastReservationNumber,
                  request,
                  this.props.baseConf
                );
              }
              this.setState({cardError: false, cardErrorMessage: null});
            } else {
              this.setState({
                cardError: true,
                cardErrorMessage: getBorgunErrorMessage(data.VerifyCardResult.ActionCode)
              })
            }
          }
        );
      } else if (this.props.baseConf.enablePayments == 'valitor' && this.props.payOnline) {
        gateway = 'valitor';
        if (!this.state.reservationPaymentFailed) {
          this.createReservation(qs, token, gateway)
        } else {
          const request: any = {
            pan: this.payment.pan.current.value.replace(/\s/g, ''),
            expYear: this.payment.expYY.current.value,
            expMonth: this.payment.expMM.current.value,
            cvc: this.payment.cvc.current.value,
            paymentRedirectUrl: window.location.href.replace(/\?.*/, ''),
            gateway: gateway
          }

          if (partnerReferenceNumber) {
            request.brokerReferenceNumber = partnerReferenceNumber;
          }
          this.props.retryPayment(
            (this.props.reservation.status &&
              this.props.reservation.status.reservationNumber) ||
            this.state.lastReservationNumber,
            request,
            this.props.baseConf
          );
        }
        this.setState({cardError: false, cardErrorMessage: null});
      } else {
        this.createReservation(qs, token, gateway);

      }
    }
    this.setState({errors});
  }

  getFormErrors() {
    const errors = {};
    const ageRanges = this.props.conf.driver_age_ranges.sort((a, b) => Number(a.driver_age_range_min) - Number(b.driver_age_range_min))
    const minDriverAge = parseInt(ageRanges[0].driver_age_range_min);
    const maxDriverAge = parseInt(ageRanges[ageRanges.length - 1].driver_age_range_max) || 100;
    if (!this.props.terms) {
      errors['terms'] = true;
    }

    for (let key in this.driver) {
      if (
        key != 'comments' &&
        ((!this.state.arriveByPlane && key != 'flightNumber') ||
          this.state.arriveByPlane)
      ) {
        if (key == 'customerId' && this.driver.country.current.value !== 'IS') {
          continue;
        }
        if (
          this.driver[key].current &&
          !this.driver[key].current.value.trim()
        ) {
          errors[key] = true;
        } else if (
          key == 'email' &&
          this.driver[key].current.value.indexOf('@') == -1
        ) {
          errors[key] = true;
        } else if (key == 'age' && (isNaN(this.driver[key].current.value) ||
          minDriverAge && Number(this.driver[key].current.value) < minDriverAge ||
          maxDriverAge && Number(this.driver[key].current.value) > maxDriverAge
        )) {
          errors[key] = true;
        }
      }
    }

    if (
      !this.state.driverPhone ||
      this.state.driverPhone.length < 5 ||
      !this.state.driverPhone.match(/^[0-9\(\)\+\-\ ]+$/)
    ) {
      errors['phone'] = true;
    }

    return errors;
  }

  createReservation(queryString, token = '', gateway = defaultGateway) {
    const partnerReferenceNumber = queryString['partnerCode'];
    const hash = queryString['quoteID']
    const request: any = {
      rateQuoteId: hash,
      carModel: this.props.rateInfo.size,
      coverages:
        this.props.cart &&
        this.props.cart
          .filter(item => item.groupType == 'coverages')
          .map(item => {
            return {otaCode: item.otaCode};
          }),
      extras:
        this.props.cart &&
        this.props.cart
          .filter(item => item.groupType == 'extras')
          .map(item => {
            return {otaCode: item.otaCode, quantity: item.count};
          }),
      customer: {
        arriveByPlane: this.state.arriveByPlane,
        comments: this.props.baseConf.enableCommentsField ? this.driver.comments.current.value : null,
        customerId: this.props.baseConf.enableCustomerIdField ? this.driver.customerId.current && this.driver.customerId.current.value : null,
        email: this.driver.email.current.value,
        firstName: this.driver.firstName.current.value,
        lastName: this.driver.lastName.current.value,
        phone: this.state.driverPhone,
        flightNumber: this.state.arriveByPlane
          ? this.driver.flightNumber.current.value
          : '',
        address: this.driver.address.current.value,
        city: this.driver.city.current.value,
        country: this.driver.country.current.value,
        age: this.driver.age.current.value,
        gender: this.driver.gender.current.value
      },
      gateway,
      source: queryString['source'] || null
    };
    const isMultiple = this.props.baseConf.enableMultipleProducts;

    if (gateway === 'paypal') {
      request.orderId = token;
    } else if (gateway === 'borgun') {
      request.token = token;
      request.paymentRedirectUrl = window.location.href.replace(/\?.*/, '');
    } else if (gateway === 'valitor') {
      request.pan = this.payment.pan.current.value.replace(/\s/g, '');
      request.expYear = this.payment.expYY.current.value;
      request.expMonth = this.payment.expMM.current.value;
      request.cvc = this.payment.cvc.current.value;
      request.paymentRedirectUrl = window.location.href.replace(/\?.*/, '');
      request.gateway = gateway;
    }
    if (partnerReferenceNumber) {
      request.brokerReferenceNumber = partnerReferenceNumber;
    }

    const bookingConfirmRateInfo = createBookingConfirmRateInfo(this.props.rateInfo, this.props.currency,
        this.props.payOnline, this.props.cart)

    this.props.createReservation(
      request,
      {...this.props.baseConf, skyscannerId: this.props.rateInfo.apiKey},
        bookingConfirmRateInfo
    );
  }

  navigateToResults() {
    this.props.history.push(
      `/${this.props.locale.slug}/prices${window.location.search}`
    );
  }

  render() {
    if (
      !this.props.countries ||
      !(this.props.prismic && this.props.prismic.data) ||
      !this.props.rateInfo ||
      !this.props.baseConf
    ) {
      return <Loader/>;
    }
    const selectedCountry = this.driver.country.current && this.driver.country.current.value || this.props.rateInfo.country;
    const showCustomerIdField = this.props.baseConf.enableCustomerIdField && this.props.baseConf.customerIdEnabledCountries &&
      this.props.baseConf.customerIdEnabledCountries.indexOf(selectedCountry) !== -1;
    return (
      <div>
        {this.props.reservation && this.props.reservation.isFetching &&
          this.props.payOnline && this.props.baseConf.enablePayments === 'paypal' && <Loader spinner={true}/>}
        <NavBar
          prismic={this.props.prismic}
          conf={this.props.conf}
          active={3}
          currency={this.props.currency}
          setCurrency={this.props.setCurrency}
          locale={this.props.locale}
          setLocale={this.props.setLocale}
          history={this.props.history}
          match={this.props.match}
        />
        <div className="search fixed sm"/>
        {this.state.errorOverlay ? (
          <div className="search-overlay overlay-fixed">
            <i className="icon icon-warning-sign-red"/>
            <h2 className="text-red">
              {t('Something went wrong, please try again later')}
            </h2>
            <div className="btn-group" style={{width: '15%'}}>
              <button
                onClick={() => this.navigateToResults()}
                className="btn btn-blue"
              >
                {t('close')}
              </button>
            </div>
          </div>
        ) : (
          ''
        )}
        {this.state.show3DSecureModal ? (
          <div className="search-overlay overlay-fixed">
            <div id="3dsecure-modal-html"></div>
          </div>
        ) : (
          ''
        )}
        <div className="container details">
          <div className="flex-row">
            <div className="flex3 marginright32">
              <div className="margin32_0">
                <h3 className="text-on-primary">{t('Booking details')}</h3>
                <div className="text-on-primary text-md">
                  {this.props.prismic.data.details_note}
                </div>
              </div>
              <h3 className="text-blue margintop64">
                {t("Renter's information")}
              </h3>
              <div className="input-group">
                <div className="row-item flex1 ">
                  <div
                    className={`label text-bold ${this.state.errors[
                      'firstName'
                      ] && 'has-error'}`}
                  >
                    {t('First name')}
                  </div>
                  <input
                    name="firstName"
                    onBlur={() => {
                      this.props.setDetails({
                        firstName: this.driver.firstName.current.value
                      });
                    }}
                    ref={this.driver.firstName}
                    className={`input minwidth100 ${this.state.errors[
                      'firstName'
                      ] && 'has-error'}`}
                    placeholder={t('Enter your first name')}
                    disabled={this.state.reservationPaymentFailed}
                  />
                </div>
                <div className="row-item flex1 ">
                  <div
                    className={`label text-bold ${this.state.errors[
                      'lastName'
                      ] && 'has-error'}`}
                  >
                    {t('Last name')}
                  </div>
                  <input
                    name="lastname"
                    onBlur={() => {
                      this.props.setDetails({
                        lastName: this.driver.lastName.current.value
                      });
                    }}
                    ref={this.driver.lastName}
                    className={`input minwidth100 ${this.state.errors[
                      'lastName'
                      ] && 'has-error'}`}
                    placeholder={t('Enter your last name')}
                    disabled={this.state.reservationPaymentFailed}
                  />
                </div>
              </div>
              <div className="input-group">
                <div className="row-item flex1 ">
                  <div
                    className={`label text-bold ${this.state.errors['email'] &&
                    'has-error'}`}
                  >
                    {t('E-mail address')}
                  </div>
                  <input
                    name="email"
                    onBlur={() => {
                      this.props.setDetails({
                        email: this.driver.email.current.value
                      });
                    }}
                    ref={this.driver.email}
                    className={`input minwidth100 ${this.state.errors[
                      'email'
                      ] && 'has-error'} `}
                    placeholder={t('Enter your e-mail address')}
                    disabled={this.state.reservationPaymentFailed}
                  />
                </div>
                <div className="row-item flex1">
                  <div
                    className={`label text-bold ${this.state.errors['phone'] &&
                    'has-error'}`}
                  >
                    {t('Phone number')}
                  </div>
                  <ReactTelephoneInput
                    disabled={this.state.reservationPaymentFailed}
                    defaultCountry={this.props.rateInfo.country.toLowerCase()}
                    flagsImagePath="/flags.png"
                    value={this.state.driverPhone}
                    onChange={driverPhone => this.setState({driverPhone})}
                    onBlur={() =>
                      this.props.setDetails({phone: this.state.driverPhone})
                    }
                  />
                </div>
              </div>
              <div className="input-group flex-row">
                <div className="row-item flex1 minwidth50">
                  <div
                    className={`label text-bold ${this.state.errors[
                      'address'
                      ] && 'has-error'}`}
                  >
                    {t('Address')}
                  </div>
                  <input
                    name="address"
                    onBlur={() => {
                      this.props.setDetails({
                        address: this.driver.address.current.value
                      });
                    }}
                    ref={this.driver.address}
                    className={`input minwidth100 ${this.state.errors[
                      'address'
                      ] && 'has-error'} `}
                    placeholder={t('Enter your address')}
                    disabled={this.state.reservationPaymentFailed}
                  />
                </div>
                <div className="row-item flex1 minwidth50">
                  <div
                    className={`label text-bold ${this.state.errors[
                      'country'
                      ] && 'has-error'}`}
                  >
                    {t('Country')}
                  </div>
                  <select
                    onChange={() => {
                      this.props.setDetails({
                        country: this.driver.country.current.value
                      });
                    }}
                    ref={this.driver.country}
                    className={`input minwidth100 ${this.state.errors[
                      'country'
                      ] && 'has-error'}`}
                    defaultValue={this.props.rateInfo.country}
                    disabled=true
                  >
                    {this.props.countries.map((country, i) => (
                      <option value={country.code} key={i}>
                        {country.name[this.props.locale.rc_language_code]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="input-group">
                <div className="row-item flex2">
                  <div
                    className={`label text-bold ${this.state.errors['city'] &&
                    'has-error'} `}
                  >
                    {t('City')}
                  </div>
                  <input
                    name="city"
                    onBlur={() =>
                      this.props.setDetails({
                        city: this.driver.city.current.value
                      })
                    }
                    ref={this.driver.city}
                    className={`input minwidth100 ${this.state.errors['city'] &&
                    'has-error'} `}
                    placeholder={t('City of residence')}
                    disabled={this.state.reservationPaymentFailed}
                  />
                </div>
                <div className="row-item flex1">
                  <div
                    className={`label text-bold ${this.state.errors['gender'] &&
                    'has-error'}`}
                  >
                    {t('Gender')}
                  </div>
                  <select
                    onBlur={() => {
                      this.props.setDetails({
                        gender: this.driver.gender.current.value
                      });
                    }}
                    ref={this.driver.gender}
                    className={`input minwidth100 ${this.state.errors[
                      'gender'
                      ] && 'has-error'} `}
                    disabled={this.state.reservationPaymentFailed}
                  >
                    <option value="">{'-----'}</option>
                    <option value="MALE">{t('Male')}</option>
                    <option value="FEMALE">{t('Female')}</option>
                    <option value="UNDEFINED">{t('Other')}</option>
                  </select>
                </div>
                <div className="row-item flex1">
                  <div
                    className={`label text-bold ${this.state.errors['age'] &&
                    'has-error'}`}
                  >
                    {t('Age of renter')}
                  </div>
                  <input
                    name="age"
                    onBlur={() => {
                      this.props.setDetails({
                        age: this.driver.age.current.value
                      });
                    }}
                    ref={this.driver.age}
                    className={`input minwidth100 ${this.state.errors['age'] &&
                    'has-error'} `}
                    disabled={this.state.reservationPaymentFailed}
                  />
                </div>
              </div>
              {showCustomerIdField &&
                  <>
                      <div className="input-group margin0">
                          <div className="row-item">
                              <div className={`label text-bold ${this.state.errors['customerId'] &&
                              'has-error'}`}>{t('Customer ID')}</div>
                              <input name="customerId" ref={this.driver.customerId}
                                     className={`input minwidth100 ${this.state.errors['customerId'] &&
                                     'has-error'} `} disabled={this.state.reservationPaymentFailed}/>
                          </div>
                          <div className="row-item"></div>
                      </div>
                  </> || null}
              {this.props.baseConf.enableCommentsField &&
                  <>
                      <div className="input-group margin0">
                          <div className="row-item">
                              <div className="label text-bold">{t('Comments')}</div>
                              <textarea
                                  name="comments"
                                  onBlur={() => {
                                    this.props.setDetails({
                                      comments: this.driver.comments.current.value
                                    });
                                  }}
                                  ref={this.driver.comments}
                                  className="input"
                                  disabled={this.state.reservationPaymentFailed}
                              />
                          </div>
                      </div>
                      <div className="margin8_0 text-left text-sm text-dark-gray">
                        {this.props.prismic.data.details_comments_note}
                      </div>
                  </> || null}
              <div className="margin16_0">
                <div
                  className={`checkbox checkbox-sm ${this.state.arriveByPlane &&
                  'checked'} ${this.state.reservationPaymentFailed &&
                  'disabled'}`}
                  onClick={() =>
                    this.setState({arriveByPlane: !this.state.arriveByPlane})
                  }
                />
                <span
                  className="text-dark-gray text-md text-bold"
                  onClick={() =>
                    this.setState({arriveByPlane: !this.state.arriveByPlane})
                  }
                  style={{cursor: 'pointer'}}
                >
                  {' '}
                  {t("I'm arriving by plane")}
                </span>
              </div>
              {this.state.arriveByPlane ? (
                <div className="input-group text-center">
                  <div className="row-item">
                    <div
                      className={`label text-bold text-left ${this.state.errors[
                        'flightNumber'
                        ] && 'has-error'}`}
                    >
                      {t('Flight number')}
                    </div>
                    <input
                      name="flightnumber"
                      onBlur={() =>
                        this.props.setDetails({
                          flightNumber: this.driver.flightNumber.current.value
                        })
                      }
                      ref={this.driver.flightNumber}
                      className={`input minwidth50 ${this.state.errors[
                        'flightNumber'
                        ] && 'has-error'} `}
                      disabled={this.state.reservationPaymentFailed}
                    />
                  </div>
                  <div className="row-item"/>
                </div>
              ) : (
                ''
              )}
              {this.props.baseConf.enablePayments !== 'disabled' &&
              this.props.baseConf.enablePayments !== 'paypal' &&
              this.props.payOnline
                ? [
                  <div key="paym_sep" className="separator"/>,
                  <h3 key="paym_title" className="text-blue margintop32">
                    {t('Payment details')}
                  </h3>,
                  <h4
                    key="is_test"
                    className="text-red text-right"
                    style={{marginTop: '-39px'}}
                    hidden={
                      this.props.baseConf[this.props.baseConf.enablePayments].paymentMode !== 'test'
                    }
                  >
                    TEST
                  </h4>,
                  <div
                    key="paym_card_err"
                    className={`error margin8_0 ${
                      !this.state.cardError || this.state.tokenLoading ? 'd-none' : ''
                    }`}
                  >
                    {this.state.cardErrorMessage}
                  </div>,
                  <div
                    key="paym_err"
                    className={`error margin8_0 ${
                      !this.state.reservationPaymentFailed ||
                      this.state.cardError ||
                      this.state.tokenLoading ? 'd-none' : ''
                    }`}
                  >
                    {this.props.prismic.data.cc_payment_failed_message}
                  </div>,
                  <div key="paym_card" className="input-group flex-row">
                    <div className="row-item flex3">
                      <div
                        className={`label text-bold ${this.state.errors[
                          'cardName'
                          ] && 'has-error'} `}
                      >
                        {t('Cardholder name')}
                      </div>
                      <input
                        ref={this.payment.cardName}
                        className={`input minwidth100 ${this.state.errors[
                          'cardName'
                          ] && 'has-error'} `}
                        placeholder={t("Cardholder's first and last name")}
                      />
                    </div>
                    <div className="row-item flex2">
                      <div
                        className={`label text-bold ${this.state.errors[
                          'cardNumber'
                          ] && 'has-error'} `}
                      >
                        {t('Credit card number')}
                      </div>
                      <input
                        type="text"
                        ref={this.payment.pan}
                        autoComplete="off"
                        className={`input minwidth100 ${this.state.errors[
                          'pan'
                          ] && 'has-error'} `}
                        placeholder="0000 0000 0000 0000"
                      />
                    </div>
                    <div className="row-item flex1">
                      <div
                        className={`label text-bold ${this.state.errors[
                          'cvc'
                          ] && 'has-error'} `}
                      >
                        {t('CVC')}
                      </div>
                      <input
                        type="password"
                        ref={this.payment.cvc}
                        autoComplete="off"
                        className={`input minwidth100 ${this.state.errors[
                          'cvc'
                          ] && 'has-error'} `}
                        placeholder="000"
                      />
                    </div>
                  </div>,
                  <div key="paym_expiry" className="input-group text-center">
                    <div className="row-item">
                      <div
                        className={`label text-bold text-left ${(this.state
                            .errors['expMM'] ||
                          this.state.errors['expYY']) &&
                        'has-error'} `}
                      >
                        {t('Card expiry date')}
                      </div>
                      <div className="flex-row">
                        <input
                          type="text"
                          ref={this.payment.expMM}
                          autoComplete="off"
                          className={`input text-center flex1 joined ${this
                            .state.errors['expMM'] && 'has-error'} `}
                          placeholder={t('MM')}
                          maxLength="2"
                        />
                        <input
                          type="text"
                          ref={this.payment.expYY}
                          autoComplete="off"
                          className={`input text-center flex1 ${this.state
                            .errors['expYY'] && 'has-error'} `}
                          placeholder={t('YY')}
                          maxLength="2"
                        />
                      </div>
                    </div>
                    <div className="row-item"/>
                  </div>,
                  <div key="paym_cards" className="input-group">
                    {this.props.conf.accepted_cards_logos && (
                      <div className="row-item flex2 w-100">
                        <div className={`label text-bold`}>
                          {t('Accepted credit cards')}
                        </div>
                        <img
                          src={this.props.conf.accepted_cards_logos.url}
                          width={
                            this.props.conf.accepted_cards_logos.dimensions
                              .width /
                            (this.props.conf.accepted_cards_logos.dimensions
                                .height /
                              48)
                          }
                          height={48}
                        />
                      </div>
                    )}
                  </div>
                ]
                : null}
            </div>
            <div className="flex1-2 margin32_0">
              <div className="cart-container">
                <Cart/>
                <div className="margin16_0 flex-row align-center">
                  <div
                    className={`checkbox checkbox-sm ${
                      this.props.terms ? 'checked' : ''
                    } ${this.state.errors.terms ? 'has-error' : ''}`}
                    onClick={() => this.props.toggleTerms(this.props.terms)}
                  />
                  <RichText render={this.props.prismic.data.terms_conditions_accept_text}/>
                </div>
                <div className="fine-print text-red margin8_0">
                  {this.props.payOnline && this.props.prismic.data.details_hint_pay_online || this.props.prismic.data.details_hint}
                </div>
                {this.props.payOnline &&
                this.props.baseConf.enablePayments === 'paypal' ? (
                  <div id="paypal-button-container"/>
                ) : (
                  <button
                    onClick={() => this.createBooking()}
                    className="btn btn-green margin8_0"
                    disabled={
                      (this.props.reservation &&
                        this.props.reservation.isFetching) ||
                      this.state.tokenLoading
                    }
                  >
                    {(this.props.reservation &&
                      this.props.reservation.isFetching) ||
                    this.state.tokenLoading ? (
                      <div className="spinner green"/>
                    ) : (
                      t('Confirm booking')
                    )}
                  </button>
                )}
                {this.state.reservationPaymentFailed ? (
                  <Link
                    to={`/${this.props.locale.slug}/prices${
                      window.location.search
                    }`}
                    onClick={this.props.cancelReservation}
                  >
                    <button className="btn btn-gray margin8_0">
                      {t('cancel booking')}
                    </button>
                  </Link>
                ) : (
                  <Link
                    to={`/${this.props.locale.slug}/coverages${window.location.search}`}
                  >
                    <button className="btn btn-gray margin8_0">
                      {t('back to protection')}
                    </button>
                  </Link>
                )}
                <div className="margin8_0">
                  <span className="text-md">
                    {' '}
                    {t('Also see our')}{' '}
                    <a
                      target="_blank"
                      href={
                        this.props.prismic.data.cancellation_policy_link.url
                      }
                    >
                      {t('Cancellation and Refund Policy')}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer prismic={this.props.prismic}/>
      </div>
    );
  }
}
