import types from '../types';
import {handleAddToCartEvent, handleRemoveFromCartEvent} from '../helpers/analyticsEvents';

const initialState = {
  items: [],
  payOnline: true
};

const cart = (state = initialState, action) => {
    switch (action.type) {
        case types.REMOVE_ITEM:
            const removeItemClone = Object.assign({}, action.item);
            const previousItem = (state.items || []).find(item => {
                return ((item.groupType == action.item.groupType) && (item.otaCode == action.item.otaCode))
            })
            removeItemClone.count = previousItem.count;
            handleRemoveFromCartEvent([removeItemClone], action.componentProps.currency, action.componentProps.rateInfo.rentalDays)

            return Object.assign({}, state,
                {
                    items: (state.items || []).filter(item => {
                        return !((item.groupType == action.item.groupType) && (item.otaCode == action.item.otaCode))
                    })
                }
            );
        case types.CLEAR_CART:

            return Object.assign({}, state,
                {
                    items: []
                }
            );
        case types.ADD_ITEM:
            const exists = state.items && state.items.find(item => {
                return (item.groupType == action.item.groupType) && (item.otaCode == action.item.otaCode)
            });
            const cart = (state.items || []).map(item => {
                return !((item.groupType == action.item.groupType) && (item.otaCode == action.item.otaCode)) ? item : action.item
            });
            const itemClone = Object.assign({}, action.item);
            if (!exists) {
                cart.push(action.item);
                handleAddToCartEvent(false, action.componentProps.rateInfo, action.componentProps.currency, false, [itemClone]);
            } else if (exists.count < action.item.count) {
                itemClone.count = 1;
                handleAddToCartEvent(false, action.componentProps.rateInfo, action.componentProps.currency, false, [itemClone]);
            } else {
                itemClone.count = exists.count - action.item.count;
                handleRemoveFromCartEvent([itemClone], action.componentProps.currency, action.componentProps.rateInfo.rentalDays);
            }

            return Object.assign({}, state, { items: cart });
        case types.TOGGLE_PAY_ONLINE:
          return { ...state, payOnline: action.payload }
        case types.RESTORE_CART: {
            const items = JSON.parse(localStorage.getItem('cart'));
            return { ...state, items }
        }
        default:
            return state
    }
}

export default cart
