import types from '../types';

const desks = (state = [], action) => {
    switch (action.type) {
        case types.REQUEST_DESKS:
            return Object.assign({}, state,
                {
                    desks: action.desks
                }
            );
        case types.RECEIVE_DESKS:
            return Object.assign({}, state,
                {
                    desks: action.desks
                }
            );
        case types.REQUEST_OPENING_TIMES:
            return Object.assign({}, state,
                {
                    openingTimes: {}
                }
            );
        case types.RECEIVE_OPENING_TIMES:
            return Object.assign({}, state,
                {
                    openingTimes: action.openingTimes
                }
            );
        default:
            return state
    }
};

export default desks;
