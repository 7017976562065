import types from '../types';

const terms = (state = [], action) => {
    switch (action.type) {
        case types.TOGGLE_TERMS:
            return Object.assign({}, state,
                {
                    terms: action.terms
                }
            );
        default:
            return state
    }
}

export default terms