import React, { Component } from 'react';

const initialState = {
	open: false
}
type State = Readonly<typeof initialState>

export default class Collapse extends Component<{}, State> {
	readonly state: State = initialState;

    getMarketingBody(text) {
        let __html = '';
        let isList = false;
        text.forEach(t => {
            if (t.type == 'paragraph') {
                if (isList) isList = false && __html += '</ul>';

                __html += `<p>${ t.text }</p>`;
            } else if (t.type == 'list-item') {
                if (!isList) isList = true && __html += '<ul>';

                __html += `<li>${ t.text }</li>`;
            }
        })

        return { __html };
    }

	render() {
		return (
			<div className={`faq-item ${this.state.open ? 'open' : ''}`} onClick={() => { this.setState({open: !this.state.open}) }}>
				<div className="heading">
					<i className={`icon icon-chevron-${this.state.open ? 'up' : 'down'}-gray`}></i>
					{ this.props.text.marketing_title }
				</div>
				<div className="body" dangerouslySetInnerHTML={ this.getMarketingBody(this.props.text.marketing_text) }></div>
			</div>
		);
	}
}
