import types from '../types';

const prismic = (state = [], action) => {
    switch (action.type) {
        case types.RECEIVE_PRISMIC:
            return Object.assign({}, state,
                {
                    prismic: action.prismic
                }
            );
        default:
            return state
    }
}

export default prismic