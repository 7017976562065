import React, { Component } from 'react';
import moment from 'moment';
import { t } from '../../conf/gettext';
import { RouteComponentProps } from 'react-router-dom';
import { SearchForm } from '../SearchForm';
import { monthsShort } from '../../conf/values';
import search from '../../reducers/search';

const initialState = {
    searchOpen: false,
}
type State = Readonly<typeof initialState>

export default class SearchBar extends Component<any, State> {
    readonly state: State = initialState;

    componentDidMount() {
        let pickUpDesk = [];
        let dropOffDesk = [];
        if (this.props.defaultValues) {
            pickUpDesk = Object.keys(this.props.desks).map(key => this.props.desks[key]).filter(desk => desk.code == this.props.defaultValues.pickUpDesk);
            dropOffDesk = Object.keys(this.props.desks).map(key => this.props.desks[key]).filter(desk => desk.code == this.props.defaultValues.dropOffDesk);
        }

        const state = {
            pickUpDate: (this.props.defaultValues && moment(this.props.defaultValues.pickUpDate)) || (this.props.rateInfo && this.props.rateInfo.pickupDate && this.props.rateInfo.pickupDate.split('Z').length && moment(this.props.rateInfo.pickupDate.split('Z')[0])) || moment().add(1, 'days'),
            returnDate: (this.props.defaultValues && moment(this.props.defaultValues.dropOffDate)) || (this.props.rateInfo && this.props.rateInfo.returnDate && this.props.rateInfo.returnDate.split('Z').length && moment(this.props.rateInfo.returnDate.split('Z')[0])) || moment().add(5, 'days'),
            pickUpDesk: (pickUpDesk.length && pickUpDesk[0].code)  || (this.props.rateInfo && this.props.rateInfo.pickupDeskCode) || this.props.conf.default_pu_location_code,
            returnDesk: (dropOffDesk.length && dropOffDesk[0].code) || (this.props.rateInfo && this.props.rateInfo.pickupDeskCode) || this.props.conf.default_pu_location_code,
            country: (this.props.rateInfo && this.props.rateInfo.sourceCountry) || (this.props.defaultValues && this.props.defaultValues.country),
            age: (this.props.rateInfo && this.props.rateInfo.driverAge) || (this.props.defaultValues && this.props.defaultValues.age),
            pickUpTime: (this.props.defaultValues && this.props.defaultValues.pickUpDate && this.getTime(this.props.defaultValues.pickUpDate)) || (this.props.rateInfo && this.props.rateInfo.pickupDate && this.props.rateInfo.pickupDate.split('Z').length && this.getTime(this.props.rateInfo.pickupDate.split('Z')[0])),
            dropOffTime: (this.props.defaultValues && this.props.defaultValues.dropOffDate && this.getTime(this.props.defaultValues.dropOffDate)) || (this.props.rateInfo && this.props.rateInfo.returnDate && this.props.rateInfo.returnDate.split('Z').length && this.getTime(this.props.rateInfo.returnDate.split('Z')[0])),
            promoCode: (this.props.defaultValues && this.props.defaultValues.promoCode)
        };

        /*if (this.props.defaultValues && this.props.defaultValues.url) {
            window.history.pushState({path: this.props.defaultValues.url}, '', this.props.defaultValues.url);
        }*/
            const defaultAge = this.props.conf.driver_age_ranges.find(a => a.default === 'true') || this.props.conf.driver_age_ranges[0];
        if (this.props.defaultValues &&
            this.props.defaultValues.pickUpDate &&
            this.props.defaultValues.pickUpDesk &&
            this.props.defaultValues.dropOffDate &&
            (this.props.defaultValues.dropOffDesk == 0 ? this.props.defaultValues.pickUpDesk : this.props.defaultValues.dropOffDesk) &&
            this.props.defaultValues.country) {
                this.props.fetchRates({
                    currency: this.props.defaultValues.currency,
                    driverAge: this.props.defaultValues.age || defaultAge.driver_age_range_min,
                    pickupDate: moment(this.props.defaultValues.pickUpDate).toISOString(true).split('.')[0],
                    pickupDeskCode: this.props.defaultValues.pickUpDesk,
                    returnDate: moment(this.props.defaultValues.dropOffDate).toISOString(true).split('.')[0],
                    returnDeskCode: this.props.defaultValues.dropOffDesk == 0 ? this.props.defaultValues.pickUpDesk : this.props.defaultValues.dropOffDesk,
                    sourceCountry: this.props.defaultValues.country,
                    promoCode: this.props.defaultValues.promoCode,
                    hideRest: !!this.props.defaultValues.promoCode,
                    partnerCode: this.props.defaultValues.partnerCode,
                    apiKey: this.props.defaultValues.apiKey
                }, this.props.baseConf);
        }

        if (this.props.defaultValues && this.props.defaultValues.searchOpen) {
            state.searchOpen = this.props.defaultValues.searchOpen;
        }

        this.setState(Object.assign({}, state, { setup: true }));
    }

    private toggleSearchForm() {
        return this.setState({ searchOpen: !this.state.searchOpen });
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.country && nextProps.defaultValues && nextProps.defaultValues.country) {
            this.setState({ country: nextProps.defaultValues.country});
        }
    }

    fetchRates(search: any) {
        this.toggleSearchForm();
        const puDateIsoString = moment(search.pickupDate).toISOString(true);
        const doDateIsoString = moment(search.returnDate).toISOString(true);
        this.setState({
            pickUpDate: puDateIsoString && puDateIsoString.split('.')[0],
            returnDate: doDateIsoString && doDateIsoString.split('.')[0],
            pickUpDesk: search.pickupDeskCode,
            returnDesk: search.returnDeskCode,
            country: search.country,
            age: search.driverAge,
            promoCode: search.promoCode,
            searched: true,
            hideRest: !!search.promoCode,
            partnerCode: search.partnerCode,
            apiKey: search.apiKey
        });

        if (this.props.history) {
            this.props.history.push(`/${ window.location.search }`);
        }

        return this.props.fetchRates(search, this.props.baseConf, search.apiKey);
    }

    getTime(d) {
        const date = moment(d);
        const hours = date.hours() < 10 ? `0${date.hours()}` : date.hours();
        const minutes = date.minutes() < 10 ? `0${date.minutes()}` : date.minutes();

        return `${hours}:${minutes}`;
    }

    getHumanDate(d) {
        const date = moment(d);
        const hours = date.hours() < 10 ? `0${date.hours()}` : date.hours();
        const minutes = date.minutes() < 10 ? `0${date.minutes()}` : date.minutes();
        return `${date.format('DD')}. ${ t(date.format('MMMM').toLowerCase()) } ${hours}:${minutes}`;
    }

    getLocationString(desk) {
        if (desk.latitude && desk.longitude) {
            return `${desk.latitude},${desk.longitude}`;
        } else {
            return [desk.address1, desk.address2, desk.city, desk.country].join('+').replace(' ', '+');
        }
    }

    getDesk(code) {
        const desk = Object.keys(this.props.desks).map(key => this.props.desks[key]).filter(desk => desk.code === code)[0];
        if (desk) {
            return desk;
        } else {
            return '-';
        }
    }

    render() {
        if (!this.state.setup) return null;

        return (
            <div className="search">
                <div className="datetime-info">
                    <div className="location">
                        <div className="location-row">
                            <i className="icon icon-location"></i>
                            <span>{ t('From') } <span className="text-blue">{ this.getDesk(this.state.pickUpDesk).name[this.props.locale.rc_language_code] }</span> { t('to') } <span className="text-blue">{ this.getDesk(this.state.returnDesk).name[this.props.locale.rc_language_code] }</span></span>
                        </div>
                        <div className="location-row">
                            <i className="icon icon-time"></i>
                            <span>{ t('From') } <span className="text-blue">{ (this.getHumanDate(this.state.pickUpDate)) || '-' }</span> { t('to') } <span className="text-blue">{ this.getHumanDate(this.state.returnDate) || '-' }</span></span>
                        </div>
                    </div>
                </div>
                { this.props.path && this.props.path.indexOf('confirmation') != -1 && <button className="btn btn-transparent" onClick={ () => this.setState({ pickUpDetailedOpen: !this.state.pickUpDetailedOpen }) }>{ this.state.pickUpDetailedOpen ? t('Hide') : t('Show') } { t('detailed pick-up information') }</button>}
                { this.props.path && this.props.path.indexOf('confirmation') != -1 && (
                    <div className={`detailed-info text-on-primary ${ this.state.pickUpDetailedOpen && 'open' }`}>
                        <div className="flex-row margin16_0">
                            <div className="flex1">
                                <h3>{ t('Location details') }</h3>
                            </div>
                            <div className="flex1">
                                <h3>{ t('Opening hours') }</h3>
                            </div>
                        </div>
                        <div className="flex-row">
                            <div className="flex1">
                                {
                                    this.getDesk(this.props.reservation.pickupDeskCode).pickupInstructions[this.props.locale.rc_language_code] }
                            </div>
                            <div className="flex1">
                                { t('On your pick-up date, we are open at the following times') }
                            </div>
                        </div>
                        <div className="flex-row margin16_0">
                            <div className="flex1">
                                <strong>{ `${ this.getDesk(this.props.rateInfo.pickupDeskCode).address }, ${ this.getDesk(this.props.rateInfo.pickupDeskCode).city }, ${ this.getDesk(this.props.rateInfo.pickupDeskCode).country }` }</strong>
                            </div>
                            {/*<div className="flex1">
                                <strong>{ this.props.rateInfo.pickupDesk.openingHours }</strong>
                            </div>*/}
                        </div>
                        <div className="flex-row">
                            <iframe className="flex1" src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBe6ryJmWCL6i-9n31E37dFru2Jpvsxs4E&q=${this.getLocationString(this.getDesk(this.props.reservation.pickupDeskCode))}&zoom=10`}></iframe>
                        </div>
                    </div>
                )}
                { this.props.path && this.props.path.indexOf('confirmation') != -1 ? null : <button className="btn btn-transparent" onClick={ () => this.toggleSearchForm() }>{ t('edit search') }</button> }
                { this.props.path && this.props.path.indexOf('confirmation') != -1 && <button className="btn btn-transparent" onClick={ () => this.setState({ dropOffDetailedOpen: !this.state.dropOffDetailedOpen }) }>{ this.state.dropOffDetailedOpen ? t('Hide') : t('Show') } { t('detailed drop-off information') }</button>}
                { this.state.searchOpen ? 
                    <SearchForm 
                        search={this.props.search}
                        setSearch={ this.props.setSearch }
                        countries={ this.props.countries }
                        minimalSearch={ this.props.minimalSearch || false }
                        desks={ this.props.desks }
                        state={ this.state }
                        fetchRates={ (search) => { this.fetchRates(search) } }
                        toggle={ this.toggleSearchForm.bind(this) }
                        locale={ this.props.locale }
                        conf={ this.props.conf }
                        baseConf={this.props.baseConf}
                        currency={ this.props.currency }
                    /> : null }
                { this.props.path && this.props.path.indexOf('confirmation') != -1 && (
                    <div className={`detailed-info text-on-primary ${ this.state.dropOffDetailedOpen && 'open' }`}>
                        <div className="flex-row margin16_0">
                            <div className="flex1">
                                <h3>{ t('Location details') }</h3>
                            </div>
                            <div className="flex1">
                                <h3>{ t('Opening hours') }</h3>
                            </div>
                        </div>
                        <div className="flex-row">
                            <div className="flex1">
                                { this.getDesk(this.props.reservation.returnDeskCode).returnInstructions[this.props.locale.rc_language_code] }
                            </div>
                            <div className="flex1">
                                { t('On your drop-off date, we are open at the following times') }
                            </div>
                        </div>
                        <div className="flex-row margin16_0">
                            <div className="flex1">
                                <strong>{ `${this.getDesk(this.props.reservation.returnDeskCode).address }, ${ this.getDesk(this.props.reservation.returnDeskCode).city }, ${ this.getDesk(this.props.reservation.returnDeskCode).country }` }</strong>
                            </div>
                            {/*<div className="flex1">
                                <strong>{ this.props.rateInfo.returnDesk.openingHours }</strong>
                            </div>*/}
                        </div>
                        <div className="flex-row">
                            <iframe className="flex1" src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBe6ryJmWCL6i-9n31E37dFru2Jpvsxs4E&q=${this.getLocationString(this.getDesk(this.props.reservation.returnDeskCode))}&zoom=10`}></iframe>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
