import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {withRouter} from 'react-router';
import {fetchReservation} from '../../../actions/reservation';
import {addDriver} from '../../../actions/drivers';
import {setCurrency} from '../../../actions/currency';
import Booking from '../../../views/Desktop/Booking';
import {getPrismic} from '../../../helpers';
import {setLocale} from '../../../actions/locale';
import {bookHotel} from '../../../actions/hotel';

const mapStateToProps = state => {
  return {
    desks: state.desks.desks,
    reservation: state.reservation.reservation,
    prismic: getPrismic(state.prismic.prismic, state.locale.locale),
    items: state.items.items,
    currency: state.currency.currency,
    exchange: state.currency.exchange,
    locale: state.locale.locale,
    conf: state.prismic.prismic && state.prismic.prismic.results.find(r => r.type == 'configuration').data,
    hotel: state.hotel,
    baseConf: state.config.config,
    carinfo: state.carinfo
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any, any>) => {
  return {
    fetchReservation: (id: string, urls) => {
      dispatch(fetchReservation(id, urls))
    },
    addDriver: (driver, hash, urls) => {
      dispatch(addDriver(driver, hash, urls))
    },
    setCurrency: (currency) => {
      dispatch(setCurrency(currency))
    },
    setLocale: (locale) => {
      dispatch(setLocale(locale))
    },
    bookHotel: (payload, urls) => {
      dispatch(bookHotel(payload, urls))
    },
  }
}

export default withRouter(connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(Booking));