import types from '../types';

const countries = (state = {conf: {}}, action) => {
    switch (action.type) {
        case types.REQUEST_COUNTRIES:
            return Object.assign({}, state,
                {
                    countries: action.countries
                }
            );
        case types.RECEIVE_COUNTRIES:
            return Object.assign({}, state,
                {
                    countries: action.countries
                }
            );
        default:
            return state
    }
}
export default countries