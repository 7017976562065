import React, { Component } from 'react';
import { t } from '../../conf/gettext';
import { getRate } from '../../helpers';

const initialState = {
    count: 0,
};
type State = Readonly<typeof initialState>

export default class AdditionalOption extends Component<any, State> {
    readonly state: State = initialState;

    add(count = null) {
        const newCount = count === null ? (Number(!this.state.count) || 0) : this.state.count + count;

        if (count && newCount <= this.props.item.maxQuantity && newCount >= 0) {
            this.setState({count: newCount});
        } else if (count == null) {
            this.setState({count: newCount});
        }

        if (newCount == 0) {
            this.props.removeItem(this.props.item);
        } else if (this.props.item.type === 'bool' || (newCount <= this.props.item.maxQuantity && newCount >= 0)) {
            this.props.addItem(Object.assign({}, this.props.item, { count: newCount }));
        }
    }

    componentDidMount() {
        this.setCount();
    }

    componentWillReceiveProps(props) {
        this.setCount(props);
    }

    setCount(props = this.props) {
        let found = false;

        if (props.cart && props.cart.length) {
            props.cart.forEach(item => {
                if (item.otaCode == this.props.item.otaCode) {
                    this.setState({count: item.count});
                    found = true;
                }
            });
        }

        if (!found && this.state.count > 0) {
            this.setState({count : 0});
        }
    }

    render () {
        if (!this.props.item) return null;

        const description = this.props.item.description ? (<div className="description">{this.props.item.description}</div>) : null;

        const controls = this.props.item.maxQuantity <= 1 ? (
            <div className="controls">
                <div className={`checkbox checkbox-lg ${ this.state.count == 1 && 'checked' }`} onClick={ () => this.add() }></div>
            </div>
        ) : (
            <div className="controls">
                <div className="minus-button" onClick={ () => this.add(-1) }></div>
                <span className="count">{ this.state.count || 0 }</span>
                <div className="plus-button" onClick={ () => this.add(1) }></div>
            </div>
        );

        return (
            <div className="additional-option">
                <div className="header">
                    <div className="info">
                        <div className="name">
                            { this.props.item.name }
                        </div>
                        <div className="price">
                            <span className="blue">{ getRate(this.props.item.unitPrice, this.props.currency, this.props.exchange) } { this.props.currency } </span>
                            { this.props.item.unit == 'PER_DAY' ? t('per day') : t('per rental') }
                        </div>
                    </div>
                    { controls }
                </div>
                { description }
            </div>
        );
    }
}