import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {withRouter} from 'react-router';
import {fetchRateInfo} from '../../../actions/rateinfo';
import {addItem, removeItem} from '../../../actions/cart';
import {toggleTerms} from '../../../actions/terms';
import ConfirmView from '../../../views/Desktop/Confirm';
import {getPrismic} from '../../../helpers';
import {setCurrency} from '../../../actions/currency';
import {setLocale} from '../../../actions/locale';
// import {postSource} from '../../../actions/rates';

const mapStateToProps = state => {
  return {
    rateInfo: state.rateInfo.rateinfo,
    cart: state.cart.items,
    terms: state.terms.terms || false,
    prismic: getPrismic(state.prismic.prismic, state.locale.locale),
    items: state.items.items,
    desks: state.desks.desks,
    currency: state.currency.currency,
    exchange: state.currency.exchange,
    locale: state.locale.locale,
    conf: state.prismic.prismic && state.prismic.prismic.results.find(r => r.type == 'configuration').data,
    baseConf: state.config.config,
    payOnline: state.cart.payOnline,
    carinfo: state.carinfo
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any, any>) => {
  return {
    fetchRateInfo: (hash: string, urls, history = null) => {
      dispatch(fetchRateInfo(hash, urls, history))
    },
    addItem: (item, props) => {
      dispatch(addItem(item, props))
    },
    removeItem: (item, props) => {
      dispatch(removeItem(item, props))
    },
    toggleTerms: (terms) => {
      dispatch(toggleTerms(terms))
    },
    setCurrency: (currency) => {
      dispatch(setCurrency(currency))
    },
    setLocale: (locale) => {
      return dispatch(setLocale(locale))
    },
    // postSource: (conf, rateQuoteId, body) => dispatch(postSource(conf, rateQuoteId, body))
  }
}

export default withRouter(connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmView));
