import React, {Component} from 'react';
import {t} from '../../conf/gettext';
import {fuel, mileage, vehicleTypes} from '../../conf/values';
import {getRate} from 'helpers';
import PromoCodeInfo from './PromoCodeInfo';
import ReactTooltip from 'react-tooltip';
import {handleAddToCartEvent} from '../../helpers/analyticsEvents';

class Listing extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      current: this.props.rate,
      index: 0
    }
  }

  navigate(isBack, alternatives = []) {
    let nextCar;
    let nextIndex;
    const index = this.state.index;
    if (index === 0 && isBack) {
      nextCar = alternatives[alternatives.length - 1];
      nextIndex = alternatives.length;
    } else if (index === alternatives.length && !isBack) {
      nextIndex = 0;
      nextCar = this.props.rate;
    } else {
      nextIndex = isBack ? index - 1 : index + 1;
      nextCar = nextIndex === 0 ? this.props.rate : alternatives[nextIndex - 1];
    }
    this.setState({
      index: nextIndex,
      current: nextCar
    })
  }

  getItem(items, itemGroupType, itemOtaCode) {
    return items[itemGroupType].filter((item) => item.otaCode === itemOtaCode)[0]
  }

  render() {
    const daysString = this.props.rate.rentalDays === 1 ? t('day') : this.props.rate.rentalDays < 5 ? t('days (2-4)') : t('days');
    const mileageText = this.props.rate.mileagePolicy.mileageTypeUnlimited ?
      t('Unlimited mileage') :
      `${this.props.rate.mileagePolicy.mileageQuantity} ${t(mileage[this.props.rate.mileagePolicy.mileageUnitType.toLowerCase()])} ${t(mileage[this.props.rate.mileagePolicy.mileageUnitPeriodType.toLowerCase()])}`;

    const alternatives = this.props.carinfo && this.props.carinfo.cars && this.props.carinfo.cars[this.props.rate.vehicleId];
    const imgStyle = {backgroundImage: `url(${this.state.current.image.original || this.state.current.imageUrl})`};
    return (
      <div className={'listing' + (this.props.hideBookButton ? ' no-borders' : '')}>
        <div className="header">
          <div className="info">
            <div className="size-class-info">
              <span
                className="text-bold">{t(vehicleTypes[this.props.rate.size] || this.props.rate.size)}</span>
              {this.props.rate.isGuaranteedModel &&
                  <span className="description guaranteed">{t('Guaranteed model, you will get this exact car!')}</span>
                ||
                alternatives && alternatives.length &&
                  <span className="description">{t('You will get one of these cars:')}</span>
                ||
                  <span className="description">{t('or similar')}</span>}
            </div>
            <div className="size-class">
              {this.state.current.model}
            </div>
            {alternatives && alternatives.length &&
                <div className="car-carousel">
                    <div className="cars">
                        <button className="prev-btn" onClick={() => this.navigate(true, alternatives)}></button>
                        <div style={imgStyle} className="image"></div>
                        <button className="next-btn" onClick={() => this.navigate(false, alternatives)}></button>
                    </div>
                    <div className="indicators">
                        <span className={`indicator ${this.state.index === 0 && 'active'}`}></span>
                      {alternatives.map((_, i) => <span key={i}
                                                        className={`indicator ${this.state.index === i + 1 && 'active'}`}></span>)}
                    </div>
                </div> ||
                <div className="car-carousel no-options">
                    <div className="cars">
                        <div style={imgStyle} className="image"></div>
                    </div>
                </div>}
          </div>
        </div>
        <div className="cta-container">
          <div className="price">
            <span>{getRate(this.props.rate.pricing && this.props.rate.pricing.payNow.vehicleRentalAmount, this.props.currency, this.props.exchange)} {this.props.currency}</span>

            {this.props.rate.promoCode && this.props.rate.promoCode.code ?
              <PromoCodeInfo
                estimatedTotalAmount={this.props.rate.pricing && this.props.rate.pricing.payNow.totalAmount}
                discount={this.props.rate.promoCode.discount || this.props.rate.pricing && this.props.rate.pricing.payNow.discount}
                currency={this.props.currency}
                exchange={this.props.exchange}
              /> :
              null}
            <div className="vat-remark">{this.props.prismic.data.vat_remark_for_price}</div>
          </div>
          {!this.props.hideBookButton ? <div onClick={() => {
            let currentUrlParams = new URLSearchParams(window.location.search);
            currentUrlParams.set('apiKey', this.props.rate.apiKey);
            currentUrlParams.set('quoteID', this.props.rate.rateQuoteId);
            handleAddToCartEvent(true, this.props.rate, this.props.currency, true)
            this.props.clearCart() && this.props.history.push(`/${this.props.locale.slug}/vehicle?${currentUrlParams.toString()}`);
          }} className="book">
            {t('Book this car')}
          </div> : null}
        </div>
        <div className="extras">
          <div className="car-info">
            {this.state.current.driveType == 'AWD' || this.state.current.driveType == '4WD' ?
              (<div>
                <i className="icon icon-wheel"></i>
                {t(this.props.rate.driveType)}
              </div>) : ''
            }
            <div>
              <i className="icon icon-gearbox"></i>
              {this.state.current.transmissionAuto || this.state.current.transmissionType === 'A' ? t('A') : t('M')}
            </div>
            <div>
              <i className="icon icon-person"></i>
              {this.state.current.passengerCount}
            </div>
            <div>
              <i className="icon icon-luggage"></i>
              {this.state.current.baggageCount}
            </div>
            <div>
              <i className="icon icon-door"></i>
              {this.state.current.doorCount}
            </div>
            {(this.state.current.airConditioning || this.state.current.airCon) && (
              <div>
                <i className="icon icon-snowflake"></i>
                <i className="icon icon-check-dark-gray"></i>
              </div>
            )}
            {this.state.current.features && this.state.current.features.map(feat => (
              <div key={feat} data-tip={t('car-feature-' + feat)}>
                <i className={`icon icon-${feat}`}></i>
                <i className="icon icon-check-dark-gray"></i>
              </div>
            ))}
            <ReactTooltip effect="solid" type="info" offset={{top: 8}}/>
          </div>
        </div>
        <div className="description">
          <div className="description-item">
            <i className="icon icon-check-green"></i>
            {t(fuel[this.props.rate.fuelPolicy])} {t('Fuel policy')}
          </div>
          <div className="description-item">
            <i className="icon icon-check-green"></i>
            {mileageText}
          </div>
          {
            this.props.rate.inclusiveItems.filter(item => this.props.items[item.groupType] && this.getItem(this.props.items, item.groupType, item.otaCode)).map((item, i) => (
              <div key={i} className="description-item">
                <i className="icon icon-check-green"></i>
                <span>{this.getItem(this.props.items, item.groupType, item.otaCode).name[this.props.locale.rc_language_code]}</span>
              </div>
            ))
          }
        </div>
      </div>)
  }
}

export {Listing};
