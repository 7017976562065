import React, {Component} from 'react';
import {t} from '../conf/gettext';
import {NavBar} from '../components/NavBar';
import {SearchBar} from '../components/SearchBar';
import {Listing} from '../components/Listing';
import {AdditionalOption} from '../components/AdditionalOption';
import Cart from '../containers/Cart';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Loader} from '../components/Loader';
import {getCurrencyOrDefault, getLocaleOrDefault, sortExtras} from '../helpers';
import queryString from 'query-string';
import Footer from '../components/Footer';
import SkyScanner from '../helpers/skyscanner';
import {RichText} from 'prismic-reactjs';
import {handleBeginCheckoutEvent} from '../helpers/analyticsEvents';

const initialState = {}
type State = Readonly<typeof initialState>

export default class Confirm extends Component<RouteComponentProps<any>, State> {
  readonly state: State = initialState;
  quoteID: string | string[] = '';
  analyticsSent = false;

  constructor(props) {
    super(props);

    if (this.props.match.params.locale) {
      this.props.setLocale(getLocaleOrDefault(this.props.conf.enabled_languages, this.props.match.params.locale));
    }

    const qs = queryString.parse(this.props.location.search);
    if (qs['quoteID']) {
      this.quoteID = qs['quoteID']
    }
    if (qs['currency']) {
      this.props.setCurrency(getCurrencyOrDefault(this.props.conf.supported_currencies, qs['currency']));
    }
    if (qs['skyscanner_redirectid']) {
      const redirectId = qs['skyscanner_redirectid'];
      this.props.postSource(this.props.baseConf, this.quoteID, {source: qs['source'], value: redirectId});
      SkyScanner.setCookie(redirectId);
    }
  }

  componentWillReceiveProps(newProps) {
    this.fetchRateInfo(newProps);
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps !== this.props && this.props.rateInfo && !this.analyticsSent) {
      handleBeginCheckoutEvent(this.props.rateInfo, this.props.currency, this.props.cart);
      this.analyticsSent = true;
    }
  }

  componentDidMount() {
    this.fetchRateInfo(this.props);
    document.title = t('Extras') + ' | ' + this.props.prismic.data.meta_title;
  }

  componentWillUnmount() {
    document.title = this.props.prismic.data.meta_title;
  }

  fetchRateInfo(props) {
    const qs = queryString.parse(this.props.location.search);
    if (!this.state.fetchedRate && props.baseConf && Object.keys(props.baseConf).length) {
      this.props.fetchRateInfo(this.quoteID, props.baseConf.urls, this.props.history);
      this.setState({fetchedRate: true});
    }
  }

  getItem(items, itemGroupType, itemOtaCode) {
    return items[itemGroupType].filter((item) => item.otaCode === itemOtaCode)[0]
  }

  render() {
    if (!this.props.rateInfo || !this.props.countries || !this.props.baseConf) return <Loader/>;

    const extras = sortExtras(this.props.rateInfo.optionalItems.extras);

    return (
      <div>
        <NavBar prismic={this.props.prismic} setCurrency={this.props.setCurrency} setLocale={this.props.setLocale}
                currency={this.props.currency} exchange={this.props.exchange} locale={this.props.locale}
                conf={this.props.conf} prevRoute={t("results")}
                prevUrl={`/${this.props.locale.slug}${window.location.search}`} history={this.props.history}
                match={this.props.match}/>
        <SearchBar baseConf={this.props.baseConf} search={this.props.search} setSearch={this.props.setSearch}
                   countries={this.props.countries} rateInfo={this.props.rateInfo} history={this.props.history}
                   desks={this.props.desks} fetchRates={this.props.fetchRates} conf={this.props.conf}
                   locale={this.props.locale}/>
        <Listing
          locale={this.props.locale}
          rate={this.props.rateInfo}
          prismic={this.props.prismic}
          exchange={this.props.exchange}
          currency={this.props.currency}
          baseConf={this.props.baseConf}
          items={this.props.items}
          hideBookButton={true}
          carinfo={this.props.carinfo}
        />
        <div className="margin0_8">
          {this.props.rateInfo.optionalItems['extras'] ? <h3>Additional Options</h3> : ''}
          {
            extras.map((item, i) => {
              if (this.props.items && this.props.items['extras'] && this.getItem(this.props.items, 'extras', item.otaCode)) {
                return (
                  <div key={i}>
                    <AdditionalOption
                      item={Object.assign({}, item,
                        {
                          description: this.getItem(this.props.items, 'extras', item.otaCode)['description'][this.props.locale.rc_language_code],
                          name: this.getItem(this.props.items, 'extras', item.otaCode)['name'][this.props.locale.rc_language_code],
                          groupType: 'extras'
                        }
                      )}
                      addItem={(itm) => this.props.addItem(itm, this.props)}
                      removeItem={(itm) => this.props.removeItem(itm, this.props)}
                      cart={this.props.cart}
                      currency={this.props.currency}
                      exchange={this.props.exchange}/>
                  </div>
                )
              }
            })
          }
          <Cart/>
          <div className="margin8_0 flex-row align-center">
            <div className={`checkbox checkbox-sm ${this.props.terms ? 'checked' : ''}`}
                 onClick={() => this.props.toggleTerms(this.props.terms)}></div>
            <RichText render={this.props.prismic.data.terms_conditions_accept_text}/>
          </div>
          <div
            className="fine-print text-red margin8_0">{this.props.payOnline && this.props.prismic.data.extras_hint_pay_online || this.props.prismic.data.extras_hint}</div>
          <Link to={`/${this.props.locale.slug}/coverages${window.location.search}`}>
            <button className="btn btn-green margin8_0">{t('continue')}</button>
          </Link>
        </div>
        <Footer prismic={this.props.prismic}/>
      </div>
    );
  }
};
