import { Dispatch } from 'redux';
import types from '../types';

export function setLocale(locale) {
    return {
        type: types.SET_LOCALE,
        locale
    }
}

