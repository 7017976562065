import types from '../types';

const drivers = (state = [], action) => {
    switch (action.type) {
        case types.RECEIVE_DRIVER:
            return Object.assign({}, state,
                {
                    drivers: action.drivers
                }
            );
        default:
            return state
    }
}

export default drivers