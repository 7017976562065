import types from '../types';
import { defaultCurrency } from 'conf/env';

const currency = localStorage.getItem('currency') || defaultCurrency;
const cart = (state = { currency }, action) => {
    switch (action.type) {
        case types.SET_CURRENCY:
            return Object.assign({}, state, {currency: action.currency});
        case types.SET_EXCHANGE:
            return Object.assign({}, state, {exchange: action.exchange});
        default:
            return state
    }
}

export default cart