export default {
  RECEIVE_DESKS: 'RECEIVE_DESKS',
  REQUEST_DESKS: 'REQUEST_DESKS',
  REQUEST_RATES: 'REQUEST_RATES',
  RECEIVE_RATES: 'RECEIVE_RATES',
  REQUEST_RATE_INFO: 'REQUEST_RATE_INFO',
  RECEIVE_RATE_INFO: 'RECEIVE_RATE_INFO',
  REMOVE_ITEM: 'REMOVE_ITEM',
  CLEAR_CART: 'CLEAR_CART',
  ADD_ITEM: 'ADD_ITEM',
  RESTORE_CART: 'RESTORE_CART',
  TOGGLE_PAY_ONLINE: 'TOGGLE_PAY_ONLINE',
  REQUEST_RESERVATION: 'REQUEST_RESERVATION',
  RECEIVE_RESERVATION: 'RECEIVE_RESERVATION',
  CONFIRM_RESERVATION: 'CONFIRM_RESERVATION',
  RECEIVE_PRISMIC: 'RECEIVE_PRISMIC',
  RECEIVE_ITEMS: 'RECEIVE_ITEMS',
  REQUEST_ITEMS: 'REQUEST_ITEMS',
  TOGGLE_TERMS: 'TOGGLE_TERMS',
  RECEIVE_DRIVER: 'RECEIVE_DRIVER',
  SET_DETAIL: 'SET_DETAIL',
  SET_SEARCH: 'SET_SEARCH',
  SET_CURRENCY: 'SET_CURRENCY',
  SET_EXCHANGE: 'SET_EXCHANGE',
  SET_LOCALE: 'SET_LOCALE',
  RECEIVE_HOTEL: 'RECEIVE_HOTEL',
  REQUEST_HOTEL: 'REQUEST_HOTEL',
  REQUEST_COUNTRIES: 'REQUEST_COUNTRIES',
  RECEIVE_COUNTRIES: 'RECEIVE_COUNTRIES',
  REQUEST_CONFIG: 'REQUEST_CONFIG',
  RECEIVE_CONFIG: 'RECEIVE_CONFIG',
  POST_SOURCE_REQUEST: 'POST_SOURCE_REQUEST',
  POST_SOUCE_SUCCESS: 'POST_SOURCE_SUCCESS',
  POST_SOURCE_FAIL: 'POST_SOURCE_FAIL',
  REQUEST_CARINFO: 'REQUEST_CARINFO',
  RECEIVE_CARINFO: 'RECEIVE_CARINFO',
  REQUEST_OPENING_TIMES: 'REQUEST_OPENING_TIMES',
  RECEIVE_OPENING_TIMES: 'RECEIVE_OPENING_TIMES'
};
