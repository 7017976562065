import React, {Component} from 'react';
import moment from 'moment';
import {NavBar} from '../components/NavBar';
import {SearchBar} from '../components/SearchBar';
import {Listing} from '../components/Listing';
import {warningMessages} from '../conf/values';
import queryString from 'query-string';
import Footer from '../components/Footer';
import {filterPromoCodeRates, getCurrencyOrDefault, getLocaleOrDefault} from '../helpers';

import {t} from '../conf/gettext';
import {handleViewItemListEvent} from '../helpers/analyticsEvents';

const defaultValues: any = {};
const initialState = {
  defaultValues,
  pickUpDate: moment().add(1, 'days'),
  dropOffDate: moment().add(5, 'days'),
  acrissArray: [],
  hideRest: null
};
type State = Readonly<typeof initialState>

export default class Home extends Component<any, State> {
  readonly state: State = initialState;

  componentDidMount() {
    const defaultValues: any = {};
    const qs = queryString.parse(this.props.location.search);
    const defaultTime = this.props.conf.default_pu_time ? this.props.conf.default_pu_time : '11:00';
    if (this.props.match.params.locale) {
      this.props.setLocale(getLocaleOrDefault(this.props.conf.enabled_languages, this.props.match.params.locale));
    }
    // temporary fix
    const map = {
      puLocation: 'pickUpDesk',
      doLocation: 'dropOffDesk',
      country: 'country',
      driverAge: 'age',
      puDate: 'pickUpDate',
      doDate: 'dropOffDate',
      promo: 'promoCode',
      cars: 'cars',
      hideRest: 'hideRest',
      apiKey: 'apiKey',
      partnerCode: 'partnerCode'
    };

    let url = `${this.props.locale.slug}/prices?`;

    if (qs['currency']) {
      url += `currency=${qs['currency']}`;
      this.props.setCurrency(getCurrencyOrDefault(this.props.conf.supported_currencies, qs['currency']));
    } else {
      url += `currency=${this.props.currency}`;
    }
    if (qs['cars']) {
      const acrissArray = qs['cars'].split(',');
      url += '&cars=' + qs['cars'];
      this.setState({
        acrissArray,
      });
      defaultValues.acrissArray = acrissArray;
    }
    if (qs['hideRest']) {
      const hideRest = qs['hideRest'] === 'true';
      if (hideRest) {
        url += '&hideRest=' + qs['hideRest'];
      }
      this.setState({
        hideRest
      });
      defaultValues.hideRest = hideRest;
    }
    if (qs['partnerCode']) {
      url += '&partnerCode=' + qs['partnerCode'];
      defaultValues.partnerCode = qs['partnerCode'];
    }
    if (qs['apiKey']) {
      url += '&apiKey=' + qs['apiKey'];
      defaultValues.apiKey = qs['apiKey'];
    }
    if (this.props.conf.default_pu_time) {
      defaultValues.pickUpTime = this.props.conf.default_pu_time;
      defaultValues.dropOffTime = this.props.conf.default_pu_time;
    }

    if (this.props.conf.default_pu_location_code) {
      defaultValues.pickUpDesk = this.props.conf.default_pu_location_code;
    }
    const ages = this.props.conf.driver_age_ranges;
    if (Object.keys(qs).length) {
      Object.keys(qs).forEach(ref => {
        const indexRef = map[ref];
        if (['pickUpDesk', 'dropOffDesk', 'country', 'age', 'promoCode'].indexOf(indexRef) != -1) {
          defaultValues[indexRef] = qs[ref];
          if (indexRef === 'age') {
            const queryAge = parseInt(qs[ref]);
            for (const range of [...ages].reverse()) {
              if (queryAge >= parseInt(range.driver_age_range_min)) {
                defaultValues[indexRef] = range.driver_age_range_min;
                break;
              }
            }
          }

          if (qs[ref]) {
            url += `&${ref}=${qs[ref]}`;
          }
        }

        if (['dropOffDate', 'pickUpDate'].indexOf(indexRef) != -1) {
          const date = moment(qs[ref]);

          if (date) {
            defaultValues[indexRef] = date;
            url += `&${ref}=${qs[ref]}`;
          }
        }
      });
    } else {
      defaultValues['searchOpen'] = true;
    }

    if (!defaultValues.pickUpDate) {
      defaultValues.pickUpDate = moment().add(1, 'days');
      defaultValues.pickUpDate.hours(defaultTime.split(':')[0]);
      defaultValues.pickUpDate.minutes(defaultTime.split(':')[1]);
    }

    if (!defaultValues.dropOffDate) {
      defaultValues.dropOffDate = moment().add(5, 'days');
      defaultValues.dropOffDate.hours(defaultTime.split(':')[0]);
      defaultValues.dropOffDate.minutes(defaultTime.split(':')[1]);
    }

    if (qs['daysToStart'] && !qs['puDate'] && !isNaN(qs['daysToStart'])) {
      defaultValues.pickUpDate = moment(this.state.pickUpDate).add(Number(qs['daysToStart']), 'days');
      defaultValues.dropOffDate = moment(defaultValues.pickUpDate).add(3, 'days');
      url += `&puDate=${defaultValues.pickUpDate}&doDate=${defaultValues.dropOffDate}`;
    }

    if (qs['puDate'] && !qs['doDate'] && qs['rentalDuration'] && !isNaN(qs['rentalDuration'])) {
      defaultValues.dropOffDate = moment(qs['puDate']).add(qs['rentalDuration'], 'days');
      url += `&doDate=${defaultValues.dropOffDate}`;
    }

    if (!defaultValues.country) {
      fetch('https://ipapi.co/json/')
        .then(
          res => res.json()
        ).then(
        json => {
          if (json.country) {
            defaultValues.country = json.country;
            this.setState({defaultValues});
          }
        }
      ).catch(err => this.setState({
        defaultValues: {
          ...defaultValues,
          country: this.props.conf.default_country_of_residency || 'GB'
        }
      }));
    }

    this.setState({defaultValues});
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.rates.rates && (this.props.rates.rates != prevProps.rates.rates)) {
      handleViewItemListEvent(this.props.rates.rates);
    }
  }

  render() {
    if (!Object.keys(this.state.defaultValues).length || !this.props.countries || !this.props.baseConf) return null;
    let rates = this.props.rates && this.props.rates.rates;
    const acrissArray = this.state.acrissArray;
    if (rates && acrissArray && acrissArray.length) {
      if (this.state.hideRest) {
        rates = rates.filter(r => acrissArray.indexOf(r.acrissCode) !== -1);
      }
      rates.sort((a, b) => {
        const aInArr = acrissArray.indexOf(a.acrissCode) !== -1;
        const bInArr = acrissArray.indexOf(b.acrissCode) !== -1;
        if (aInArr && !bInArr) {
          return -1;
        }
        if (bInArr && !aInArr) {
          return 1;
        }
        return a.estimatedTotalAmount - b.estimatedTotalAmount;
      });
    }
    let discountCars = [];
    let otherCars = [];
    if (rates) {
      if (this.props.search.promoCode || this.state.defaultValues.promoCode) {
        discountCars = filterPromoCodeRates(rates, null, true).map((rate, i) => (
          <div key={rate.vehicleId + rate.productCode} className="listing-container">
            <Listing carinfo={this.props.carinfo} prismic={this.props.prismic} clearCart={this.props.clearCart}
                     locale={this.props.locale} exchange={this.props.exchange} currency={this.props.currency}
                     rate={rate} items={this.props.items} history={this.props.history}/>
          </div>
        ));
        if (!this.state.hideRest) {
          otherCars = filterPromoCodeRates(rates, null, false).map((rate, i) => (
            <div key={rate.vehicleId + rate.productCode} className="listing-container">
              <Listing carinfo={this.props.carinfo} prismic={this.props.prismic} clearCart={this.props.clearCart}
                       locale={this.props.locale} exchange={this.props.exchange} currency={this.props.currency}
                       rate={rate} items={this.props.items} history={this.props.history}/>
            </div>
          ));
        }
      } else {
        discountCars = rates.map((rate, i) => (
          <div key={rate.vehicleId + rate.productCode} className="listing-container">
            <Listing carinfo={this.props.carinfo} prismic={this.props.prismic} clearCart={this.props.clearCart}
                     locale={this.props.locale} exchange={this.props.exchange} currency={this.props.currency}
                     rate={rate} items={this.props.items} history={this.props.history}/>
          </div>
        ));
      }
    }
    return (
      <div className="background">
        <NavBar prismic={this.props.prismic} setCurrency={this.props.setCurrency} setLocale={this.props.setLocale}
                currency={this.props.currency} exchange={this.props.exchange} locale={this.props.locale}
                conf={this.props.conf} match={this.props.match}/>
        <SearchBar baseConf={this.props.baseConf} search={this.props.search} setSearch={this.props.setSearch}
                   minimalSearch={true} countries={this.props.countries} desks={this.props.desks}
                   fetchRates={this.props.fetchRates} defaultValues={this.state.defaultValues}
                   locale={this.props.locale} conf={this.props.conf} location={this.props.location}
                   currency={this.props.currency}/>
        <div className="results">
          {this.props.rates.isFetching ? [<div key="1" className="listing-loading margin8_0"></div>,
            <div key="2" className="listing-loading margin8_0"></div>,
            <div key="3" className="listing-loading margin8_0"></div>] : ''}
          {rates && this.props.rates.message == 'OK' ?
            <>
              {discountCars}
              {otherCars}
            </> : ''
          }
          {rates && this.props.rates.message == 'OK' && !otherCars.length && this.state.hideRest ?
            <div className={!discountCars.length && !otherCars.length && 'listing-error' || 'listing-show-more'}>
              {!discountCars.length && <h2 className="text-blue text-center">{
                this.state.acrissArray.length && t('Sorry, this car is not available for selected time period') ||
                (this.props.search.promoCode || this.state.defaultValues.promoCode) && t('Campaign offers for this period are sold out or expired')
              }</h2> || null}
              <a
                tabIndex={-1}
                className="text-center"
                onClick={() => this.setState({hideRest: false, acrissArray: []})}
              >{t('Show all cars for this time period')}</a>
            </div> : null
          }
          {
            this.props.rates.message && this.props.rates.message != 'OK' ?
              <div className="listing-error">
                <h2
                  className="text-blue text-center">{t(warningMessages[this.props.rates.code]) || this.props.rates.message}</h2>
                {(this.props.rates.code === 30101 || this.props.rates.code === 30102) && this.props.openingTimes ?
                  <div className="text-center">
                    <h3>{t('Opening times at selected date:')}</h3>
                    <ul style={{listStyle: 'none'}}>
                      {this.props.openingTimes.open1 ?
                        <li>{this.props.openingTimes.open1} - {this.props.openingTimes.close1}</li> : null}
                      {this.props.openingTimes.open2 ?
                        <li>{this.props.openingTimes.open2} - {this.props.openingTimes.close2}</li> : null}
                      {this.props.openingTimes.open3 ?
                        <li>{this.props.openingTimes.open3} - {this.props.openingTimes.close3}</li> : null}
                    </ul>
                  </div> : null}
              </div> : ''
          }
          {
            this.props.rates && this.props.rates.message == 'OK' ?
              <Footer prismic={this.props.prismic}/> : ''
          }
        </div>
      </div>
    );
  }
};
