import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

const initialState = {};
type State = Readonly<typeof initialState>

class ScrollToTop extends Component<any, State> {
    readonly state: State = initialState;

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}

export default withRouter(ScrollToTop);