import { Dispatch } from 'redux';
import types from '../types';

export function toggleTerms(terms) {
    return {
        type: types.TOGGLE_TERMS,
        terms: !terms
    }
}

