import {Dispatch} from 'redux';
import {t} from 'conf/gettext';
import fetch from 'cross-fetch';
import types from '../types';
import {fetchOpeningTimes} from './desks';
import queryString from 'query-string';

export interface data {
  currency: string,
  driverAge: number,
  pickupDate: string,
  pickupDeskCode: string,
  returnDate: string,
  returnDeskCode: string,
  sourceCountry: string
};

export function requestRates() {
  return {
    type: types.REQUEST_RATES,
    rates: null,
    isFetching: true,
  }
}

export function receiveRates(rates: any) {
  if (rates.rates && rates.rates.sort) {
    rates.rates = rates.rates.sort((a, b) => {
      if (a.rateTotalAmount < b.rateTotalAmount) {
        return -1;
      } else if (a.rateTotalAmount > b.rateTotalAmount) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  return {
    type: types.RECEIVE_RATES,
    rates: rates,
    isFetching: false,
  }
}

export function fetchRates(body, conf, apiKeyOverride?: string): (dispatch: Dispatch<any, any>) => void {
  if (!conf) return null;
  return function (dispatch: Dispatch<any, any>) {
    dispatch(requestRates());
    let qs = '?';
    for (let key in body) {
      qs += `${key}=${body[key]}&`;
    }
    if (conf.enableMultipleProducts && !apiKeyOverride) {
      fetchRatesOfMultipleProducts(dispatch, body, conf, qs, apiKeyOverride);
    } else {
      fetchRatesOfSingleProduct(dispatch, body, conf, qs, apiKeyOverride);
    }

  }
}

function fetchRatesOfSingleProduct(dispatch, body, conf, qs, apiKeyOverride?: string) {
  const apiKey = apiKeyOverride || conf.apiKeys.reservationsAPI;
  fetch(`${conf.urls.reservationsAPI}/rates${qs}`,
    {method:'GET',
    headers: {
      "X-API-KEY": apiKey
    }})
    .then(res => {
      const json = res.json();
      if (res.status >= 400) {
        return json.then(Promise.reject.bind(Promise));
      }
      return json;
    })
    .then(json => {
      if (json.code === '30101') {
        dispatch(fetchOpeningTimes(conf.urls, apiKey, body.pickupDeskCode, body.pickupDate.substring(0, 10)));
      } else if (json.code === '30102') {
        dispatch(fetchOpeningTimes(conf.urls, apiKey, body.returnDeskCode, body.returnDate.substring(0, 10)));
      }
      dispatch(receiveRates(json))
    })
    .catch(err => {
      dispatch(receiveRates({
          rates: [],
          message: t('Something went wrong, please try again later')
        })
      );
    });
}

function fetchRatesOfMultipleProducts(dispatch, body, conf, qs, apiKeyOverride?: string) {
  const apiKey = apiKeyOverride || conf.apiKeys.reservationsAPI;
  const products = conf.products;
  Promise.all(products.map(p => fetch(`${conf.urls.reservationsAPI}/rates${qs}`, {
    method: 'GET',
    headers: {
      "X-API-KEY": p.apiKey
    }
  })))
    .then(
      (res) => Promise.all(res.map((r: any) => r.json()))
    )
    .then(
      (res) => {
        const rates = [];
        let successfulResponse = null;
        res.forEach(r => {
          rates.push(...r.rates)
          if (r.code === '30101') {
            dispatch(fetchOpeningTimes(conf.urls, apiKey, body.pickupDeskCode, body.pickupDate.substring(0, 10)));
          } else if (r.code === '30102') {
            dispatch(fetchOpeningTimes(conf.urls, apiKey, body.returnDeskCode, body.returnDate.substring(0, 10)));
          } else if (!r.code) {
            successfulResponse = r;
          }
        })
        dispatch(receiveRates({...(successfulResponse || res[0]), rates}))
      }
    )
    .catch(
      (err) => dispatch(receiveRates({
          rates: [],
          message: t('Something went wrong, please try again later')
        })
      )
    );
}

// export const postSource = (conf, rateQuoteId, body) => {
//   return async dispatch => {
//     try {
//       dispatch({type: types.POST_SOURCE_REQUEST});
//       const qs = queryString.parse(window.location.search);
//       await fetch(`${conf.urls.reservationsAPI}/source/${qs['apiKey'] || conf.apiKeys.reservationsAPI}/${rateQuoteId}`, {
//         method: 'POST',
//         body: JSON.stringify(body),
//         headers: {
//           "Content-Type": "application/json"
//         },
//       });
//       dispatch({type: types.POST_SOUCE_SUCCESS});
//     } catch (e) {
//       dispatch({type: types.POST_SOURCE_FAIL});
//     }
//
//   }
// }
