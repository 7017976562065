import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {withRouter} from 'react-router';
import {restoreCart} from '../../actions/cart';
import {cancelReservation, createReservation, postRetryPayment} from '../../actions/reservation';
import {setDetails} from '../../actions/details';
import DetailsView from '../../views/Details';
import {toggleTerms} from '../../actions/terms';
import {getPrismic} from '../../helpers';
import {setCurrency} from '../../actions/currency';
import {setLocale} from '../../actions/locale';
import {fetchRateInfo} from '../../actions/rateinfo';


export interface reservation {
  carModel: string,
  coverages: [
    {
      otaCode: string,
    }
  ],
  customer: {
    arriveByPlane: boolean,
    comments: string,
    email: string,
    firstName: string,
    lastName: string,
    flightNumber: string,
    phone: string,
  },
  extras: [
    {
      otaCode: string,
      qunatity: number,
    }
  ],
  gateway: string,
  paymentCancelUrl: string,
  paymentNotifyUrl: string,
  paymentOkUrl: string,
  token: string,

}

const mapStateToProps = state => {
  return {
    reservation: state.reservation,
    rateInfo: state.rateInfo.rateinfo,
    cart: state.cart.items,
    payOnline: state.cart.payOnline,
    terms: state.terms.terms || false,
    prismic: getPrismic(state.prismic.prismic, state.locale.locale),
    details: state.details,
    currency: state.currency.currency,
    exchange: state.currency.exchange,
    locale: state.locale.locale,
    conf: state.prismic.prismic && state.prismic.prismic.results.find(r => r.type == 'configuration').data,
    countries: state.countries.countries && state.countries.countries.length ? state.countries.countries.sort((a, b) => {
      let cA = a.name[state.locale.locale.rc_language_code];
      cA = cA ? cA.toUpperCase() : 'undefined';
      let cB = b.name[state.locale.locale.rc_language_code];
      cB = cB ? cB.toUpperCase() : 'undefined';
      return (cA < cB) ? -1 : (cA > cB) ? 1 : 0;
    }) : [],
    baseConf: state.config.config
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any, any>) => {
  return {
    createReservation: (reservation: reservation, conf, reservationData) => {
      dispatch(createReservation(reservation, conf, reservationData))
    },
    fetchRateInfo: (hash: string, urls, history = null) => {
      dispatch(fetchRateInfo(hash, urls, history))
    },
    restoreCart: () => dispatch(restoreCart()),
    cancelReservation: () => dispatch(cancelReservation()),
    retryPayment: (reservationNumber, requestBody, conf) => dispatch(postRetryPayment(reservationNumber, requestBody, conf)),
    toggleTerms: (terms) => {
      dispatch(toggleTerms(terms))
    },
    setDetails: (obj: {}) => {
      dispatch(setDetails(obj))
    },
    setCurrency: (currency) => {
      dispatch(setCurrency(currency))
    },
    setLocale: (locale) => {
      dispatch(setLocale(locale))
    },
  }
}

export default withRouter(connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(DetailsView));
