import React, {Component} from 'react';
import {t} from '../../../conf/gettext';
import {Link, withRouter} from 'react-router-dom';
import {fuel, mileage, vehicleTypes} from '../../../conf/values';
import {getRate} from '../../../helpers';
import PromoCodeInfo from '../../Listing/PromoCodeInfo';
import ReactTooltip from 'react-tooltip';
import {handleAddToCartEvent} from '../../../helpers/analyticsEvents';

const initialState = {
  current: null
};

type State = Readonly<typeof initialState>

class Listing extends Component<any, State> {
  readonly state: State = initialState;

  render() {

    const daysString = this.props.rate.rentalDays === 1 ? t('day') : this.props.rate.rentalDays < 5 ? t('days (2-4)') : t('days');
    return (
      <div className="listing">
        <CarDetails rate={this.props.rate} carinfo={this.props.carinfo} items={this.props.items}
                    locale={this.props.locale}/>
        <div className="booking">
          <div className="confirmation-time">
            <i
              className={`icon ${this.props.rate.rateStatus == 'FREE_SELL' ? 'icon-check-green' : 'icon-time text-blue'}`}></i>{this.props.rate.rateStatus == 'FREE_SELL' ? t('Immediate confirmation') : t('On request')}
          </div>
          {this.props.rate.promoCode && this.props.rate.promoCode.code ?
            <div className="promo-code-label-container">
              <span className="promo-code-label">PROMO OFFER</span>
            </div> : null}
          <div className="price">
            <div className="price-info">
              {t('for')}
              <b>{this.props.rate.rentalDays} {daysString}</b> - <b>{getRate((this.props.rate.pricing.payNow.vehicleRentalAmount / this.props.rate.rentalDays), this.props.currency, this.props.exchange)} {this.props.currency}</b> {t('per day')}
            </div>
            <span>{getRate(this.props.rate.pricing.payNow.vehicleRentalAmount, this.props.currency, this.props.exchange)} {this.props.currency}</span>
            {this.props.rate.mandatoryItems
              .map((item, i) => (
                <div key={i} className="price-info">
                  plus <b>{this.getItem(this.props.items, 'fees', item.otaCode).name[this.props.locale.rc_language_code]}</b> {getRate(item.price, this.props.currency, this.props.exchange)} {this.props.currency}
                </div>
              ))
            }
            {this.props.rate.promoCode && this.props.rate.promoCode.code ?
              <PromoCodeInfo
                estimatedTotalAmount={this.props.rate.pricing.payNow.totalAmount}
                discount={this.props.rate.pricing.payNow.discount}
                currency={this.props.currency}
                exchange={this.props.exchange}
              /> :
              null}
            <div className="vat-remark">{this.props.prismic.data.vat_remark_for_price}</div>
          </div>
          <div className="book" onClick={() => {
            let currentUrlParams = new URLSearchParams(window.location.search);
            handleAddToCartEvent(true, this.props.rate, this.props.currency, true);
            currentUrlParams.set('apiKey', this.props.rate.apiKey);
            currentUrlParams.set('quoteID', this.props.rate.rateQuoteId)
            this.props.clearCart() && this.props.history.push(`/${this.props.locale.slug}/vehicle?${currentUrlParams.toString()}`)
          }}>
            <div>
              <Link to={`/${this.props.locale.slug}/vehicle${window.location.search}`} >
                {t('Book this car')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getItem(items, itemGroupType, itemOtaCode) {
    return items[itemGroupType].filter((item) => item.otaCode === itemOtaCode)[0]
  }
};

class CarDetails extends Component<any, any> {

  constructor(props) {
    super(props);

    this.state = {
      current: props.rate,
      index: 0
    }
  }

  navigate(isBack, alternatives = []) {
    let nextCar;
    let nextIndex;
    const index = this.state.index;
    if (index === 0 && isBack) {
      nextCar = alternatives[alternatives.length - 1];
      nextIndex = alternatives.length;
    } else if (index === alternatives.length && !isBack) {
      nextIndex = 0;
      nextCar = this.props.rate;
    } else {
      nextIndex = isBack ? index - 1 : index + 1;
      nextCar = nextIndex === 0 ? this.props.rate : alternatives[nextIndex - 1];
    }
    this.setState({
      index: nextIndex,
      current: nextCar
    })
  }

  getInclusiveItems() {
    return (this.props.rate.inclusiveItems.length && Object.keys(this.props.rate.inclusiveItems)
        .map(key => this.props.rate.inclusiveItems[key])
        .filter(item => this.props.items && this.props.items[item.groupType] && this.getItem(this.props.items, item.groupType, item.otaCode))
        .map((item, i) => {
            return (
              <div key={i}>
                <i className="icon icon-check-green"></i>
                <span>{this.getItem(this.props.items, item.groupType, item.otaCode)["name"][this.props.locale.rc_language_code]}</span>
              </div>
            )
          }
        )
    ) || '';
  }


  getItem(items, itemGroupType, itemOtaCode) {
    return items[itemGroupType].filter((item) => item.otaCode === itemOtaCode)[0]
  }

  render() {
    const mileageText = this.props.rate.mileagePolicy.mileageTypeUnlimited ?
      t('Unlimited mileage') :
      `${this.props.rate.mileagePolicy.mileageQuantity} ${t(mileage[this.props.rate.mileagePolicy.mileageUnitType.toLowerCase()])} ${t(mileage[this.props.rate.mileagePolicy.mileageUnitPeriodType.toLowerCase()])}`;

    let driveType = null;

    if (this.state.current.driveType == '4WD') {
      driveType = t('4WD');
    }

    if (this.state.current.driveType == 'AWD') {
      driveType = t('AWD');
    }
    const imgStyle = {backgroundImage: `url(${this.state.current.image.original || this.state.current.imageUrl})`};
    const alternatives = this.props.carinfo && this.props.carinfo.cars && this.props.carinfo.cars[this.props.rate.vehicleId];
    const daysString = this.props.rate.rentalDays === 1 ? t('day') : this.props.rate.rentalDays < 5 ? t('days (2-4)') : t('days');
    return (
      <div className="main">
        <div className="header flex-row justify-space-between">
          <div>
            <div className="size-class-info">
              <span
                className="text-bold">{t(this.props.rate.size && vehicleTypes[this.props.rate.size] || this.props.rate.size)}</span>
              {this.props.rate.isGuaranteedModel &&
                  <span className="description">{t('Guaranteed model, you will get this exact car!')}</span>
                ||
                alternatives && alternatives.length &&
                  <span className="description">{t('You will get one of these cars:')}</span>
                ||
                  <span className="description">{t('or similar')}</span>}
            </div>
            <div className="info">
              <div className="type-info">
                <div className="size-class">
                  {this.state.current.displayName || this.state.current.model}
                </div>
              </div>
            </div>
            {this.props.rate.isGuaranteedModel &&
                <span className="guaranteed-model-label">GUARANTEED MODEL</span> || null}
          </div>

          {this.props.showPrice ? <div className="text-right">
            <div className="text-blue text-xl text-bold">
              {getRate(this.props.rate.pricing.payNow.vehicleRentalAmount, this.props.currency, this.props.exchange)} {this.props.currency}
            </div>
            <div className="text-md text-dark-gray">
              {t('for')} {this.props.rate.rentalDays} {daysString} {getRate((this.props.rate.pricing.payNow.vehicleRentalAmount / this.props.rate.rentalDays), this.props.currency, this.props.exchange)} {this.props.currency} {t('per day')}
            </div>
          </div> : null}
        </div>
        <div className="body">
          {alternatives && alternatives.length &&
              <div className="car-carousel">
                  <div className="cars">
                      <button className="prev-btn" onClick={() => this.navigate(true, alternatives)}></button>
                      <div style={imgStyle} className="image"></div>
                      <button className="next-btn" onClick={() => this.navigate(false, alternatives)}></button>
                  </div>
                  <div className="indicators">
                      <span className={`indicator ${this.state.index === 0 && 'active'}`}></span>
                    {alternatives.map((_, i) => <span key={i}
                                                      className={`indicator ${this.state.index === i + 1 && 'active'}`}></span>)}
                  </div>
              </div> ||
              <div className="car-carousel no-options">
                  <div className="cars">
                      <div style={imgStyle} className="image"></div>
                  </div>
              </div>}
          <div className="body-info">
            <div className="car-info">
              {driveType ? (
                <div>
                  <i className="icon icon-wheel"></i>
                  {driveType}
                </div>
              ) : ''}
              <div>
                <i className="icon icon-gearbox"></i>
                {this.state.current.transmissionAuto || this.state.current.transmissionType === 'A' ? t('A') : t('M')}
              </div>
              <div>
                <i className="icon icon-person"></i>
                {this.state.current.passengerCount}
              </div>
              <div>
                <i className="icon icon-luggage"></i>
                {this.state.current.baggageCount}
              </div>
              <div>
                <i className="icon icon-door"></i>
                {this.state.current.doorCount}
              </div>
              {(this.state.current.airConditioning || this.state.current.airCon) && (
                <div>
                  <i className="icon icon-snowflake"></i>
                  <i className="icon icon-check-dark-gray"></i>
                </div>
              )}
              {this.state.current.features && this.state.current.features.map(feat => (
                <div key={feat} data-tip={t('car-feature-' + feat)}>
                  <i className={`icon icon-${feat}`}></i>
                  <i className="icon icon-check-dark-gray"></i>
                </div>)
              )}
              <ReactTooltip effect="solid" type="info" offset={{top: 8}}/>
            </div>
            <div className="extras">
              <h4>{this.props.rate.inclusiveItems.length ? t('Included in price') : ''}</h4>
              <div className="extra-item">
                <div>
                  <i className="icon icon-check-green"></i>
                  {t(fuel[this.props.rate.fuelPolicy])} {t('Fuel policy')}
                </div>
                <div>
                  <i className="icon icon-check-green"></i>
                  {mileageText}
                </div>
                {
                  this.getInclusiveItems()
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export {CarDetails};

export default withRouter(Listing);
