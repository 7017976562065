import types from '../types';

const carinfo = (state = { cars: {} }, action) => {
    switch (action.type) {
        case types.REQUEST_CARINFO:
            return state;
        case types.RECEIVE_CARINFO:
            return Object.assign({}, state,
                {
                    cars: action.items
                }
            );
        default:
            return state
    }
}

export default carinfo;
