import React, {Component, RefObject} from 'react';
import ReactTelephoneInput from 'react-telephone-input';
import moment from 'moment';
import {NavBar} from '../../components/Desktop/NavBar';
import {Loader} from '../../components/Loader';
import {t} from '../../conf/gettext';
import {getLocaleOrDefault} from '../../helpers';
import queryString from 'query-string';
import {monthsShort} from '../../conf/values';
import Footer from '../../components/Footer';

const initialState = {
  errors: {},
  driverPhone: null,
  lname: null,
  ref: null,
  reservationNumber: null,
  opacity: 1,
  noExpiry: null
};
type State = Readonly<typeof initialState>

export default class InfoUpdate extends Component<any, State> {
  readonly state: State = initialState;
  driver: any;
  reservationNumber: RefObject<any>;
  lastName: RefObject<any>;

  blurTimeout;

  constructor(props) {
    super(props);

    if (this.props.match.params.locale) {
      this.props.setLocale(getLocaleOrDefault(this.props.conf.enabled_languages, this.props.match.params.locale));
    }

    this.reservationNumber = React.createRef();
    this.lastName = React.createRef();
    this.driver = {
      firstName: React.createRef(),
      lastName: React.createRef(),
      email: React.createRef(),
      address: React.createRef(),
      cityOfResidence: React.createRef(),
      countryCode: React.createRef(),
      gender: React.createRef(),
      age: React.createRef(),
      license: {
        firstName: React.createRef(),
        lastName: React.createRef(),
        nr: React.createRef(),
        expiryYY: React.createRef(),
        expiryMM: React.createRef(),
      },
      additionalDrivers: {},
    }

    const qs = queryString.parse(this.props.location.search);
    const state = {};
    if (qs['ref']) {
      this.setState({ref: qs.ref})
    }

    if (qs['lname']) {
      this.setState({lname: qs.lname});
    }

    if (qs['ref'] && qs['lname']) {
      this.fetchReservation();
    }

  }

  componentDidMount() {
    window.addEventListener('focus', this.handleFocus, true);
    window.addEventListener('blur', this.handleFocus, true);
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.handleFocus, true);
    window.removeEventListener('blur', this.handleFocus, true);
  }

  handleFocus(event: FocusEvent) {
    if (event.target instanceof HTMLInputElement) {
      if (event.type === 'focus') {
        if (!document.body.className || document.body.className.indexOf('kb-open') === -1) {
          document.body.className += ' kb-open';
        }
        if (this.blurTimeout) {
          clearTimeout(this.blurTimeout);
          this.blurTimeout = null;

        }
      } else {
        this.blurTimeout = setTimeout(() => {
          document.body.className = document.body.className.replace(' kb-open', '');
          this.blurTimeout = null;
        }, 100);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.reservation.reservation && !this.props.reservation.reservation.length && nextProps.reservation.reservation && Object.keys(nextProps.reservation.reservation).length) {
      const state = {};
      if (nextProps.reservation.reservation.driver.expiry === 'noExp') {
        state['noExpiry'] = true;
      } else {
        state['noExpiry'] = false;
      }

      nextProps.reservation.reservation.additionalDrivers.forEach((driver, i) => {
        if (driver.expiry === 'noExp') {
          state[`noExpiry_${i}`] = true;
        }
      });

      this.setState(state);
    }
  }

  sanitizeAge() {
    this.driver.age.current.value = this.driver.age.current.value.replace(/[^0-9]/g, '');
  }

  fetchReservation() {
    const reservationNumber = (this.reservationNumber.current && this.reservationNumber.current.value) || this.state.ref;
    const lname = (this.lastName.current && this.lastName.current.value) || this.state.lname;
    if (reservationNumber && reservationNumber.trim() && lname && lname.trim()) {
      this.setState({reservationNumber: reservationNumber.trim()})
      this.props.fetchModifyReservation({
        lastName: lname.trim(),
        reservationNumber: reservationNumber.trim(),
      }, this.props.baseConf);
    }
  }

  cancel() {
    this.props.receiveModifyReservation();
  }

  validate(key, value) {
    const errors = {...this.state.errors};
    const ageRanges = this.props.conf.driver_age_ranges.sort((a, b) => Number(a.driver_age_range_min) - Number(b.driver_age_range_min))
    const minDriverAge = parseInt(ageRanges[0].driver_age_range_min);
    const maxDriverAge = parseInt(ageRanges[ageRanges.length - 1].driver_age_range_max) || 100;
    if (key.indexOf('expiry') == -1 && value && !value.trim()) {
      errors[key] = true;
    } else if (key.indexOf('expiryMM') != -1 && !this.state.noExpiry && (!parseInt(value) || parseInt(value) > 12 || parseInt(value) < 1)) {
      errors[key] = true;
    } else if (key.indexOf('expiryYY') != -1 && !this.state.noExpiry && (!parseInt(value) || 2000 + parseInt(value) < moment().year())) {
      errors[key] = true;
    } else if (key == 'email' && value.indexOf('@') == -1) {
      errors[key] = true;
    } else if (key == 'age' && (isNaN(value) || minDriverAge && Number(value) < minDriverAge || maxDriverAge && Number(value) > maxDriverAge)) {
      errors[key] = true;
    } else if (key == 'phone' && (!value || value.length < 5 || !value.match(/^[0-9\(\)\+\-\s]+$/))) {
      errors[key] = true;
    } else if (!value || !value.trim()) {
      errors[key] = true;
    } else {
      errors[key] = false;
    }

    this.setState({errors});
  }

  save() {
    const errors = {};
    let driverPhone = this.state.driverPhone || this.props.reservation.reservation.customer.phone;

    if (driverPhone.length > 4) {
      driverPhone = driverPhone.replace(/[^0-9\+]/g, '');
    }
    const ageRanges = this.props.conf.driver_age_ranges.sort((a, b) => Number(a.driver_age_range_min) - Number(b.driver_age_range_min))
    const minDriverAge = parseInt(ageRanges[0].driver_age_range_min);
    const maxDriverAge = parseInt(ageRanges[ageRanges.length - 1].driver_age_range_max) || 100;
    for (let key in this.driver) {
      if (['additionalDrivers', 'phone'].indexOf(key) == -1) {
        if (this.driver[key].current && !this.driver[key].current.value.trim()) {
          errors[key] = true;
        } else if (key == 'email' && this.driver[key].current.value.indexOf('@') == -1) {
          errors[key] = true;
        } else if (key == 'age' && (isNaN(this.driver[key].current.value) || minDriverAge && Number(this.driver[key].current.value) < minDriverAge || maxDriverAge && Number(this.driver[key].current.value) > maxDriverAge)) {
          errors[key] = true;
        }
      }
    }

    if (!driverPhone || driverPhone.length < 5 || !driverPhone.match(/^[0-9\(\)\+\-]+$/)) {
      errors['phone'] = true;
    }

    for (let key in this.driver.licence) {
      if (key.indexOf('expiry') == -1 && this.driver.licence[key].current && this.driver.licence[key].current.value) {
        if (key.indexOf('expiryMM') != -1 && !this.state.noExpiry && (!parseInt(this.driver.licence[key].current.value) || parseInt(this.driver.licence[key].current.value) > 12 || parseInt(this.driver.licence[key].current.value) < 1)) {
          errors[`${key}_main`] = true;
        }
        if (key.indexOf('expiryYY') != -1 && !this.state.noExpiry && (!parseInt(this.driver.licence[key].current.value) || 2000 + parseInt(this.driver.licence[key].current.value) < moment().year())) {
          errors[`${key}_main`] = true;
        }
      }

    }

    /*if (Object.keys(this.driver.additionalDrivers).length) {
        for (let index in this.driver.additionalDrivers) {
            for (let key in this.driver.additionalDrivers[index]) {
                if (key.indexOf('expiry') == -1 && this.driver.additionalDrivers[index][key] && !this.driver.additionalDrivers[index][key].value.trim()) {
                    errors[`${key}_${index}`] = true;
                } else if (key.indexOf('expiryMM') != -1 && !this.state[`noExpiry_${index}`] && (isNaN(this.driver.additionalDrivers[index][key].value) || parseInt(this.driver.additionalDrivers[index][key].value) > 12 || parseInt(this.driver.additionalDrivers[index][key].value) < 1)) {
                    errors[`${key}_${index}`] = true;
                } else if (key.indexOf('expiryYY') != -1 && !this.state[`noExpiry_${index}`] && (isNaN(this.driver.additionalDrivers[index][key].value) || 2000 + parseInt(this.driver.additionalDrivers[index][key].value) < moment().year())) {
                    errors[`${key}_${index}`] = true;
                }
            }
        }
    }*/

    this.setState({errors});
    if (!Object.keys(errors).length) {
      const data = {
        additionalDrivers: [],
        customer: {
          phone: driverPhone
        },
        driver: {},
      };
      for (let key in this.driver) {
        if (['additionalDrivers', 'license'].indexOf(key) == -1) {
          if (key == 'age') {
            data.customer[key] = Number(this.driver[key].current.value);
          } else {
            data.customer[key] = this.driver[key].current.value;
          }
        } else if (key == 'additionalDrivers') {
          for (let k in this.driver[key]) {
            let expiry = '';

            if (this.state[`noExpiry_${k}`] || (this.driver[key][k]['expiryMM'] && this.driver[key][k]['expiryMM'].value &&
              this.driver[key][k]['expiryYY'] && this.driver[key][k]['expiryYY'].value)
            ) {
              expiry = this.state[`noExpiry_${k}`] ? 'noExp' :
                this.driver[key][k]['expiryMM'].value && `${this.driver[key][k]['expiryMM'].value}/20${this.driver[key][k]['expiryYY'].value}` || '';
            }

            data.additionalDrivers[k] = {
              expiry
            };

            for (let n in this.driver[key][k]) {
              if (['expiryMM', 'expiryYY', 'nr'].indexOf(n) == -1) {
                if (this.driver[key][k][n]) {
                  data.additionalDrivers[k][n] = this.driver[key][k][n].value;
                }
              } else if (n == 'nr') {
                if (this.driver[key][k][n]) {
                  data.additionalDrivers[k]['licenseNumber'] = this.driver[key][k][n].value;
                }
              }
            }
          }
        } else if (key == 'licence') {
          data.driver["expiryMM"] = this.props.reservation.reservation.driver.expiry && this.props.reservation.driver && this.props.reservation.driver.expiry.indexOf('null') == -1 ? this.props.reservation.driver.expiry.split('/')[0] : null
          data.driver["expiryYY"] = this.props.reservation.reservation.driver.expiry && this.props.reservation.driver && this.props.reservation.driver.expiry.indexOf('null') == -1 ? this.props.reservation.driver.expiry.split('/')[1].slice(-2) : null
          data.driver["licenseNumber"] = this.props.reservation.reservation.driver.licenseNumber
          data.driver["firstName"] = this.props.reservation.reservation.driver.firstName
          data.driver["lastName"] = this.props.reservation.reservation.driver.lastName
        }
      }

      this.props.putModifyReservation(this.props.reservation.reservation.reservationNumber, data, this.props.baseConf);
      this.setState({lname: null, ref: null});
    }
  }

  getDesk(iata_code) {
    for (let key in this.props.desks) {
      const desk = this.props.desks[key];

      if ((desk.iata_code == iata_code || desk.supplier_desk_code == iata_code) && desk.name) {
        return desk.name[this.props.locale.rc_language_code];
      }
    }

    return iata_code;
  }

  render() {
    if (!this.props.locale || !this.props.countries || !this.props.prismic || !this.props.baseConf) return <Loader/>;
    if (this.props.reservation.status && (this.props.reservation.status.indexOf('success') != -1 || this.props.reservation.status.indexOf('error') != -1)) {
      if (this.reservationNumber.current) {
        this.reservationNumber.current.value = '';
      }

      if (this.lastName.current) {
        this.lastName.current.value = '';
      }

      const url = window.location.href.split('?')[0];
      window.history.pushState({path: url}, '', url);
    }


    const searchBtn = this.props.reservation.isFetching ? (<div className="spinner"></div>) : (
      <span><i className="icon icon-search-blue"></i> {t('Search')}</span>);
    const submitBtn = this.props.reservation.isFetching ? (<div className="spinner green"></div>) : t('Submit');
    let category = null;
    const countries = [{code: '', name: '-----'}, ...this.props.countries];


    return (
      <div className="marginbottom32" style={{opacity: this.state.opacity}}>
        <NavBar prismic={this.props.prismic} minimal={true} nolang={true} setCurrency={this.props.setCurrency}
                currency={this.props.currency} locale={this.props.locale} setLocale={this.props.setLocale}
                conf={this.props.conf} history={this.props.history} match={this.props.match}/>
        <div className="search padding32_0" id="search">
          <div className="container">
            {this.props.reservation && this.props.reservation.reservation && Object.keys(this.props.reservation.reservation).length ?
              (<div className="search">
                <div className="container">
                  <div className="flex-row text-on-primary">
                    <div className="flex1">
                      <i className="icon icon-confirmation-check margin0_8"></i>
                      <h3>{`${t('Booking no')} ${this.state.reservationNumber || this.props.reservation.reservation.reservationNumber}`}</h3>
                    </div>
                  </div>
                  <div className="separator margin16_0"></div>
                  <div className="search-row">
                    <div className="search-item">
                      <h3 className="margin16_0">{t('Pick-up')}</h3>
                      <div className="datetime-info">
                        <div className="date">
                          <div className="month">
                            {monthsShort[moment(this.props.reservation.reservation.pickupDate).month()]}
                          </div>
                          <div className="numeric">
                            {moment(this.props.reservation.reservation.pickupDate).date()}
                          </div>
                        </div>
                        <div className="location">
                          <div className="location-row">
                            <i className="icon icon-location"></i>
                            <span>{this.getDesk(this.props.reservation.reservation.pickupDesk)}</span>
                          </div>
                          <div className="location-row">
                            <i className="icon icon-time"></i>
                            <span>{moment(this.props.reservation.reservation.pickupDate).format('HH:mm')}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="search-item">
                      <h3 className="margin16_0">{t('Drop-off')}</h3>
                      <div className="datetime-info">
                        <div className="date">
                          <div className="month">
                            {monthsShort[moment(this.props.reservation.reservation.returnDate).month()]}
                          </div>
                          <div className="numeric">
                            {moment(this.props.reservation.reservation.returnDate).date()}
                          </div>
                        </div>
                        <div className="location">
                          <div className="location-row">
                            <i className="icon icon-location"></i>
                            <span>{this.getDesk(this.props.reservation.reservation.dropOffDesk)}</span>
                          </div>
                          <div className="location-row">
                            <i className="icon icon-time"></i>
                            <span>{moment(this.props.reservation.reservation.returnDate).format('HH:mm')}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>)
              : (<div className="flex-row">
                <div className="flex1">
                  <div className="input-group">
                    <div className="row-item">
                      <div className="label">{t('Reservation reference')}</div>
                      <input
                        ref={this.reservationNumber}
                        defaultValue={this.state.ref}
                        className="input"
                        name="reference_nr"
                        placeholder={t('Enter the reservation reference number')}/>
                    </div>
                  </div>
                </div>
                <div className="flex1 marginleft32">
                  <div className="input-group">
                    <div className="row-item">
                      <div className="label">{t("Renter's last name")}</div>
                      <div className="flex-row">
                        <input
                          ref={this.lastName}
                          defaultValue={this.state.lname}
                          className="flex2 input"
                          name="last_name"
                          placeholder={t("Enter the renter's last name")}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex1 marginleft32">
                  <div className="flex-row">
                    <button className="flex1 btn btn-search-form" onClick={() => {
                      this.fetchReservation()
                    }}>
                      {searchBtn}
                    </button>
                  </div>
                </div>
              </div>)
            }
          </div>
        </div>
        {this.props.reservation && this.props.reservation.status && this.props.reservation.status.indexOf('error') != -1 ?
          (<div className="error">
            {this.props.reservation.status == 'error_missing' ?
              t("Sorry we didn't find a reservation matching the search criteria.") :
              t('Something went wrong. Please try again later.')}</div>) : ''
        }
        {this.props.reservation && ((this.props.reservation.reservation && Object.keys(this.props.reservation.reservation).length) || (this.props.reservation.status && this.props.reservation.status.indexOf('success') != -1)) ?
          (<div className="success">
            {this.props.reservation.status && this.props.reservation.status.indexOf('success') != -1 ?
              t("Reservation information was successfully updated.") :
              t('Please provide missing renter and driver info before proceeding to the counter.')}
          </div>) : ''
        }
        {this.props.reservation && this.props.reservation.reservation && Object.keys(this.props.reservation.reservation).length ?
          (<div className="container details info_details">
            <div className="flex-row">
              <div className="flex3">
                <h3 className="text-blue margintop64">{t("Renter's information")}</h3>
                <div className="input-group">
                  <div className="row-item flex1 fixed-row">
                    <div
                      className={`label text-bold ${this.state.errors['firstName'] && 'has-error'}`}>{t('First name')}</div>
                    <input disabled defaultValue={this.props.reservation.reservation.customer.firstName}
                           name="firstname" ref={this.driver.firstName}
                           className={`input minwidth100 ${this.state.errors['firstName'] && 'has-error'}`}
                           placeholder={t("Enter your first name")}/>
                  </div>
                  <div className="row-item flex1 fixed-row">
                    <div
                      className={`label text-bold ${this.state.errors['lastName'] && 'has-error'}`}>{t('Last name')}</div>
                    <input disabled defaultValue={this.props.reservation.reservation.customer.lastName} name="lastname"
                           ref={this.driver.lastName}
                           className={`input minwidth100 ${this.state.errors['lastName'] && 'has-error'}`}
                           placeholder={t("Enter your last name")}/>
                  </div>
                </div>
                <div className="input-group">
                  <div className="row-item flex1 fixed-row">
                    <div
                      className={`label text-bold ${this.state.errors['email'] && 'has-error'}`}>{t('E-mail address')}</div>
                    <input defaultValue={this.props.reservation.reservation.customer.email} name="email"
                           onBlur={(e) => this.validate('email', e.target.value)} ref={this.driver.email}
                           className={`input minwidth100 ${this.state.errors['email'] && 'has-error'} `}
                           placeholder={t("Enter your e-mail address")}/>
                  </div>
                  <div className="row-item flex1 fixed-row">
                    <div
                      className={`label text-bold ${this.state.errors['phone'] && 'has-error'}`}>{t('Phone number')}</div>
                    <ReactTelephoneInput
                      defaultCountry={this.props.reservation.reservation.customer.countryCode ? this.props.reservation.reservation.customer.countryCode.toLowerCase() : 'is'}
                      initialValue={this.props.reservation.reservation.customer.phone || ''}
                      flagsImagePath="/flags.png"
                      onChange={(driverPhone) => this.setState({driverPhone})}
                      onBlur={(e) => this.validate('phone', e)}/>
                  </div>
                </div>
                <div className="input-group flex-row">
                  <div className="row-item flex1 fixed-row minwidth50">
                    <div
                      className={`label text-bold ${this.state.errors['address'] && 'has-error'}`}>{t('Address')}</div>
                    <input onBlur={(e) => this.validate('address', e.target.value)}
                           defaultValue={this.props.reservation.reservation.customer.address} name="address"
                           ref={this.driver.address}
                           className={`input minwidth100 ${this.state.errors['address'] && 'has-error'} `}
                           placeholder={t("Enter your address")}/>
                  </div>
                  <div className="row-item flex1 fixed-row minwidth50">
                    <div
                      className={`label text-bold ${this.state.errors['countryCode'] && 'has-error'}`}>{t('Country')}</div>
                    <select
                      ref={this.driver.countryCode}
                      onBlur={(e) => this.validate('countryCode', e.target.value)}
                      className={`input minwidth100 ${this.state.errors['countryCode'] && 'has-error'}`}
                      defaultValue={this.props.reservation.reservation.customer.countryCode || ''}>
                      {
                        countries.map((country, i) =>
                          <option
                            value={country.code}
                            key={i}
                          >{country.code ? country.name[this.props.locale.rc_language_code] : country.name}
                          </option>
                        )
                      }
                    </select>
                  </div>
                </div>
                <div className="input-group">
                  <div className="row-item flex2">
                    <div
                      className={`label text-bold ${this.state.errors['cityOfResidence'] && 'has-error'} `}>{t('City')}</div>
                    <input defaultValue={this.props.reservation.reservation.customer.cityOfResidence}
                           onBlur={(e) => this.validate('cityOfResidence', e.target.value)} name="cityOfResidence"
                           ref={this.driver.cityOfResidence}
                           className={`input minwidth100 ${this.state.errors['cityOfResidence'] && 'has-error'} `}
                           placeholder={t("City of residence")}/>
                  </div>
                  <div className="row-item flex1 fixed-row">
                    <div className={`label text-bold ${this.state.errors['gender'] && 'has-error'}`}>{t('Gender')}</div>
                    <select defaultValue={this.props.reservation.reservation.customer.gender}
                            onBlur={(e) => this.validate('gender', e.target.value)} ref={this.driver.gender}
                            className={`input minwidth100 ${this.state.errors['gender'] && 'has-error'} `}>
                      <option value="">{'-----'}</option>
                      <option value="MALE">{t('Male')}</option>
                      <option value="FEMALE">{t('Female')}</option>
                      <option value="UNDEFINED">{t('Other')}</option>
                    </select>
                  </div>
                  <div className="row-item flex1 fixed-row">
                    <div
                      className={`label text-bold ${this.state.errors['age'] && 'has-error'}`}>{t('Age of renter')}</div>
                    <input defaultValue={this.props.reservation.reservation.customer.age || ''}
                           onBlur={(e) => this.validate('age', e.target.value)} onChange={() => this.sanitizeAge()}
                           name="age" ref={this.driver.age}
                           className={`input minwidth100 ${this.state.errors['age'] && 'has-error'} `}/>
                  </div>
                </div>
              </div>
            </div>
            {this.props.reservation.reservation.additionalDrivers && this.props.reservation.reservation.additionalDrivers.length ?
              (<div className="flex-row">
                <div className="flex3">
                  <h3 className="text-blue margintop32">{t("Additional driver's information")}</h3>
                  {this.props.reservation.reservation.additionalDrivers.map((driver, i) => {
                    this.driver.additionalDrivers[i] = {};

                    return (
                      <div key={i}>
                        <div className="input-group">
                          <div className="row-item flex1 fixed-row">
                            <div
                              className={`label text-bold ${this.state.errors['firstName_' + i] && 'has-error'}`}>{t('First name')}</div>
                            <input defaultValue={driver.firstName}
                                   onBlur={(e) => this.validate('firstName_' + i, e.target.value)} name="firstname"
                                   ref={el => this.driver.additionalDrivers[i].firstName = el}
                                   className={`input minwidth100 ${this.state.errors['firstName_' + i] && 'has-error'}`}
                                   placeholder={t("Enter your first name")}/>
                          </div>
                          <div className="row-item flex1 fixed-row">
                            <div
                              className={`label text-bold ${this.state.errors['lastName_' + i] && 'has-error'}`}>{t('Last name')}</div>
                            <input defaultValue={driver.lastName}
                                   onBlur={(e) => this.validate('lastName_' + i, e.target.value)} name="lastname"
                                   ref={el => this.driver.additionalDrivers[i].lastName = el}
                                   className={`input minwidth100 ${this.state.errors['lastName_' + i] && 'has-error'}`}
                                   placeholder={t("Enter your last name")}/>
                          </div>
                        </div>
                        <div className="input-group">
                          <div className="row-item flex1 fixed-row">
                            <div
                              className={`label text-bold ${this.state.errors['nr_' + i] && 'has-error'}`}>{t("Driver's licence number")}</div>
                            <input defaultValue={driver.licenseNumber} name="licenseNumber"
                                   ref={el => this.driver.additionalDrivers[i].nr = el}
                                   className={`input minwidth100 ${this.state.errors['nr_' + i] && 'has-error'} `}
                                   placeholder={t("Enter driver license number")}/>
                          </div>
                          <div className="row-item flex1">
                            <div
                              className={`label text-bold ${(this.state.errors['expiryMM_' + i] || this.state.errors['expiryYY_' + i]) && 'has-error'}`}>{t('Licence expiry date')}</div>
                            <div className="floatright negative-checkbox flex1 info-update">
                              <div
                                className={`floatright checkbox checkbox-sm ${this.state[`noExpiry_${i}`] ? 'checked' : ''}`}
                                onClick={() => {
                                  const newState = {errors: {...this.state.errors}};
                                  newState.errors[`expiryMM_${i}`] = false;
                                  newState.errors[`expiryYY_${i}`] = false;
                                  newState[`noExpiry_${i}`] = !this.state[`noExpiry_${i}`];
                                  this.setState(newState)
                                }}></div>
                              <div
                                className="margin0_8 text-sm floatright text-bold text-dark-gray">{t('Does not expire')}</div>
                            </div>
                            <div className="flex-row">
                              <input
                                style={{opacity: (this.state[`noExpiry_${i}`] ? 0 : 1)}}
                                defaultValue={driver.expiry && driver.expiry.indexOf('null') == -1 && driver.expiry != 'noExp' ? driver.expiry.split('/')[0] : null}
                                ref={el => this.driver.additionalDrivers[i].expiryMM = el}
                                className={`input text-center flex1 joined ${this.state.errors['expiryMM_' + i] ? 'has-error' : ''}`}
                                placeholder={t("MM")} maxLength={2}
                                onBlur={(e) => {
                                  const errors = {...this.state.errors};
                                  errors['expiryMM_' + i] = false;

                                  if (isNaN(e.target.value as any) || parseInt(e.target.value) > 12 || parseInt(e.target.value) < 1) {
                                    errors['expiryMM_' + i] = true;
                                  }

                                  this.setState({errors});
                                }}/>
                              <input
                                style={{opacity: (this.state[`noExpiry_${i}`] ? 0 : 1)}}
                                defaultValue={driver.expiry && driver.expiry.indexOf('null') == -1 && driver.expiry != 'noExp' ? driver.expiry.split('/')[1].slice(-2) : null}
                                ref={el => this.driver.additionalDrivers[i].expiryYY = el}
                                className={`input text-center flex1 ${this.state.errors['expiryYY_' + i] ? 'has-error' : ''}`}
                                placeholder={t("YY")}
                                maxLength={2}
                                onBlur={(e) => {
                                  const errors = {...this.state.errors};
                                  errors['expiryYY_' + i] = false;

                                  if (isNaN(e.target.value as any) || 2000 + parseInt(e.target.value) < moment().year()) {
                                    errors['expiryYY_' + i] = true;
                                  }

                                  this.setState({errors});
                                }}/>
                            </div>
                          </div>
                        </div>
                      </div>)
                  })}
                </div>
              </div>) : ''
            }
            <div className="marginbottom32">
              <div
                className="marginbottom32">{t('Do you want add additional drivers - please speak to our counter staff')}</div>
              <button className="btn btn-gray" onClick={() => {
                this.cancel()
              }}>{t('Cancel')}</button>
              <button className="btn btn-green floatright" onClick={() => {
                this.save()
              }}>{submitBtn}</button>
            </div>
          </div>) :
          (<div className="container text-center margintop64">
            <h2
              className="text-blue">{t('Please check and update driver information to speed up pickup at the counter')}</h2>
            <div className="text-lg text-gray">{t('Use the search above to find your reservation')}</div>
          </div>)
        }
        {
          this.props.rates && this.props.rates.message == 'OK' ?
            <Footer prismic={this.props.prismic}/> : ''
        }
      </div>
    );
  }
};
