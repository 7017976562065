import {Dispatch} from 'redux';
import fetch from 'cross-fetch';
import types from '../types';
import {api} from "prismic-javascript";

export function receiveCarInfo(cars: any) {
  return {
    type: types.RECEIVE_CARINFO,
    isFetching: false,
    cars
  }
}

export function fetchCarInfo(urls, apiKey): (dispatch: Dispatch<any, any>) => Promise<void> {
  return function (dispatch: Dispatch<any, any>) {
    dispatch({
      type: types.REQUEST_CARINFO
    });

    return fetch(`${urls.reservationsAPI}/car-info`,
      {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'X-API-KEY': apiKey
      }
    }
    )
      .then(res => res.json())
      .then(json => {
        dispatch(receiveCarInfo(json.options));
      });
  }
}
