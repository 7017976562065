import {Dispatch} from 'redux';
import {t} from '../conf/gettext';
import fetch from 'cross-fetch';
import types from '../types';
import {receiveRates} from './rates';
import {togglePayOnline} from './cart';
import queryString from "query-string";

export function requestRateInfo() {
  return {
    type: types.REQUEST_RATE_INFO,
    rateInfo: null
  }
}

export function receiveRateInfo(rateInfo: any) {
  return {
    type: types.RECEIVE_RATE_INFO,
    rateInfo
  }
}

export function fetchRateInfo(hash: string, urls, history = null) {
  return (dispatch: Dispatch<any, any>) => {
    dispatch(requestRateInfo());
    const qs = queryString.parse(window.location.search);
    return fetch(`${urls.reservationsAPI}/rates/${hash}`, {
      method:'GET',
      headers: {
        'X-API-KEY':qs['apiKey']
      }
    })
      .then(res => {
        const json = res.json();
        if (res.status >= 400) {
          return json.then(Promise.reject.bind(Promise));
        }
        return json;
      })
      .then(json => {
        if (json.paymentType === 'NO_PREPAY') {
          dispatch(togglePayOnline(false));
        }
        dispatch(receiveRateInfo(json));
      }).catch(err => {
        dispatch(receiveRates({
          rates: [],
          message: t('Unable to fetch rate. Please try again.')
        }));

        const pathname = window.location.pathname.split('/');
        history.push(pathname.length > 1 && pathname[1].length == 2 ? '/' + pathname[1] + window.location.search : '/' + window.location.search);
      });
  }
}
