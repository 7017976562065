import React, { Component } from 'react';
import { languages, currencies } from '../../conf/values';
import { t } from '../../conf/gettext';
import queryString from 'query-string'; 

const initialState = {
    langMenu: false,
};
type State = Readonly<typeof initialState>

export default class NavBar extends Component<any, State> {
    readonly state: State = initialState;
    constructor(props) {
      super(props);
    }

    componentDidMount() {
        if (this.props.locale.default_currency && this.props.locale.default_currency !== this.props.currency && window.location.search.indexOf('currency') === -1) {
            this.setCurrency(this.props.locale.default_currency);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.locale.slug !== prevProps.locale.slug && this.props.locale.default_currency) {
            this.setCurrency(this.props.locale.default_currency);
        }
    }
    setCurrency(currency) {
        const qs = queryString.parse(window.location.search);
        qs['currency'] = currency;
        const search = queryString.stringify(qs);
        this.props.setCurrency(currency);
        const url = window.location.origin + window.location.pathname + '?' + search;
        window.history.pushState({path: url}, '', url);
    }
    render() {
        let prevUrl = null
        const mainPageUrl = this.props.prismic && this.props.prismic.data.main_page_link.url;
        if (this.props.prevUrl) {
            prevUrl = this.props.prevUrl.indexOf('?') == -1 ? `${this.props.prevUrl}?back=true` : this.props.prevUrl;
        }
        return (
            <div>
                <div className="navbar-buffer"></div>
                <div className="navbar fixed">
                    { this.props.prevUrl && this.props.history && this.props.prevRoute ?
                        <div className="nav text-blue" onClick={ () => this.props.history.push(`${prevUrl}`) } >
                            <i className="icon icon-chevron-left-gray"></i>
                            { this.props.prevRoute }
                        </div> : null
                    }
                    <div className="menu-heading" onClick={ () => this.setState({langMenu: !this.state.langMenu}) }>
                        <span className="label">{ this.props.locale.slug }</span>
                        /
                        <span className="label">{ this.props.currency }</span>
                        <i className="icon icon-chevron-down-header"></i>
                        <div className={`menu-container ${this.state.langMenu ? 'open' : ''}`}>
                            <div className="menu">
                                <div className="flex-row">
                                    <div className="flex1 menu-separator">
                                        {
                                            this.props.conf.enabled_languages.map((l, i) => {
                                                const lang = languages.find(language => language.val == l.language_code_in_prismic);

                                                if (lang) {
                                                    return (<div key={ i } className={`menu-item ${ lang.val == this.props.locale.language_code_in_prismic ? 'active' : ''}`} onClick={ () => this.props.setLocale(l) } >{ l.name }</div>);
                                                }
                                            })
                                        }
                                    </div>
                                    <div className="flex1">
                                        {
                                            this.props.conf.supported_currencies.map((cur, i) => {
                                                const currency = currencies.find(c => c.val == cur.currency_code);

                                                if (currency) {
                                                    return (<div key={ i } className={`menu-item ${ currency.val == this.props.currency ? 'active' : ''}`} onClick={ () => this.setCurrency(currency.val) } >{ currency.val }</div>);
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href={mainPageUrl}><div className="logo"></div></a>
                </div>
            </div>
        );
    }
}
