import { Dispatch } from 'redux';
import prismic from 'prismic-javascript';
import types from '../types';

export function receivePrismic(prismic) {
    return {
        type: types.RECEIVE_PRISMIC,
        prismic
    }
}

export function fetchPrismic(urls): (dispatch: Dispatch<any, any>) => Promise<void> {
    return function(dispatch: Dispatch<any, any>) {
        return prismic.getApi(urls.prismic)
        .then(api => {
            return api.query(prismic.Predicates.any("document.type", ["configuration", "content", "suppler_content"]), { lang : '*' });
        }).then(
            res => {
                dispatch(receivePrismic(res));
            },
            err => {
                console.log(err);
            }
        );
    }
}
