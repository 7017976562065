import { Dispatch } from 'redux';
import fetch from 'cross-fetch';
import types from '../types';

export function requestHotel() {
    return {
        type: types.REQUEST_HOTEL,
        isFetching: true,
        hotelBooked: false,
        error: false,
    }
}

export function receiveHotel(hotelBooked) {
    return {
        type: types.RECEIVE_HOTEL,
        isFetching: false,
        hotelBooked,
        error: !hotelBooked,
    }
}

export function bookHotel(payload, urls): (dispatch: Dispatch<any, any>) => any {
    return function(dispatch: Dispatch<any, any>) {
        dispatch(requestHotel());
        const reservationNumber = payload.reservationNumber;
        delete payload.reservationNumber;

        return fetch(`${urls.backendAPI}/hotel-delivery/${reservationNumber}`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then(res => dispatch(receiveHotel(res.status == 200)));
    }
}
